import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Container } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 150,
    backgroundColor: 'white',
    padding: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
  styTxtTitle: {
    color: '#F1727E',
    pAlign: 'center',
    marginTop: 100,
  },
  coverContent: {
    marginTop: 50,
    flexDirection: 'column',
    display: 'flex',
  },
  txtTitSubMenu: {
    color: '#F1727E',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      margin: 10,
      marginLeft: 0,
    },
  },
  coverTitle: {
    backgroundColor: '#F1727E',
    borderRadius: 12,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 6,
    },
  },
  title: {
    color: '#FFF',
    pAlign: 'center',
    margin: 12,
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      margin: 7,
    },
  },
  content: {
    fontSize: 17,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    pAlign: 'justify',
  },
}));

const Policy = (props) => {
  const classes = useStyles();

  const renderPolicyOne = () => {
    return (
      <p className={classes.content}>
        Thỏa thuận người dùng được ban hành với mục đích là sự thỏa thuận, cam kết giữa khách hàng và Công ty CP Dịch Vụ
        Việt Nam Trực Tuyến trước và trong quá trình sử dụng ứng dụng thanh toán và tương tác Trường em - Yschool
        (“Yschool”). Thỏa thuận này bao gồm:
        <br />
        <br />• Quy định đăng ký sử dụng
        <br />
        <br />• Chính sách bảo mật thông tin
        <br />
        <br />• Quy trình giải quyết khiếu nại
        <br />
        <br />• Chính sách hoàn trả và bồi thường khiếu nại Thỏa thuận được áp dụng cho khách hàng là cá nhân, tổ chức
        có nhu cầu sử dụng các dịch vụ do VNO cung cấp thông qua Yschool.
      </p>
    );
  };
  const renderPolicyTwo = () => {
    return (
      <div>
        <p className={classes.content}>
          Thỏa thuận Người dùng (“Thỏa thuận”) là thỏa thuận pháp lý giữa khách hàng với VNO, quy định các điều khoản
          trong việc khách hàng sử dụng dịch vụ Yschool. Thỏa thuận này sẽ có hiệu lực khi khách hàng chấp nhận tất cả
          các điều khoản được nêu tại đây trong khi cài đặt Yschool hoặc khi khách hàng bắt đầu sử dụng Yschool theo bất
          kỳ cách nào khác.
          <br />
          <br />
          VNO bảo toàn quyền chỉnh sửa nội dung các điều khoản sử dụng này tại bất kì thời điểm nào. VNO sẽ thông báo
          tới khách hàng khi có cập nhật trong Thỏa thuận này. Bản điều chỉnh có hiệu lực kể từ thời điểm khách hàng
          nhận được thông báo.
          <br />
          <br />
          Khách hàng hoàn toàn chịu trách nhiệm trong việc nhận thức và tuân thủ các quy định của pháp luật khi sử dụng
          Yschool, bao gồm nhưng không giới hạn, đối với những quy định về tính hợp pháp của thông tin do khách cung cấp
          trên ứng dụng và/hoặc các giao dịch, hàng hóa/dịch vụ cung cấp, nguồn gốc giá trị tiền tệ thanh toán của khách
          hàng trên Yschool.
          <br />
          <br />
          Nếu Khách hàng không đồng ý chịu sự ràng buộc của các Điều Khoản sử dụng hiện hành, Khách hàng không được sử
          dụng dịch vụ Yschool và sẽ ngừng sử dụng dịch vụ Yschool ngay lập tức
        </p>
        <h5 className={classes.txtTitSubMenu}>1. Định nghĩa</h5>
        <p className={classes.content}>
          • Công ty Công ty CP Dịch Vụ Việt Nam Trực Tuyến: Là đơn vị sở hữu và là nhà cung cấp Yschool.
          <br />
          <br />• Ứng dụng thanh toán và tương tác Yschool (“Yschool”): Là Ứng dụng mang tên Yschool do VNO là chủ sở
          hữu và cung cấp.
          <br />
          <br />• Hệ thống: Bao gồm các máy chủ đặt tại trung tâm dữ liệu của VNO, được cài đặt các phần mềm hệ thống và
          ứng dụng Yschool.
          <br />
          <br />• Dịch vụ: là các dịch vụ mà ứng dụng Yschool cung cấp.
          <br />
          <br />• Khách hàng/Người dùng: Là các tổ chức (bao gồm trường học) và/hoặc cá nhân (bao gồm giáo viên, phụ
          huynh, học sinh, sinh viên) có nhu cầu sử dụng Yschool hoặc sử dụng một trong các ứng dụng/tính năng được phát
          triển bởi VNO dựa trên Yschool.
          <br />
          <br />• Các Bên: là các bên tham gia vào hệ thống Yschool, bao gồm nhưng không giới hạn: VNO và Khách
          hàng/Người dùng.
          <br />
          <br />• Tài khoản Yschool: là tài khoản mà người dùng thông qua đó sử dụng các dịch vụ trên ứng dụng Yschool.
          <br />
          <br />• Thông tin truy cập: là mật khẩu, thông tin đăng nhập chi tiết và bất kỳ thông tin bảo mật liên quan
          đến tài khoản Yschool của người dùng.
          <br />
          <br />• Pháp luật: là toàn bộ hệ thống luật của Nước Cộng hòa Xã hội Chủ nghĩa Việt Nam, bao gồm bộ luật, luật
          và các văn bản dưới luật.
          <br />
          <br />• Ngày làm việc: là các ngày làm việc của VNO, từ 8h30 đến 18h00 từ thứ 2 đến thứ 6, ngoại trừ các ngày
          nghỉ, lễ, tết theo quy định của pháp luật.
        </p>
        <h5 className={classes.txtTitSubMenu}>2. Quyền sử dụng Yschool</h5>
        <p className={classes.content}>
          • Khách hàng có quyền sử dụng đầy đủ các tính năng của Yschool khi đăng ký sử dụng dịch vụ theo các chính sách
          được quy định của VNO.
          <br />
          <br />• Khách hàng KHÔNG được phép sử dụng dịch vụ Yschool cho những mục đích bao gồm nhưng không giới hạn:
          khai thác thông tin sản phẩm, hệ thống, truy xuất/cập nhật dữ liệu, gửi email, viết bài hoặc truyền tải dữ
          liệu để:
          <br />
          <br />- Làm tổn hại, làm phiền cho người khác hoặc gây ra thương tổn đến con người và tài sản;
          <br />
          <br />- Liên quan đến việc công bố các thông tin hoặc tài liệu lừa đảo, gây mất uy tín danh dự cho bất kỳ tổ
          chức, cá nhân nào, hoặc mang tính chất không lành mạnh bao gồm nhưng không giới hạn như: quấy rối, khiêu dâm,
          bạo lực, kinh dị, có nội dung vi phạm các chuẩn mực đạo đức của xã hội;
          <br />
          <br />- Xâm phạm các quyền riêng tư hoặc kì thị chủng tộc, chính trị, tôn giáo, quốc gia, vùng miền, giới
          tính, người tàn tật;
          <br />
          <br />- Xâm phạm quyền sở hữu trí tuệ hoặc các quyền sở hữu khác;
          <br />
          <br />- Cản trở hoặc phá hỏng Yschool (bao gồm nhưng không giới hạn bởi việc truy cập trái phép Yschool thông
          qua bất cứ phương tiện máy móc, phần mềm);
          <br />
          <br />
          -Vi phạm các quy định của pháp luật.
          <br />
          <br />
          Việc vi phạm các quy định nêu trên của Người dùng sẽ là cơ sở cho VNO được quyền đình chỉ, tạm khóa và/hoặc
          xóa bỏ tài khoản của Người dùng tại Yschool.
        </p>
        <h5 className={classes.txtTitSubMenu}>3. Chế độ phí và phương thức thanh toán</h5>
        <p className={classes.content}>
          Phí sử dụng dịch vụ Yschool và phương thức thanh toán được căn cứ theo biểu phí được VNO công bố cho từng thời
          kỳ.
        </p>
        <h5 className={classes.txtTitSubMenu}>4. Tài khoản Yschool của khách hàng</h5>
        <p className={classes.content}>
          • Trong quá trình đăng ký sử dụng Yschool, khách hàng sẽ tạo tài khoản và mật khẩu, đồng thời cung cấp một số
          thông tin của người dùng. Khách hàng đồng ý việc thu thập, ghi nhận, xử lý, lưu trữ, chia sẻ thông tin của
          người dùng theo quy định của Thỏa Thuận này nhằm phụ vụ cho các mục đích của ứng dụng Yschool.
          <br />
          <br />• Khách hàng có trách nhiệm phải tự bảo mật mật khẩu và tài khoản của mình cũng như chịu trách nhiệm
          hoàn toàn về các hoạt động liên quan đến tài khoản của khách hàng. Với mỗi một tài khoản trong hệ thống
          Yschool, khách hàng là trường học có thể tạo các tài khoản riêng biệt cho giáo viên của trường học đó tham
          gia. Khách hàng tự trang bị đầy đủ, bảo dưỡng thường xuyên các thiết bị, máy móc kết nối phần mềm ứng dụng để
          đảm bảo kết nối và truy cập an toàn đến hệ thống Yschool.
          <br />
          <br />• Khách hàng có trách nhiệm cung cấp các thông tin theo yêu cầu của Yschool để phục vụ cho việc định
          danh và thực hiện đúng trình tự, thủ tục các giao dịch thanh toán, các hướng dẫn an toàn bảo mật của ứng dụng
          Yschool và VNO. Khách hàng tuyên bố và bảo đảm rằng các thông tin của Khách hàng và các thông tin khác được
          cung cấp cho VNO là trung thực và chính xác. Khách hàng phải bảo đảm thoát/đăng xuất khỏi tài khoản của mình
          sau mỗi phiên sử dụng. Khách hàng thông báo ngay cho VNO khi phát hiện hoặc nghi ngờ có bất cứ ai sử dụng tài
          khoản và/hoặc mật khẩu của mình mà không được phép hay bất cứ lỗi bảo mật nào đối với hệ thống/ứng dụng
          Yschool. VNO không thể và sẽ không chịu trách nhiệm cho bất cứ sự mất mát, hư hỏng hay trách nhiệm nào khác
          nếu khách hàng không tuân thủ điều lệ này hay do sự truy cập trái phép vào tài khoản của khách hàng gây ra do
          lỗi của khách hàng.
          <br />
          <br />• VNO không có trách nhiệm thực hiện lệnh thanh toán thông qua Yschool của Người dùng khi Người dùng
          không thực hiện đầy đủ các yêu cầu về thủ tục thanh toán, xác nhận thông tin thanh toán trên ứng dụng hoặc
          trong trường hợp tài khoản của Người dùng đã bị tạm khóa, đình chỉ, chấm dứt vì lý do bảo mật thông tin, khắc
          phục sự cố hoặc giải quyết tranh chấp phát sinh.
          <br />
          <br />• Khách hàng phải thông báo kịp thời cho VNO khi phát hiện hoặc nghi ngờ có sai sót, nhầm lẫn trong giao
          dịch và/hoặc lộ, mất thông tin tài khoản người dùng.
          <br />
          <br />• Trong trường hợp có tranh chấp giữa hai hoặc nhiều bên về quyền sở hữu tài khoản, khách hàng đồng ý
          rằng VNO sẽ là bên duy nhất giải quyết tranh chấp đó và quyết định của VNO (có thể bao gồm việc chấm dứt hoặc
          đình chỉ tài khoản tranh chấp) là quyết định cuối cùng và có giá trị ràng buộc tất cả các bên.
          <br />
          <br />• Khách hàng phải phối hợp cùng VNO giải quyết các vấn đề phát sinh trong quá trình sử dụng ứng dụng,
          bao gồm nhưng không giới hạn: cung cấp thông tin theo yêu cầu của VNO, tuân thủ theo các hướng dẫn khắc phục
          trong các trường hợp có sai sót từ Yschool, khắc phụ các sự cố và lỗi bảo mật, khôi phục tài khoản, thanh toán
          nhầm, thanh toán thiếu và thanh toán dư đối với các khoản chi phí mà người dùng đã thanh toán thông qua ứng
          dụng Yschool.
        </p>
        <h5 className={classes.txtTitSubMenu}>5. Tư vấn và hỗ trợ khách hàng</h5>
        <p className={classes.content}>
          • VNO chịu trách nhiệm cung cấp dịch vụ tư vấn hỗ trợ cho khách hàng trong suốt quá trình sử dụng thông qua
          tổng đài tư vấn và hỗ trợ khách hàng 19006474, email mailto:info.yschool@webmoney.com.vn, diễn đàn và các hình
          thức hỗ trợ khác được công bố tại website https://yschool.com.vn.
          <br />
          <br />• Khi sử dụng dịch vụ tư vấn qua tổng đài tư vấn và hỗ trợ khách hàng 19006474, khách hàng chấp nhận trả
          cước phí điện thoại theo quy định của nhà cung cấp dịch vụ viễn thông.
          <br />
          <br />• Các dịch vụ tư vấn hỗ trợ thông qua hình thức khác (như dịch vụ tư vấn hỗ trợ tại các địa điểm theo
          yêu cầu của khách hàng, dịch vụ tái đào tạo hướng dẫn sử dụng cho khách hàng) sẽ được hai bên thống nhất về
          chi phí và phương thức cung cấp bằng văn bản bổ sung khi có phát sinh yêu cầu.
        </p>
        <h5 className={classes.txtTitSubMenu}>6. Bảo hành, bảo trì</h5>
        <p className={classes.content}>
          • VNO chịu trách nhiệm đảm bảo điều kiện kỹ thuật để khách hàng có thể sử dụng Yschool 24h/ngày và 7 ngày/tuần
          ngoại trừ thời gian bảo trì, nâng cấp, khắc phục sự cố cho hệ thống. Thời gian ngưng hệ thống để bảo trì hoặc
          nâng cấp hoặc sao lưu sẽ được VNO báo trước lịch thực hiện cho khách hàng theo hình thức thông báo trực tiếp
          trên Yschool. Lịch bảo trì hoặc nâng cấp hoặc sao lưu sẽ thực hiện theo định kỳ hàng ngày hoặc hàng tuần hoặc
          hàng tháng hoặc hàng năm và ưu tiên vào buổi đêm khi hệ thống ít sử dụng nhất.
          <br />
          <br />• VNO có trách nhiệm tiến hành khắc phục sự cố của hệ thống nhanh nhất có thể kể từ khi tiếp nhận được
          yêu cầu từ khách hàng.
          <br />
          <br />• VNO có trách nhiệm cập nhật phiên bản mới nhất của Yschool cho khách hàng sử dụng.
          <br />
          <br />• Khách hàng đồng ý chấp nhận tất cả sự vá lỗi, sửa lỗi, nâng cấp, bảo trì cần thiết để các tính năng
          của Yschool hoạt động chính xác và đảm bảo tính bảo mật. Trừ trường hợp khẩn cấp, VNO sẽ thông báo trước tới
          khách hàng lịch trình của các hoạt động sửa lỗi, nâng cấp này.
        </p>
        <h5 className={classes.txtTitSubMenu}>7. Bảo mật</h5>
        <p className={classes.content}>
          • Dữ liệu của khách hàng là mọi thông tin mà khách hàng gửi, tải lên, truyền hoặc bằng bất kể phương thức nào
          giúp hiển thị thông tin đó trên trong khoản của khách hàng trên Yschool. Dữ liệu đó bao gồm nhưng không giới
          hạn: các dữ liệu thông tin định danh cần thiết mà Người dùng đã sử dụng đề đăng ký và sử dụng tài khoản
          Yschool, thông tin liên hệ của khách hàng, các số liệu tài khoản, các tin nhắn, các thông báo, các tập tin,
          các hình ảnh, các video, các ý kiến, các nhận xét, các đường dẫn, các nội dung liên quan đến công việc và tất
          cả mọi thông tin được hiển thị trên tài khoản Yschool của khách hàng; các giao dịch, thao tác, sửa đổi, bổ
          sung của người trong quá trình sử dụng Yschool. Khách hàng là chủ sở hữu và có toàn quyền kiểm soát về việc
          truy cập dữ liệu được đăng tải trên tài khoản/hệ thống Yschool của khách hàng.
          <br />
          <br />• Khách hàng hiểu rằng VNO không sở hữu bất kì dữ liệu nào mà khách hàng đăng tải lên Yschool; trừ các
          tài liệu và hoặc giao diện hướng dẫn được VNO chủ động cung cấp công khai cho khách hàng.
          <br />
          <br />• Việc quyết định đưa dữ liệu nào lên Yschool là toàn quyền của khách hàng. Tuy nhiên, những nội dung
          được quy định tại khoản 2.2 của Thỏa thuận sẽ không được phép đăng tải trên Yschool. VNO có toàn quyền vô hiệu
          hóa quyền truy cập vào nội dung dữ liệu của khách hàng nếu VNO xác định dữ liệu đó vi phạm điều khoản này,
          hoặc chúng tôi nhận được đơn khiếu nại từ các tài khoản, hoặc thông báo vi phạm sở hữu trí tuệ và/hoặc thông
          báo nội dung có dấu hiệu vi phạm pháp luật của các cơ quan có thẩm quyền.
          <br />
          <br />• VNO chịu trách nhiệm thực hiện và duy trì tất cả các biện pháp bảo vệ mang tính hành chính, vật lý và
          kỹ thuật để bảo vệ cho tính bảo mật và toàn vẹn đối với dữ liệu khách hàng. VNO cam kết sẽ không: - Sửa đổi,
          xóa dữ liệu khách hàng mà không có sự đồng ý của khách hàng hoặc không phải vì mục đích khắc phục lỗi hay sự
          cố; - Không tiết lộ dữ liệu khách hàng trừ trường hợp phải tuân theo quy định của pháp luật hoặc được khách
          hàng cho phép hoặc theo các quy định khác của Thỏa thuận này; - Không Truy cập vào dữ liệu và/hoặc làm thay
          đổi dữ liệu của khách hàng trừ trường hợp khắc phục lỗi kỹ thuật hoặc theo yêu cầu của khách hàng khi sử dụng
          dịch vụ hỗ trợ.
          <br />
          <br />• VNO chịu trách nhiệm bảo mật mọi thông tin về dữ liệu của khách hàng và không được phép tiết lộ cho
          bất kỳ bên thứ ba nào khác ngoại trừ: yêu cầu của cơ quan có thẩm quyền của nhà nước; các đại lý, bên bán
          hàng, nhà cung cấp, nhà thầu, đối tác và các bên khác cung cấp dịch vụ cho VNO, thực hiện các chức năng thay
          mặt Công ty hoặc các bên hợp tác thương mại, cho hoặc liên quan đến các mục đích mà các bên thứ ba đó được
          thuê hoặc các mục đích hợp tác của VNO với các bên thứ ba đó (tùy từng trường hợp áp dụng). VNO không chịu
          trách nhiệm về các thất thoát dữ liệu, bí mật thông tin của khách hàng do khách hàng vô tình hoặc cố ý gây ra.
          <br />
          <br />• Khách hàng chịu trách nhiệm xác định và xác thực quyền của tất cả những người dùng truy nhập vào dữ
          liệu của khách hàng.
          <br />
          <br />• Khách hàng chịu trách nhiệm đảm bảo bí mật thông tin tài khoản khách hàng trên hệ thống Yschool.
          <br />
          <br />• Khách hàng chịu trách nhiệm đối với toàn bộ các hoạt động thực hiện bởi các tài khoản của khách hàng
          và có trách nhiệm ngay lập tức thông báo với VNO về các truy cập trái phép vào tài khoản Yschool của khách
          hàng trong trường hợp phát hiện ra các truy cập trái phép đó.
          <br />
          <br />• VNO sẽ không chịu bất cứ trách nhiệm nào liên quan đến các tổn hại gây ra bởi khách hàng, bao gồm các
          cá nhân không có quyền truy cập vào Yschool vẫn có thể lấy được quyền truy cập do lỗi máy tính/ phần mềm hoặc
          hệ thống mạng nội bộ của khách hàng, không thuộc phạm vi quản lý của VNO.
          <br />
          <br />• Trong phạm vi của Thỏa thuận này, “Thông tin bí mật” bao gồm: Dữ liệu của khách hàng cung cấp trong
          quá trình sử dụng Yschool, công nghệ độc quyền của mỗi bên, quy trình nghiệp vụ và các thông tin kỹ thuật của
          sản phẩm, thiết kế, và toàn bộ quá trình trao đổi giữa hai bên liên quan đến Yschool. Bất kể những điều đã đề
          cập ở trên, “Thông tin bí mật” không bao gồm các thông tin mà:
          <br />
          <br />- Đã được biết tới rộng rãi trước khi tiết lộ; - Được công chúng biết tới không phải do lỗi của bên nhận
          thông tin; - Dữ liệu tổng hợp trong đó không chứa bất kỳ thông tin cá nhân hoặc thông tin nào cụ thể của khách
          hàng.
          <br />
          <br />• Trừ trường hợp được quy định tại Khoản 7.5 trên, Khách hàng và VNO cùng thỏa thuận:
          <br />
          <br />- Thực hiện các biện pháp cần thiết để giữ bí mật cho tất cả các “Thông tin bí mật”;
          <br />
          <br />- Không sao chép, cung cấp một phần hay toàn bộ thông tin bảo mật cho bất kỳ bên thứ ba khi chưa có sự
          chấp thuận của bên có quyền sở hữu đối với “Thông tin bí mật”;
          <br />
          <br />- Không sử dụng “Thông tin bí mật” mà các bên đã cung cấp cho nhau phục vụ cho các mục đích khác ngoài
          mục đích thực hiện Thỏa thuận này.
        </p>
        <h5 className={classes.txtTitSubMenu}>8. Bản quyền phần mềm và dữ liệu</h5>
        <p className={classes.content}>
          VNO là chủ sở hữu và có toàn quyền tác giả đối với Yschool. xử lý, lưu trữ, chia sẻ thông tin của người dùng
          theo quy định của Thỏa Thuận này nhằm phụ vụ cho các mục đích của ứng dụng Yschool.
          <br />
          <br />• Khách hàng có quyền sử dụng Yschool để tạo ra dữ liệu phục vụ công việc của đơn vị và có quyền tải về
          phần dữ liệu do chính đơn vị nhập vào hệ thống trong suốt thời gian được cấp phép sử dụng.
          <br />
          <br />• Khách hàng đồng ý rằng sản phẩm/dịch vụ, bao gồm nhưng không giới hạn: giao diện người sử dụng, đoạn
          âm thanh, đoạn video, hình ảnh, logo, nội dung hướng dẫn sử dụng và phần mềm được sử dụng để thực hiện sản
          phẩm/dịch vụ liên quan đến Yschool thuộc sở hữu riêng của VNO được bảo hộ bởi pháp luật về sở hữu trí tuệ và
          quyền tác giả. Khách hàng cam kết sẽ không sử dụng các thông tin hoặc tài liệu thuộc sở hữu riêng đó theo bất
          cứ cách thức nào ngoại trừ cho mục đích sử dụng sản phẩm/dịch vụ theo Thỏa thuận này. Không có phần nào trong
          sản phẩm/dịch vụ có thể được sao chép lại dưới bất kỳ hình thức nào hoặc bằng bất cứ phương tiện nào, trừ khi
          được cho phép một cách rõ ràng theo các điều khoản này.
          <br />
          <br />• Khách hàng đồng ý không sửa đổi, thuê, cho thuê, cho vay, bán, phân phối, cầm cố, thế chấp hoặc tạo ra
          các sản phẩm phát sinh dựa trên sản phẩm/dịch vụ theo bất cứ phương cách nào, và không khai thác sản phẩm/dịch
          vụ theo bất cứ phương thức không được phép nào, bao gồm nhưng không giới hạn ở việc xâm phạm hoặc tạo gánh
          nặng lên dung lượng của hệ thống mạng của VNO.
          <br />
          <br />• Việc sử dụng Yschool và/hoặc bất cứ phần nào của sản phẩm/dịch vụ liên quan đến Yschool như đã nêu
          trên, trừ khi việc sử dụng Yschool và/hoặc sản phẩm/dịch vụ đó như được cho phép theo Thỏa thuận này, đều bị
          nghiêm cấm và được coi là xâm phạm đến các quyền sở hữu trí tuệ và quyền tác giả, và khách hàng có thể phải
          chịu các hình phạt dân sự và hình sự, bao gồm cả việc bồi thường thiệt hại bằng tiền có thể được áp dụng đối
          với việc xâm phạm quyền sở hữu trí tuệ và quyền tác giả.
          <br />
          <br />• Để VNO có thể cung cấp dịch vụ sử dụng Yschool cho khách hàng, khách hàng đồng ý cho VNO quyền xử lý
          và truyền tải dữ liệu của khách hàng. VNO có quyền nhưng không có nghĩa vụ nào trong việc thực hiện các hành
          động khắc phục nếu như có bất cứ nội dung nào mà khách hàng vi phạm các điều khoản được liệt kê trong Thỏa
          thuận này. VNO không có bất kỳ trách nhiệm pháp lý nào đối với khách hàng trong các tình huống VNO thực hiện
          hành động khắc phục. Khách hàng là người duy nhất chịu trách nhiệm về tính chính xác, chất lượng, tính toàn
          vẹn, hợp pháp, tin cậy và phù hợp đối với tất cả dữ liệu của mình.
          <br />
          <br />• VNO có thể đề nghị và khách hàng có thể lựa chọn đồng ý sử dụng các tính năng chưa được phát hành rộng
          rãi và chưa được kiểm duyệt hoàn toàn về mặt chất lượng theo quy trình của VNO (chức năng Beta). Mục đích của
          việc này là để khách hàng kiểm duyệt và cung cấp phản hồi cho VNO. Khách hàng hoàn toàn chịu trách nhiệm về
          những rủi ro khi sử dụng các chức năng này. VNO không đảm bảo về tính đúng đắn, đầy đủ của các chức năng Beta
          cũng như không chịu trách nhiệm cho các lỗi sai hoặc thiệt hại gây ra do việc sử dụng các chức năng Beta.
        </p>
        <h5 className={classes.txtTitSubMenu}>9. Thông tin/ thông báo</h5>
        <p className={classes.content}>
          • Trong quá trình sử dụng, khách hàng đồng ý nhận các thông tin/thông báo do VNO gửi với nội dung và phương
          thức như sau:
          <br />
          <br />- Nội dung các thông báo bao gồm nhưng không giới hạn bởi các loại thông tin như sau:
          <br />
          <br />- Thông tin về các tính năng mới của sản phẩm;
          <br />
          <br />- Thông tin về các phiên bản mới của sản phẩm;
          <br />
          <br />- Thông tin về các sản phẩm có liên quan;
          <br />
          <br />- Thông tin về nội dung các bài báo hoặc bản tin mà VNO cho rằng có thể hữu ích cho khách hàng trong quá
          trình hoạt động.
          <br />
          <br />• Phương thức gửi thông báo bao gồm nhưng không giới hạn bởi các hình thức sau:
          <br />
          <br />- Thông báo trực tiếp trên màn hình sản phẩm;
          <br />
          <br />- Thông báo qua email;
          <br />
          <br />- Thông báo qua tin nhắn trên điện thoại di động;
          <br />
          <br />- Thông báo qua điện thoại;
          <br />
          <br />- Thông báo qua văn bản;
          <br />
          <br />- Thông báo bằng cách gặp trao đổi trực tiếp;
          <br />
          <br />- Các hình thức thông báo khác.
        </p>
        <h5 className={classes.txtTitSubMenu}>10. Giới hạn trách nhiệm pháp lý và thực hiện dịch vụ</h5>
        <p className={classes.content}>
          • VNO không cam đoan, tuyên bố, hoặc bảo đảm rằng việc khách hàng sử dụng sản phẩm/dịch vụ Yschool của VNO sẽ
          không bị gián đoạn hoặc không bị lỗi, hoặc sản phẩm/dịch vụ sẽ đáp ứng yêu cầu khách hàng hoặc tất cả các lỗi
          trên phần mềm và/hoặc tài liệu sẽ được sửa hoặc hệ thống tổng thể đảm bảo hoạt động của sản phẩm/dịch vụ
          Yschool (bao gồm nhưng không giới hạn: mạng internet, các mạng truyền dẫn khác, mạng nội bộ và các thiết bị
          của khách hàng) sẽ không có virus hoặc không có thành phần gây hại.
          <br />
          <br />• VNO không đảm bảo dưới bất kỳ hình thức nào, dù rõ ràng hay ngầm định về các điều kiện như sự thỏa mãn
          về chất lượng, phù hợp cho nhu cầu sử dụng đặc thù hoặc không xâm phạm các quyền của bên thứ ba. Dịch vụ của
          VNO được cung cấp cho khách hàng dưới dạng “theo hiện trạng - as is” và “có sẵn - as available” cho khách hàng
          sử dụng. Khách hàng sẽ chịu toàn bộ trách nhiệm trong việc xác định xem sản phẩm/dịch vụ Yschool hoặc thông
          tin được tạo ra từ sản phẩm/dịch vụ Yschool là đúng đắn và đáp ứng đầy đủ cho mục đích sử dụng của khách hàng.
          <br />
          <br />• Trong bất cứ trường hợp nào VNO đều không chịu trách nhiệm về bất kỳ các thiệt hại nào trực tiếp, gián
          tiếp, ngẫu nhiên, đặc biệt, hậu quả hoặc mang tính chất trừng phạt, bao gồm nhưng không giới hạn ở các thiệt
          hại do mất doanh thu, lợi nhuận, lợi thế kinh doanh, ngừng việc, mất mát dữ liệu do hậu quả của:
          <br />
          <br />- Việc sử dụng hoặc không thể sử dụng sản phẩm/dịch vụ Yschool;
          <br />
          <br />- Bất kỳ các thay đổi nào được thực hiện đối với sản phẩm/dịch vụ Yschool;
          <br />
          <br />- Truy cập trái phép hoặc biến đổi trái phép các dữ liệu;
          <br />
          <br />- Xóa, sai hỏng, hoặc không lưu trữ dữ liệu có trên hoặc thông qua sản phẩm/dịch vụ Yschool;
          <br />
          <br />- Các tuyên bố hay hành vi của bất kỳ bên thứ ba nào đối với sản phẩm/dịch vụ Yschool;
          <br />
          <br />- Bất kỳ vấn đề nào khác liên quan đến sản phẩm/dịch vụ Yschool.
          <br />
          <br />• VNO được miễn trách nhiệm thực hiện nghĩa vụ được nêu trong Thỏa thuận này đối với các trường hợp bất
          khả kháng ghi trong Thỏa thuận này.
        </p>
        <h5 className={classes.txtTitSubMenu}>11. Trách nhiệm xử lý sự cố an ninh</h5>
        <p className={classes.content}>
          • Trong trường hợp khách hàng phát hiện ra các sự cố an ninh của Yschool, khách hàng có trách nhiệm thông báo
          ngay với VNO bằng cách ấn nút Phản hồi ngay trên giao diện sản phẩm hoặc gọi điện đến tổng đài 19006474. Các
          sự cố an ninh phần mềm bao gồm nhưng không giới hạn bởi các trường hợp sau:
          <br />
          <br />- Bị mất hoặc thay đổi dữ liệu trên phần mềm mà không biết nguyên nhân;
          <br />
          <br />- Bị gián đoạn không sử dụng được sản phẩm;
          <br />
          <br />- Nghi ngờ bị hacker tấn công.
          <br />
          <br />• Khi xảy ra sự cố an ninh thông tin liên quan đến Yschool, VNO sẽ có trách nhiệm tổ chức điều tra để xử
          lý sự cố và khôi phục hoạt động cho khách hàng trong thời gian sớm nhất có thể. Trong quá trình điều tra và
          khắc phục sự cố, khách hàng phải có trách nhiệm tham gia, hỗ trợ nếu VNO có yêu cầu.
          <br />
          <br />• Khách hàng chịu trách nhiệm và miễn trừ toàn bộ trách nhiệm cho VNO về việc sử dụng hoặc hoạt động
          trên Yschool của Khách hàng trước pháp luật. Bất kỳ hoạt động gian lận, lừa gạt hoặc hoạt động bất hợp pháp
          khác có thể là lý do chấm dứt Tài Khoản của Khách hàng, theo toàn quyền quyết định của VNO, và VNO có thể
          trình báo về Khách hàng với các cơ quan thực thi pháp luật phù hợp.
        </p>
        <h5 className={classes.txtTitSubMenu}>12. Bất khả kháng</h5>
        <p className={classes.content}>
          • Trong trường hợp bất khả kháng, không bên nào bị xem là có lỗi và chịu trách nhiệm bồi thường với bên còn
          lại nếu không thực hiện hoặc thực hiện không đúng, không đầy đủ các nghĩa vụ và trách nhiệm của mình được quy
          định trong Thỏa thuận này mà nguyên nhân của việc không thực hiện, thực hiện không đúng, không đủ này có
          nguyên nhân từ sự kiện bất khả kháng. Hai bên nhất trí coi các trường hợp sau là bất khả kháng:
          <br />
          <br />- Thiên tai, địch họa gây cách trở hoặc phá hủy hoặc tắc nghẽn hoặc dừng kết nối đến trung tâm dữ liệu
          của VNO.
          <br />
          <br />- Sự cố mất điện trên diện rộng; Sự cố đứt cáp viễn thông gây tắc nghẽn hoặc ngừng kết nối viễn thông,
          Internet đến trung tâm dữ liệu của VNO.
          <br />
          <br />- Tin tặc (hacker), vi rút máy tính (virus) tấn công vào trung tâm dữ liệu của VNO làm ngừng trệ, tắc
          nghẽn hoặc phá hủy phần mềm và dữ liệu.
          <br />
          <br />- Các sự cố bất khả kháng khác theo quy định của pháp luật.
          <br />
          <br />• Các bên thống nhất với nhau rằng, trong trường hợp bất khả kháng xảy ra, các bên sẽ cùng nhau phối hợp
          và/hoặc trong phạm vi khả năng hết mức của mình, tiến hành thông báo cho bên còn lại về sự cố phát sinh do
          tình huống bất khả kháng, thực hiện các biện pháp khắc phục, sửa chữa các sự cố, gián đoạn. Đồng thời, các bên
          phải ngay lập tức thực hiện tiếp tục các trách nhiệm và nghĩa vụ của mình theo Thỏa thuận ngay khi sự kiện bất
          khả kháng không còn.
        </p>
        <h5 className={classes.txtTitSubMenu}>13. Tạm ngừng và chấm dứt thỏa thuận</h5>
        <p className={classes.content}>
          • VNO có quyền tạm khóa tài khoản/tạm ngừng việc sử dụng của khách hàng đối với dịch vụ và có thể mở tạm ngừng
          khi khách hàng chấm dứt hoặc cam kết chấm dứt các hành vi vi phạm trong các trường hợp sau:
          <br />
          <br />- Khách hàng không thực hiện việc đăng ký gia hạn và thanh toán các khoản chi phí sử dụng Yschool sau
          khi quá hạn; Thời gian thanh toán được VNO thông báo theo từng thời kỳ.
          <br />
          <br />- VNO cho rằng Yschool đang được khách hàng sử dụng để tham gia vào các cuộc tấn công từ chối dịch vụ,
          gửi thư rác, các hoạt động bất hợp pháp hoặc việc sử dụng sản phẩm/dịch vụ của khách hàng gây nguy hại tới VNO
          và những cá nhân/tổ chức khác.
          <br />
          <br />- Khách hàng có các hành vi nêu tại khoản 2.2 của Thỏa thuận.
          <br />
          <br />- Tài khoản của khách hàng xảy ra sự cố như tại quy định của khoản 4.3 và khoản 4.4 mà VNO cần có thời
          gian khắc phục, việc tạm khóa tài khoản nhằm đảm bảo tài khoản của khách hàng trước sự đăng nhập trái phép từ
          bên thứ ba. VNO sẽ mở tạm khóa khi lỗi bảo mật được khắc phục hoàn toàn.
          <br />
          <br />- Thông tin về nội dung các bài báo hoặc bản tin mà VNO cho rằng có thể hữu ích cho khách hàng trong quá
          trình hoạt động.
          <br />
          <br />• Thỏa thuận được coi như chấm dứt trong các trường hợp sau:
          <br />
          <br />- VNO đơn phương chấm dứt thỏa thuận do khách hàng không thực hiện nghĩa vụ thanh toán cho VNO theo thỏa
          thuận giữa hai bên;
          <br />
          <br />- VNO đơn phương chấm dứt khi khách hàng không chấm dứt, khắc phục, tiêu hủy các dữ liệu và hành vi vi
          phạm được nêu tại khoản 13.1b và 13.1c trong thời hạn 07 ngày làm việc sau khi bị VNO tạm ngưng, hoặc khách
          hàng vẫn tái phạm sau khi VNO đã mở tạm ngưng.
          <br />
          <br />- VNO đơn phương chấm dứt thỏa thuận theo yêu cầu của tòa án và cơ quan có thẩm quyền của nhà nước;
          <br />
          <br />- Khách hàng gửi thông báo yêu cầu chấm dứt thỏa thuận cho VNO bằng văn bản.
          <br />
          <br />• VNO không có nghĩa vụ hoàn trả bất kể chi phí nào mà khách hàng đã thanh toán cho các dịch vụ đã sử
          dụng từ Yschool trong trường hợp chấm dứt thỏa thuận vì những lý do đã nêu trên. VNO chỉ chịu trách nhiệm bảo
          đảm duy trì dữ liệu của khách hàng trên hệ thống tối đa là 90 ngày kể từ ngày chấm dứt thỏa thuận.
        </p>
      </div>
    );
  };

  const userAgreement = () => {
    return (
      <div>
        <p className={classes.txtTitSubMenu}>I. MỤC ĐÍCH, ĐỐI TƯỢNG VÀ PHẠM VI ÁP DỤNG</p>
        <p className={classes.content}>
          Thỏa thuận người dùng được ban hành với mục đích là sự thỏa thuận, cam kết giữa khách hàng và Công ty Cổ phần
          Dịch vụ Thương mại Việt Nam Trực Tuyến (“VNO”) trước và trong quá trình sử dụng ứng dụng thanh toán và tương
          tác Trường em - Yschool (“Yschool”).
        </p>
        <p className={classes.content}>Thỏa thuận này bao gồm:</p>
        <p className={classes.content}>
          • Quy định đăng ký sử dụng
          <br />
          <br />• Chính sách bảo mật thông tin
          <br />
          <br />• Quy trình giải quyết khiếu nại
          <br />
          <br />• Quy trình giải quyết khiếu nại
          <br />
          <br />• Chính sách hoàn trả và bồi thường khiếu nại
        </p>

        <p className={classes.content}>
          Thỏa thuận được áp dụng cho khách hàng là cá nhân, tổ chức có nhu cầu sử dụng các dịch vụ do VNO cung cấp
          thông qua Yschool.
        </p>
        {/* ------------------- NỘI DUNG THỎA THUẬN NGƯỜI DÙNG YSCHOOL  -------------- */}
        <p className={classes.txtTitSubMenu}>II NỘI DUNG THỎA THUẬN NGƯỜI DÙNG YSCHOOL</p>
        <p className={classes.content}>
          Thỏa thuận Người dùng (“Thỏa thuận”) là thỏa thuận pháp lý giữa khách hàng với VNO, quy định các điều khoản
          trong việc khách hàng sử dụng dịch vụ Yschool. Thỏa thuận này sẽ có hiệu lực khi khách hàng chấp nhận tất cả
          các điều khoản được nêu tại đây trong khi cài đặt Yschool hoặc khi khách hàng bắt đầu sử dụng Yschool theo bất
          kỳ cách nào khác.
          <br />
          <br />
          VNO bảo toàn quyền chỉnh sửa nội dung các điều khoản sử dụng này tại bất kì thời điểm nào.
          <br />
          <br />
          VNO sẽ thông báo tới khách hàng khi có cập nhật trong Thỏa thuận này. Bản điều chỉnh có hiệu lực kể từ thời
          điểm khách hàng nhận được thông báo. Khách hàng hoàn toàn chịu trách nhiệm trong việc nhận thức và tuân thủ
          các quy định của pháp luật khi sử dụng Yschool, bao gồm nhưng không giới hạn, đối với những quy định về tính
          hợp pháp của thông tin do khách cung cấp trên ứng dụng và/hoặc các giao dịch, hàng hóa/dịch vụ cung cấp, nguồn
          gốc giá trị tiền tệ thanh toán của khách hàng trên Yschool.
        </p>
        <p className={classes.txtTitSubMenu}>1. Định nghĩa</p>
        <p className={classes.content}>
          • Công ty Cổ phần Dịch vụ Thương mại Việt Nam Trực Tuyến (“VNO”): Là đơn vị sở hữu và là nhà cung cấp Yschool.
          <br />
          <br />
          • Ứng dụng thanh toán và tương tác Yschool (“Yschool”): Là Ứng dụng mang tên Yschool do VNO là chủ sở hữu và
          cung cấp.
          <br />
          <br />
          • Hệ thống: Bao gồm các máy chủ đặt tại trung tâm dữ liệu của VNO, được cài đặt các phần mềm hệ thống và phần
          mềm Yschool.
          <br />
          <br />
          • Lệ phí/phí dịch vụ: là các khoản phát sinh trong quá trình sử dụng ứng dụng Yschool (nếu có).
          <br />
          <br />
          • Dịch vụ: là các dịch vụ mà ứng dụng Yschool cung cấp.
          <br />
          <br />
          • Khách hàng/Người dùng: Là các tổ chức (bao gồm trường học) và/hoặc cá nhân (bao gồm giáo viên, phụ huynh,
          học sinh, sinh viên) có nhu cầu sử dụng Yschool hoặc sử dụng một trong các ứng dụng/tính năng được phát triển
          bởi VNO dựa trên Yschool.
          <br />
          <br />
          • Các Bên: là các bên tham gia vào hệ thống Yschool, bao gồm nhưng không giới hạn: VNO và Khách hàng/Người
          dùng.
          <br />
          <br />
          • Tài khoản Yschool: là tài khoản mà người dùng thông qua đó sử dụng các dịch vụ trên ứng dụng Yschool.
          <br />
          <br />
          • Thông tin truy cập: là mật khẩu, thông tin đăng nhập chi tiết và bất kỳ thông tin bảo mật liên quan đến tài
          khoản Yschool của người dùng.
          <br />
          <br />
          • Pháp luật: là toàn bộ hệ thống luật của Nước Cộng hòa Xã hội Chủ nghĩa Việt Nam, bao gồm bộ luật, luật và
          các văn bản dưới luật.
          <br />
          <br />
          • Ngày làm việc: là các ngày làm việc của VNO, từ 8h30 đến 18h00 từ thứ 2 đến thứ 6, ngoại trừ các ngày nghỉ,
          lễ, tết theo quy định của pháp luật.
          <br />
          <br />
        </p>
        {/* ------------------- Quyền sử dụng Yschool  -------------- */}
        <p className={classes.txtTitSubMenu}>2. Quyền sử dụng Yschool</p>
        <p className={classes.content}>
          • Khách hàng có quyền sử dụng đầy đủ các tính năng của Yschool khi đăng ký sử dụng dịch vụ theo các chính sách
          được quy định của VNO.
          <br />
          <br />• Khách hàng KHÔNG được phép sử dụng dịch vụ Yschool cho những mục đích bao gồm nhưng không giới hạn:
          khai thác thông tin sản phẩm, hệ thống, truy xuất/cập nhật dữ liệu, gửi email, viết bài hoặc truyền tải dữ
          liệu để:
          <br />
          <br />
        </p>
        <p className={classes.content}>
          o Làm tổn hại, làm phiền cho người khác hoặc gây ra thương tổn đến con người và tài sản;
          <br />
          <br />
          o Liên quan đến việc công bố các thông tin hoặc tài liệu lừa đảo, gây mất uy tín danh dự cho bất kỳ tổ chức,
          cá nhân nào, hoặc mang tính chất không lành mạnh bao gồm nhưng không giới hạn như: quấy rối, khiêu dâm, bạo
          lực, kinh dị, có nội dung vi phạm các chuẩn mực đạo đức của xã hội;
          <br />
          <br />
          o Xâm phạm các quyền riêng tư hoặc kì thị chủng tộc, chính trị, tôn giáo, quốc gia, vùng miền, giới tính,
          người tàn tật;
          <br />
          <br />
          o Xâm phạm quyền sở hữu trí tuệ hoặc các quyền sở hữu khác;
          <br />
          <br />
          o Cản trở hoặc phá hỏng Yschool (bao gồm nhưng không giới hạn bởi việc truy cập trái phép Yschool thông qua
          bất cứ phương tiện máy móc, phần mềm);
          <br />
          <br />
          o Vi phạm các quy định của pháp luật.
          <br />
          <br />
        </p>
        <p className={classes.content}>
          Việc vi phạm các quy định nêu trên của Người dùng sẽ là cơ sở cho VNO được quyền đình chỉ, tạm khóa và/hoặc
          xóa bỏ tài khoản của Người dùng tại Yschool.
        </p>
        {/* ------------------- Chế độ phí và phương thức thanh toán  -------------- */}
        <p className={classes.txtTitSubMenu}>3. Chế độ phí và phương thức thanh toán</p>
        <p className={classes.content}>
          Phí sử dụng dịch vụ Yschool và phương thức thanh toán được căn cứ theo biểu phí được VNO công bố cho từng thời
          kỳ.
        </p>
        {/* ------------------- Tài khoản Yschool của khách hàng   -------------- */}
        <p className={classes.txtTitSubMenu}>4. Tài khoản Yschool của khách hàng</p>
        <p className={classes.content}>
          • Trong quá trình đăng ký sử dụng Yschool, khách hàng sẽ tạo tài khoản và mật khẩu, đồng thời cung cấp một số
          thông tin của người dùng. Khách hàng đồng ý việc thu thập, ghi nhận, xử lý, lưu trữ, chia sẻ thông tin của
          người dùng theo quy định của Thỏa Thuận này nhằm phụ vụ cho các mục đích của ứng dụng Yschool.
          <br />
          <br />• Khách hàng có trách nhiệm phải tự bảo mật mật khẩu và tài khoản của mình cũng như chịu trách nhiệm
          hoàn toàn về các hoạt động liên quan đến tài khoản của khách hàng. Với mỗi một tài khoản trong hệ thống
          Yschool, khách hàng là trường học có thể tạo các tài khoản riêng biệt cho giáo viên của trường học đó tham
          gia. Khách hàng tự trang bị đầy đủ, bảo dưỡng thường xuyên các thiết bị, máy móc kết nối phần mềm ứng dụng để
          đảm bảo kết nối và truy cập an toàn đến hệ thống Yschool.
          <br />
          <br />
          • Khách hàng có trách nhiệm cung cấp các thông tin theo yêu cầu của Yschool để phục vụ cho việc định danh và
          thực hiện đúng trình tự, thủ tục các giao dịch thanh toán, các hướng dẫn an toàn bảo mật của ứng dụng Yschool
          và VNO. Khách hàng tuyên bố và bảo đảm rằng các thông tin của Khách hàng và các thông tin khác được cung cấp
          cho VNO là trung thực và chính xác. Khách hàng phải bảo đảm thoát/đăng xuất khỏi tài khoản của mình sau mỗi
          phiên sử dụng. Khách hàng thông báo ngay cho VNO khi phát hiện hoặc nghi ngờ có bất cứ ai sử dụng tài khoản
          và/hoặc mật khẩu của mình mà không được phép hay bất cứ lỗi bảo mật nào đối với hệ thống/ứng dụng Yschool. VNO
          không thể và sẽ không chịu trách nhiệm cho bất cứ sự mất mát, hư hỏng hay trách nhiệm nào khác nếu khách hàng
          không tuân thủ điều lệ này hay do sự truy cập trái phép vào tài khoản của khách hàng gây ra do lỗi của khách
          hàng.
          <br />
          <br />• VNO không có trách nhiệm thực hiện lệnh thanh toán thông qua Yschool của Người dùng khi Người dùng
          không thực hiện đầy đủ các yêu cầu về thủ tục thanh toán, xác nhận thông tin thanh toán trên ứng dụng hoặc
          trong trường hợp tài khoản của Người dùng đã bị tạm khóa, đình chỉ, chấm dứt vì lý do bảo mật thông tin, khắc
          phục sự cố hoặc giải quyết tranh chấp phát sinh.
          <br />
          <br />• Khách hàng phải thông báo kịp thời cho VNO khi phát hiện hoặc nghi ngờ có sai sót, nhầm lẫn trong giao
          dịch và/hoặc lộ, mất thông tin tài khoản người dùng.
          <br />
          <br />• Trong trường hợp có tranh chấp giữa hai hoặc nhiều bên về quyền sở hữu tài khoản, khách hàng đồng ý
          rằng VNO sẽ là bên duy nhất giải quyết tranh chấp đó và quyết định của VNO (có thể bao gồm việc chấm dứt hoặc
          đình chỉ tài khoản tranh chấp) là quyết định cuối cùng và có giá trị ràng buộc tất cả các bên.
          <br />
          <br />• Khách hàng phải phối hợp cùng VNO giải quyết các vấn đề phát sinh trong quá trình sử dụng ứng dụng,
          bao gồm nhưng không giới hạn: cung cấp thông tin theo yêu cầu của VNO, tuân thủ theo các hướng dẫn khắc phục
          trong các trường hợp có sai sót từ Yschool, khắc phụ các sự cố và lỗi bảo mật, khôi phục tài khoản, thanh toán
          nhầm, thanh toán thiếu và thanh toán dư đối với các khoản chi phí mà người dùng đã thanh toán thông qua ứng
          dụng Yschool.
        </p>

        {/* ------------------- Tư vấn và hỗ trợ khách hàng   -------------- */}
        <p className={classes.txtTitSubMenu}>5. Tư vấn và hỗ trợ khách hàng</p>
        <p className={classes.content}>
          • VNO chịu trách nhiệm cung cấp dịch vụ tư vấn hỗ trợ cho khách hàng trong suốt quá trình sử dụng thông qua
          tổng đài tư vấn và hỗ trợ khách hàng 19006474, email mailto: support@yopayment.vn , diễn đàn và các hình thức
          hỗ trợ khác được công bố tại website https://yschool.vn <br />
          <br />• Khi sử dụng dịch vụ tư vấn qua tổng đài tư vấn và hỗ trợ khách hàng 19006474, khách hàng chấp nhận trả
          cước phí điện thoại theo quy định của nhà cung cấp dịch vụ viễn thông.
          <br />
          <br />• Các dịch vụ tư vấn hỗ trợ thông qua hình thức khác (như dịch vụ tư vấn hỗ trợ tại các địa điểm theo
          yêu cầu của khách hàng, dịch vụ tái đào tạo hướng dẫn sử dụng cho khách hàng) sẽ được hai bên thống nhất về
          chi phí và phương thức cung cấp bằng văn bản bổ sung khi có phát sinh yêu cầu.
          <br />
          <br />
        </p>

        {/* ------------------- Bảo hành, bảo trì   -------------- */}
        <p className={classes.txtTitSubMenu}>6. Bảo hành, bảo trì</p>
        <p className={classes.content}>
          • VNO chịu trách nhiệm đảm bảo điều kiện kỹ thuật để khách hàng có thể sử dụng Yschool 24h/ngày và 7 ngày/tuần
          ngoại trừ thời gian bảo trì, nâng cấp, khắc phục sự cố cho hệ thống. Thời gian ngưng hệ thống để bảo trì hoặc
          nâng cấp hoặc sao lưu sẽ được VNO báo trước lịch thực hiện cho khách hàng theo hình thức thông báo trực tiếp
          trên Yschool. Lịch bảo trì hoặc nâng cấp hoặc sao lưu sẽ thực hiện theo định kỳ hàng ngày hoặc hàng tuần hoặc
          hàng tháng hoặc hàng năm và ưu tiên vào buổi đêm khi hệ thống ít sử dụng nhất.
          <br />
          <br />• VNO có trách nhiệm tiến hành khắc phục sự cố của hệ thống nhanh nhất có thể kể từ khi tiếp nhận được
          yêu cầu từ khách hàng.
          <br />
          <br />• VNO có trách nhiệm cập nhật phiên bản mới nhất của Yschool cho khách hàng sử dụng.
          <br />
          <br />• Khách hàng đồng ý chấp nhận tất cả sự vá lỗi, sửa lỗi, nâng cấp, bảo trì cần thiết để các tính năng
          của Yschool hoạt động chính xác và đảm bảo tính bảo mật. Trừ trường hợp khẩn cấp, VNO sẽ thông báo trước tới
          khách hàng lịch trình của các hoạt động sửa lỗi, nâng cấp này.
        </p>

        {/* ------------------- Bảo mật   -------------- */}
        <p className={classes.txtTitSubMenu}>7. Bảo mật</p>
        <p className={classes.content}>
          • Dữ liệu của khách hàng là mọi thông tin mà khách hàng gửi, tải lên, truyền hoặc bằng bất kể phương thức nào
          giúp hiển thị thông tin đó trên trong khoản của khách hàng trên Yschool. Dữ liệu đó bao gồm nhưng không giới
          hạn: các dữ liệu thông tin định danh cần thiết mà Người dùng đã sử dụng đề đăng ký và sử dụng tài khoản
          Yschool, thông tin liên hệ của khách hàng, các số liệu tài khoản, các tin nhắn, các thông báo, các tập tin,
          các hình ảnh, các video, các ý kiến, các nhận xét, các đường dẫn, các nội dung liên quan đến công việc và tất
          cả mọi thông tin được hiển thị trên tài khoản Yschool của khách hàng; các giao dịch, thao tác, sửa đổi, bổ
          sung của người trong quá trình sử dụng Yschool. Khách hàng là chủ sở hữu và có toàn quyền kiểm soát về việc
          truy cập dữ liệu được đăng tải trên tài khoản/hệ thống Yschool của khách hàng.
          <br />
          <br />• Khách hàng hiểu rằng VNO không sở hữu bất kì dữ liệu nào mà khách hàng đăng tải lên Yschool; trừ các
          tài liệu và hoặc giao diện hướng dẫn được VNO chủ động cung cấp công khai cho khách hàng.
          <br />
          <br />• Việc quyết định đưa dữ liệu nào lên Yschool là toàn quyền của khách hàng. Tuy nhiên, những nội dung
          được quy định tại khoản 2.2 của Thỏa thuận sẽ không được phép đăng tải trên Yschool. VNO có toàn quyền vô hiệu
          hóa quyền truy cập vào nội dung dữ liệu của khách hàng nếu VNO xác định dữ liệu đó vi phạm điều khoản này,
          hoặc chúng tôi nhận được đơn khiếu nại từ các tài khoản, hoặc thông báo vi phạm sở hữu trí tuệ và/hoặc thông
          báo nội dung có dấu hiệu vi phạm pháp luật của các cơ quan có thẩm quyền.
          <br />
          <br />• VNO chịu trách nhiệm thực hiện và duy trì tất cả các biện pháp bảo vệ mang tính hành chính, vật lý và
          kỹ thuật để bảo vệ cho tính bảo mật và toàn vẹn đối với dữ liệu khách hàng. VNO cam kết sẽ không:
        </p>

        <p className={classes.content}>
          o Sửa đổi, xóa dữ liệu khách hàng mà không có sự đồng ý của khách hàng hoặc không phải vì mục đích khắc phục
          lỗi hay sự cố;
          <br />
          <br />o Không tiết lộ dữ liệu khách hàng trừ trường hợp phải tuân theo quy định của pháp luật hoặc được khách
          hàng cho phép hoặc theo các quy định khác của Thỏa thuận này;
          <br />
          <br />o Không Truy cập vào dữ liệu và/hoặc làm thay đổi dữ liệu của khách hàng trừ trường hợp khắc phục lỗi kỹ
          thuật hoặc theo yêu cầu của khách hàng khi sử dụng dịch vụ hỗ trợ.
          <br />
          <br />
        </p>

        <p className={classes.content}>
          • VNO chịu trách nhiệm bảo mật mọi thông tin về dữ liệu của khách hàng và không được phép tiết lộ cho bất kỳ
          bên thứ ba nào khác ngoại trừ: yêu cầu của cơ quan có thẩm quyền của nhà nước; các đại lý, bên bán hàng, nhà
          cung cấp, nhà thầu, đối tác và các bên khác cung cấp dịch vụ cho VNO, thực hiện các chức năng thay mặt Công ty
          hoặc các bên hợp tác thương mại, cho hoặc liên quan đến các mục đích mà các bên thứ ba đó được thuê hoặc các
          mục đích hợp tác của VNO với các bên thứ ba đó (tùy từng trường hợp áp dụng). VNO không chịu trách nhiệm về
          các thất thoát dữ liệu, bí mật thông tin của khách hàng do khách hàng vô tình hoặc cố ý gây ra.
          <br />
          <br />• Khách hàng chịu trách nhiệm xác định và xác thực quyền của tất cả những người dùng truy nhập vào dữ
          liệu của khách hàng.
          <br />
          <br />• Khách hàng chịu trách nhiệm đảm bảo bí mật thông tin tài khoản khách hàng trên hệ thống Yschool.
          <br />
          <br />• Khách hàng chịu trách nhiệm đối với toàn bộ các hoạt động thực hiện bởi các tài khoản của khách hàng
          và có trách nhiệm ngay lập tức thông báo với VNO về các truy cập trái phép vào tài khoản Yschool của khách
          hàng trong trường hợp phát hiện ra các truy cập trái phép đó.
          <br />
          <br />• VNO sẽ không chịu bất cứ trách nhiệm nào liên quan đến các tổn hại gây ra bởi khách hàng, bao gồm các
          cá nhân không có quyền truy cập vào Yschool vẫn có thể lấy được quyền truy cập do lỗi máy tính/ phần mềm hoặc
          hệ thống mạng nội bộ của khách hàng, không thuộc phạm vi quản lý của VNO.
          <br />
          <br />• Trong phạm vi của Thỏa thuận này, “Thông tin bí mật” bao gồm: Dữ liệu của khách hàng cung cấp trong
          quá trình sử dụng Yschool, công nghệ độc quyền của mỗi bên, quy trình nghiệp vụ và các thông tin kỹ thuật của
          sản phẩm, thiết kế, và toàn bộ quá trình trao đổi giữa hai bên liên quan đến Yschool. Bất kể những điều đã đề
          cập ở trên, “Thông tin bí mật” không bao gồm các thông tin mà:
          <br />
          <br />
        </p>

        <p className={classes.content}>
          o Đã được biết tới rộng rãi trước khi tiết lộ;
          <br />
          <br />o Được công chúng biết tới không phải do lỗi của bên nhận thông tin;
          <br />
          <br />o Dữ liệu tổng hợp trong đó không chứa bất kỳ thông tin cá nhân hoặc thông tin nào cụ thể của khách
          hàng.
          <br />
          <br />
        </p>

        <p className={classes.content}>
          • Trừ trường hợp được quy định tại Khoản 7.5 trên, Khách hàng và VNO cùng thỏa thuận:
        </p>
        <p className={classes.content}>
          o Thực hiện các biện pháp cần thiết để giữ bí mật cho tất cả các “Thông tin bí mật”;
          <br />
          <br />o Không sao chép, cung cấp một phần hay toàn bộ thông tin bảo mật cho bất kỳ bên thứ ba khi chưa có sự
          chấp thuận của bên có quyền sở hữu đối với “Thông tin bí mật”;
          <br />
          <br />o Không sử dụng “Thông tin bí mật” mà các bên đã cung cấp cho nhau phục vụ cho các mục đích khác ngoài
          mục đích thực hiện Thỏa thuận này.
          <br />
          <br />
        </p>
        {/* ------------------- Bản quyền phần mềm và dữ liệu   -------------- */}
        <p className={classes.txtTitSubMenu}>8. Bản quyền phần mềm và dữ liệu</p>
        <p className={classes.content}>
          • VNO là chủ sở hữu và có toàn quyền tác giả đối với Yschool.
          <br />
          <br />
          • Khách hàng có quyền sử dụng Yschool để tạo ra dữ liệu phục vụ công việc của đơn vị và có quyền tải về phần
          dữ liệu do chính đơn vị nhập vào hệ thống trong suốt thời gian được cấp phép sử dụng.
          <br />
          <br />• Khách hàng đồng ý rằng sản phẩm/dịch vụ, bao gồm nhưng không giới hạn: giao diện người sử dụng, đoạn
          âm thanh, đoạn video, hình ảnh, logo, nội dung hướng dẫn sử dụng và phần mềm được sử dụng để thực hiện sản
          phẩm/dịch vụ liên quan đến Yschool thuộc sở hữu riêng của VNO được bảo hộ bởi pháp luật về sở hữu trí tuệ và
          quyền tác giả. Khách hàng cam kết sẽ không sử dụng các thông tin hoặc tài liệu thuộc sở hữu riêng đó theo bất
          cứ cách thức nào ngoại trừ cho mục đích sử dụng sản phẩm/dịch vụ theo Thỏa thuận này. Không có phần nào trong
          sản phẩm/dịch vụ có thể được sao chép lại dưới bất kỳ hình thức nào hoặc bằng bất cứ phương tiện nào, trừ khi
          được cho phép một cách rõ ràng theo các điều khoản này. <br />
          <br />• Khách hàng đồng ý không sửa đổi, thuê, cho thuê, cho vay, bán, phân phối, cầm cố, thế chấp hoặc tạo ra
          các sản phẩm phát sinh dựa trên sản phẩm/dịch vụ theo bất cứ phương cách nào, và không khai thác sản phẩm/dịch
          vụ theo bất cứ phương thức không được phép nào, bao gồm nhưng không giới hạn ở việc xâm phạm hoặc tạo gánh
          nặng lên dung lượng của hệ thống mạng của VNO. <br />
          <br />• Việc sử dụng Yschool và/hoặc bất cứ phần nào của sản phẩm/dịch vụ liên quan đến Yschool như đã nêu
          trên, trừ khi việc sử dụng Yschool và/hoặc sản phẩm/dịch vụ đó như được cho phép theo Thỏa thuận này, đều bị
          nghiêm cấm và được coi là xâm phạm đến các quyền sở hữu trí tuệ và quyền tác giả, và khách hàng có thể phải
          chịu các hình phạt dân sự và hình sự, bao gồm cả việc bồi thường thiệt hại bằng tiền có thể được áp dụng đối
          với việc xâm phạm quyền sở hữu trí tuệ và quyền tác giả. <br />
          <br />• Để VNO có thể cung cấp dịch vụ sử dụng Yschool cho khách hàng, khách hàng đồng ý cho VNO quyền xử lý
          và truyền tải dữ liệu của khách hàng. VNO có quyền nhưng không có nghĩa vụ nào trong việc thực hiện các hành
          động khắc phục nếu như có bất cứ nội dung nào mà khách hàng vi phạm các điều khoản được liệt kê trong Thỏa
          thuận này. VNO không có bất kỳ trách nhiệm pháp lý nào đối với khách hàng trong các tình huống VNO thực hiện
          hành động khắc phục. Khách hàng là người duy nhất chịu trách nhiệm về tính chính xác, chất lượng, tính toàn
          vẹn, hợp pháp, tin cậy và phù hợp đối với tất cả dữ liệu của mình. <br />
          <br />• VNO có thể đề nghị và khách hàng có thể lựa chọn đồng ý sử dụng các tính năng chưa được phát hành rộng
          rãi và chưa được kiểm duyệt hoàn toàn về mặt chất lượng theo quy trình của VNO (chức năng Beta). Mục đích của
          việc này là để khách hàng kiểm duyệt và cung cấp phản hồi cho VNO. Khách hàng hoàn toàn chịu trách nhiệm về
          những rủi ro khi sử dụng các chức năng này. VNO không đảm bảo về tính đúng đắn, đầy đủ của các chức năng Beta
          cũng như không chịu trách nhiệm cho các lỗi sai hoặc thiệt hại gây ra do việc sử dụng các chức năng Beta.
        </p>

        {/* ------------------- Thông tin/ thông báo   -------------- */}
        <p className={classes.txtTitSubMenu}>9.  Thông tin/ thông báo</p>
        <p className={classes.content}>
          • Trong quá trình sử dụng, khách hàng đồng ý nhận các thông tin/thông báo do VNO gửi với nội dung và phương
          thức như sau:
        </p>
        <p className={classes.content}>
          o Nội dung các thông báo bao gồm nhưng không giới hạn bởi các loại thông tin như sau: <br />
          <br />o Thông tin về các tính năng mới của sản phẩm; <br />
          <br />o Thông tin về các phiên bản mới của sản phẩm; <br />
          <br />o Thông tin về các sản phẩm có liên quan; <br />
          <br />o Thông tin về nội dung các bài báo hoặc bản tin mà VNO cho rằng có thể hữu ích cho khách hàng trong quá
          trình hoạt động.
        </p>

        <p className={classes.content}>
          • Phương thức gửi thông báo bao gồm nhưng không giới hạn bởi các hình thức sau:
        </p>
        <p className={classes.content}>
          o Thông báo trực tiếp trên màn hình sản phẩm; <br />
          <br />o Thông báo qua email; <br />o Thông báo qua tin nhắn trên điện thoại di động; <br />
          <br />o Thông báo qua điện thoại; <br />
          <br />o Thông báo qua văn bản; <br />
          <br />o Thông báo bằng cách gặp trao đổi trực tiếp; <br />
          <br />o Các hình thức thông báo khác.
        </p>

        {/* ------------------- Giới hạn trách nhiệm pháp lý và thực hiện dịch vụ   -------------- */}
        <p className={classes.txtTitSubMenu}>10. Giới hạn trách nhiệm pháp lý và thực hiện dịch vụ</p>
        <p className={classes.content}>
          • VNO không cam đoan, tuyên bố, hoặc bảo đảm rằng việc khách hàng sử dụng sản phẩm/dịch vụ Yschool của VNO sẽ
          không bị gián đoạn hoặc không bị lỗi, hoặc sản phẩm/dịch vụ sẽ đáp ứng yêu cầu khách hàng hoặc tất cả các lỗi
          trên phần mềm và/hoặc tài liệu sẽ được sửa hoặc hệ thống tổng thể đảm bảo hoạt động của sản phẩm/dịch vụ
          Yschool (bao gồm nhưng không giới hạn: mạng internet, các mạng truyền dẫn khác, mạng nội bộ và các thiết bị
          của khách hàng) sẽ không có virus hoặc không có thành phần gây hại. <br />
          <br />• VNO không đảm bảo dưới bất kỳ hình thức nào, dù rõ ràng hay ngầm định về các điều kiện như sự thỏa mãn
          về chất lượng, phù hợp cho nhu cầu sử dụng đặc thù hoặc không xâm phạm các quyền của bên thứ ba. Dịch vụ của
          VNO được cung cấp cho khách hàng dưới dạng “theo hiện trạng - as is” và “có sẵn - as available” cho khách hàng
          sử dụng. Khách hàng sẽ chịu toàn bộ trách nhiệm trong việc xác định xem sản phẩm/dịch vụ Yschool hoặc thông
          tin được tạo ra từ sản phẩm/dịch vụ Yschool là đúng đắn và đáp ứng đầy đủ cho mục đích sử dụng của khách hàng.{' '}
          <br />
          <br />• Trong bất cứ trường hợp nào VNO đều không chịu trách nhiệm về bất kỳ các thiệt hại nào trực tiếp, gián
          tiếp, ngẫu nhiên, đặc biệt, hậu quả hoặc mang tính chất trừng phạt, bao gồm nhưng không giới hạn ở các thiệt
          hại do mất doanh thu, lợi nhuận, lợi thế kinh doanh, ngừng việc, mất mát dữ liệu do hậu quả của:
        </p>

        <p className={classes.content}>
          o Việc sử dụng hoặc không thể sử dụng sản phẩm/dịch vụ Yschool; <br />
          <br />o Bất kỳ các thay đổi nào được thực hiện đối với sản phẩm/dịch vụ Yschool; <br />
          <br />o Truy cập trái phép hoặc biến đổi trái phép các dữ liệu; <br />o Xóa, sai hỏng, hoặc không lưu trữ dữ
          liệu có trên hoặc thông qua sản phẩm/dịch vụ Yschool;
        </p>
        <p className={classes.content}>
          • Các tuyên bố hay hành vi của bất kỳ bên thứ ba nào đối với sản phẩm/dịch vụ Yschool; <br />
          <br />• Bất kỳ vấn đề nào khác liên quan đến sản phẩm/dịch vụ Yschool. <br />
          <br />• VNO được miễn trách nhiệm thực hiện nghĩa vụ được nêu trong Thỏa thuận này đối với các trường hợp bất
          khả kháng ghi trong Thỏa thuận này.
        </p>

        {/* ------------------- Trách nhiệm xử lý sự cố an ninh   -------------- */}
        <p className={classes.txtTitSubMenu}>11. Trách nhiệm xử lý sự cố an ninh</p>
        <p className={classes.content}>
          • Trong trường hợp khách hàng phát hiện ra các sự cố an ninh của Yschool, khách hàng có trách nhiệm thông báo
          ngay với VNO bằng cách ấn nút Phản hồi ngay trên giao diện sản phẩm hoặc gọi điện đến tổng đài 19006474. Các
          sự cố an ninh phần mềm bao gồm nhưng không giới hạn bởi các trường hợp sau:
        </p>
        <p className={classes.content}>
          o Bị mất hoặc thay đổi dữ liệu trên phần mềm mà không biết nguyên nhân; <br />
          <br />o Bị gián đoạn không sử dụng được sản phẩm; <br />
          <br />o Nghi ngờ bị hacker tấn công.
        </p>

        <p className={classes.content}>
          • Khi xảy ra sự cố an ninh thông tin liên quan đến Yschool, VNO sẽ có trách nhiệm tổ chức điều tra để xử lý sự
          cố và khôi phục hoạt động cho khách hàng trong thời gian sớm nhất có thể. Trong quá trình điều tra và khắc
          phục sự cố, khách hàng phải có trách nhiệm tham gia, hỗ trợ nếu VNO có yêu cầu. <br />
          <br />• Khách hàng chịu trách nhiệm và miễn trừ toàn bộ trách nhiệm cho VNO về việc sử dụng hoặc hoạt động
          trên Yschool của Khách hàng trước pháp luật. Bất kỳ hoạt động gian lận, lừa gạt hoặc hoạt động bất hợp pháp
          khác có thể là lý do chấm dứt Tài Khoản của Khách hàng, theo toàn quyền quyết định của VNO, và VNO có thể
          trình báo về Khách hàng với các cơ quan thực thi pháp luật phù hợp.
        </p>

        {/* ------------------- Bất khả kháng   -------------- */}
        <p className={classes.txtTitSubMenu}>12. Bất khả kháng</p>
        <p className={classes.content}>
          • Trong trường hợp bất khả kháng, không bên nào bị xem là có lỗi và chịu trách nhiệm bồi thường với bên còn
          lại nếu không thực hiện hoặc thực hiện không đúng, không đầy đủ các nghĩa vụ và trách nhiệm của mình được quy
          định trong Thỏa thuận này mà nguyên nhân của việc không thực hiện, thực hiện không đúng, không đủ này có
          nguyên nhân từ sự kiện bất khả kháng. Hai bên nhất trí coi các trường hợp sau là bất khả kháng: <br />
          <br />• Thiên tai, địch họa gây cách trở hoặc phá hủy hoặc tắc nghẽn hoặc dừng kết nối đến trung tâm dữ liệu
          của VNO. <br />
          <br />
          • Sự cố mất điện trên diện rộng; Sự cố đứt cáp viễn thông gây tắc nghẽn hoặc ngừng kết nối viễn thông,
          Internet đến trung tâm dữ liệu của VNO. <br />
          <br />• Tin tặc (hacker), vi rút máy tính (virus) tấn công vào trung tâm dữ liệu của VNO làm ngừng trệ, tắc
          nghẽn hoặc phá hủy phần mềm và dữ liệu. • Các sự cố bất khả kháng khác theo quy định của pháp luật. <br />
          <br />• Các bên thống nhất với nhau rằng, trong trường hợp bất khả kháng xảy ra, các bên sẽ cùng nhau phối hợp
          và/hoặc trong phạm vi khả năng hết mức của mình, tiến hành thông báo cho bên còn lại về sự cố phát sinh do
          tình huống bất khả kháng, thực hiện các biện pháp khắc phục, sửa chữa các sự cố, gián đoạn. Đồng thời, các bên
          phải ngay lập tức thực hiện tiếp tục các trách nhiệm và nghĩa vụ của mình theo Thỏa thuận ngay khi sự kiện bất
          khả kháng không còn.
        </p>

        <p className={classes.txtTitSubMenu}>13. Tạm ngừng và chấm dứt thỏa thuận</p>
        <p className={classes.content}>
          • VNO có quyền tạm khóa tài khoản/tạm ngừng việc sử dụng của khách hàng đối với dịch vụ và có thể mở tạm ngừng
          khi khách hàng chấm dứt hoặc cam kết chấm dứt các hành vi vi phạm trong các trường hợp sau: <br />
          <br />• Khách hàng không thực hiện việc đăng ký gia hạn và thanh toán các khoản chi phí sử dụng Yschool sau
          khi quá hạn; Thời gian thanh toán được VNO thông báo theo từng thời kỳ. <br />
          <br />• VNO cho rằng Yschool đang được khách hàng sử dụng để tham gia vào các cuộc tấn công từ chối dịch vụ,
          gửi thư rác, các hoạt động bất hợp pháp hoặc việc sử dụng sản phẩm/dịch vụ của khách hàng gây nguy hại tới VNO
          và những cá nhân/tổ chức khác. <br />
          <br />• Khách hàng có các hành vi nêu tại khoản 2.2 của Thỏa thuận. <br />
          <br />• Tài khoản của khách hàng xảy ra sự cố như tại quy định của khoản 4.3 và khoản 4.4 mà VNO cần có thời
          gian khắc phục, việc tạm khóa tài khoản nhằm đảm bảo tài khoản của khách hàng trước sự đăng nhập trái phép từ
          bên thứ ba. VNO sẽ mở tạm khóa khi lỗi bảo mật được khắc phục hoàn toàn. <br />
          <br />• Thỏa thuận được coi như chấm dứt trong các trường hợp sau: <br />
          <br />• VNO đơn phương chấm dứt thỏa thuận do khách hàng không thực hiện nghĩa vụ thanh toán cho VNO theo thỏa
          thuận giữa hai bên; <br />
          <br />• VNO đơn phương chấm dứt khi khách hàng không chấm dứt, khắc phục, tiêu hủy các dữ liệu và hành vi vi
          phạm được nêu tại khoản 13.1b và 13.1c trong thời hạn 07 ngày làm việc sau khi bị VNO tạm ngưng, hoặc khách
          hàng vẫn tái phạm sau khi VNO đã mở tạm ngưng.
          <br />
          <br />• VNO đơn phương chấm dứt thỏa thuận theo yêu cầu của tòa án và cơ quan có thẩm quyền của nhà nước;
          <br />
          <br />• Khách hàng gửi thông báo yêu cầu chấm dứt thỏa thuận cho VNO bằng văn bản.
          <br />
          <br />• VNO không có nghĩa vụ hoàn trả bất kể chi phí nào mà khách hàng đã thanh toán cho các dịch vụ đã sử
          dụng từ Yschool trong trường hợp chấm dứt thỏa thuận vì những lý do đã nêu trên. VNO chỉ chịu trách nhiệm bảo
          đảm duy trì dữ liệu của khách hàng trên hệ thống tối đa là 90 ngày kể từ ngày chấm dứt thỏa thuận.
          <br />
          <br />• Căn cứ pháp lý
        </p>
        <p className={classes.content}>
          o Căn cứ Bộ luật dân sự số 91/2015/QH13; <br />
          <br />o Căn cứ Luật thương mại số 36/2005/QH11; <br />
          <br />o Căn cứ Luật Công nghệ thông tin số 67/2006/QH11; <br />
          <br />o Căn cứ vào nhu cầu của hai bên.
        </p>

        <p className={classes.txtTitSubMenu}>•  Điều khoản chung</p>
        <p className={classes.content}>
          o Khi chưa có đồng ý bằng văn bản của VNO, khách hàng không được quyền chuyển nhượng bất cứ quyền và nghĩa vụ
          nào của khách hàng đã được xác định trong bản Thoả thuận này. <br />
          <br />o Thoả Thuận này được điều chỉnh theo pháp luật nước Cộng hòa xã hội chủ nghĩa Việt Nam. <br />
          <br />o Mọi tranh chấp, bất đồng phát sinh từ, và/hoặc liên quan đến việc sử dụng Yschool theo Thỏa thuận này,
          các Bên cùng nhau bàn bạc giải quyết trên cơ sở thương lượng đảm bảo các Bên cùng có lợi. Nếu không giải quyết
          được bằng thương lượng, các Bên thống nhất tranh chấp hoặc bất đồng đó sẽ được giải quyết tại quyết tại toà án
          nhân dân có thẩm quyền. Quyết định của Toà án có giá trị ràng buộc các Bên thi hành. Trong thời gian toà án
          chưa ra phán quyết, các Bên vẫn phải tiếp tục thực hiện nghĩa vụ và trách nhiệm của mỗi Bên theo quy định của
          Thoả Thuận này. Các chi phí liên quan đến việc giải quyết tranh chấp do Bên thua kiện theo phán quyết của toà
          án phải chịu.
        </p>
      </div>
    );
  };

  const privacyPolicy = () => {
    return (
      <div>
        <p className={classes.txtTitSubMenu}>I. MỤC ĐÍCH, ĐỐI TƯỢNG VÀ PHẠM VI ÁP DỤNG</p>
        <p className={classes.content}>
          Chính sách bảo mật được ban hành nhằm mục đích giúp khách hàng ( hoặc “người dùng”) sử dụng ứng dụng thanh
          toán và tương tác Trường Em - Yschool (“Yschool”) hiểu rõ những thông tin Công ty Cổ phần Dịch vụ Thương mại
          Việt Nam Trực Tuyến (“Công ty” hoặc “VNO”) thu thập và lý do Công ty thu thập những thông tin này của người
          dùng.
          <br />
          <br />
          Chính sách áp dụng cho người dùng là cá nhân, tổ chức có nhu cầu sử dụng các dịch vụ do VNO cung cấp thông qua
          Yschool.
          <br />
          <br />
          “Thông tin người dùng” nghĩa là mọi thông tin mà người dùng gửi, tải lên, truyền hoặc bằng bất kể phương thức
          nào giúp hiển thị thông tin đó trên tài khoản của người dùng trong Yschool. Dữ liệu đó bao gồm nhưng không
          giới hạn: các dữ liệu thông tin các nhân cần thiết mà người dùng đã sử dụng đề đăng ký và sử dụng tài khoản
          tại Yschool; các số liệu tài khoản, các tin nhắn, các thông báo, các tập tin, các hình ảnh, các video, các ý
          kiến, các nhận xét, các đường dẫn, các nội dung liên quan đến công việc và tất cả mọi thông tin được hiển thị
          trên tài khoản Yschool của người dùng; các giao dịch, thao tác, sửa đổi, bổ sung của người trong quá trình sử
          dụng Yschool. Người dùng là chủ sở hữu và có toàn quyền kiểm soát về việc truy cập dữ liệu được đăng tải trên
          tài khoản (hệ thống) Yschool của người dùng.
        </p>
        <p className={classes.txtTitSubMenu}>II. NỘI DUNG CHÍNH SÁCH BẢO MẬT THÔNG TIN NGƯỜI DÙNG YSCHOOL</p>
        <p className={classes.txtTitSubMenu}>1. Mục đích và phạm vi thu thập thông tin</p>
        <p className={classes.content}>
          Để phục vụ cho việc cung ứng các Dịch vụ liên quan đến Yschool (hay “Dịch vụ”), phân tích thông tin phục vụ
          việc bảo mật và giữ an toàn cho hệ thống, tạo cơ sở xác thực thông tin, chăm sóc khách hàng, cải thiện dịch
          vụ, VNO được quyền thu thập các loại thông tin người dùng bao gồm nhưng không giới hạn từ các nguồn sau đây:
          <br />
          <br />• Thông tin có được trực tiếp từ người dùng hay từ máy tính/thiết bị di động của người dùng
          <br />
          <br />
          Khi người dùng đăng ký và tạo tài khoản với Công ty qua Yschool, người dùng phải cung cấp cho VNO một số thông
          tin, bao gồm nhưng không hạn chế: số điện thoại, địa chỉ, email, số CMND/CCCD/Hộ chiếu và một danh tính (ID)
          tài khoản và/hoặc mật khẩu mà người dùng sẽ sử dụng để truy cập Yschool sau khi đăng ký.
          <br />
          <br />
          Khi người dùng sử dụng Yschool, người dùng phải cung cấp cho VNO những thông tin liên quan có thể được VNO yêu
          cầu hợp lý để VNO có thể cung ứng Dịch vụ cho người dùng.
          <br />
          <br />
          Người dùng có thể cung cấp thông tin thông qua việc truy cập thông tin cá nhân được lưu trữ bởi bên thứ ba,
          chẳng hạn thông tin về học sinh, lớp học, giáo viên, chương trình giảng dạy, thời khóa biểu, học phí của học
          sinh do bên thứ ba là các trường cung cấp, thông tin về tài khoản thanh toán, mã xác nhận từ bên thứ ba là
          ngân hàng.
        </p>
        <p className={classes.txtTitSubMenu}>2. Thông tin được thu thập bất cứ khi nào người dùng sử dụng Yschool</p>
        <p className={classes.content}>
          Bất cứ khi nào người dùng sử dụng Yschool, VNO đều có thể thu thập một số dữ liệu kỹ thuật liện quan đến việc
          sử dụng của người dùng.
          <br />
          <br />
          VNO có quyền thu thập các thông tin, dữ liệu liên quan đến giao dịch mà người dùng thực hiện khi thực hiện
          giao dịch trên Yschool, bao gồm nhưng không giới hạn ở thông tin, dữ liệu về giao dịch mà người dùng thực
          hiện, phương thức thanh toán (nhưng không lưu trữ các dữ liệu quan trọng của thẻ thanh toán được sử dụng cho
          việc thanh toán bao gồm số thẻ chi tiết, số CVV).
          <br />
          <br />
          Người dùng có thể cung cấp thông tin thông qua việc truy cập thông tin cá nhân được lưu trữ bởi bên thứ ba,
          chẳng hạn thông tin về học sinh, lớp học, giáo viên, chương trình giảng dạy, thời khói biểu, học phí của học
          sinh do bên thứ ba là các trường cung cấp, thông tin về tài khoản thanh toán, mã sát nhận từ bên thứ ba là
          ngân hàng.
        </p>
        <p className={classes.txtTitSubMenu}>3. Mục đích sử dụng thông tin</p>
        <p className={classes.content}>
          Các thông tin của người dùng được sử dụng để nâng cao chất lượng Dịch vụ và hỗ trợ cho việc phát triển các
          tính năng mới trên Yschool. Bên cạnh đó, để đảm bảo cho các giao dịch an toàn, nhanh chóng và hiệu quả, VNO sẽ
          sử dụng các thông tin người dùng trong các trường hợp sau:
          <br />
          <br />• Cung cấp các dịch vụ hỗ trợ và chăm sóc khách hàng;
          <br /> <br />• Xử lý giao dịch của người dùng và gửi các thông báo về giao dịch;
          <br /> <br />• Xác thực thông tin người dùng;
          <br /> <br />• Giải quyết các tranh chấp, khiếu nại (nếu có) từ khách hàng gửi đến VNO;
          <br /> <br />• Ngăn ngừa các hành vi bất hợp pháp, các rủi ro, gian lận trong quá trình giao dịch;
          <br /> <br />• Tùy chỉnh, đo lường, cải thiện nội dung và hình thức của Dịch vụ;
          <br />
          <br />• Thực hiện các hoạt động quảng bá liên quan đến các sản phẩm và dịch vụ của Yschool;
          <br />
          <br />• Đối chiếu, kiểm tra tính chuẩn xác của thông tin và xác thực thông tin với bên thứ ba;
          <br />
          <br />• Tra soát, ngăn chặn thiệt hại hoặc khắc phục những vi phạm của người dùng.
        </p>
        <p className={classes.txtTitSubMenu}>4. Bảo vệ và lưu trữ thông tin</p>
        <p className={classes.content}>
          Yschool bảo đảm thông tin người dùng sẽ được lưu trữ và xử lý một cách an toàn với các giải pháp bảo mật và
          công nghệ hàng đầu. Yschool lưu trữ và xử lý thông tin của người dùng trực tuyến trên hệ thống máy chủ được
          bảo vệ bằng hệ thống xác thực nhiều lớp, chống xâm nhập/dò, đánh cắp thông tin. Ngoài ra, thông tin người dùng
          khi truyền đi trên mạng sẽ được Yschool mã hóa, áp dụng cơ chế mã hóa bảo mật đường truyền.
          <br />
          <br />
          Để bảo vệ thông tin người dùng, hạn chế những rủi ro mất mát, chỉnh sửa, tiết lộ, sử dụng sai mục đích, truy
          cập không đúng thẩm quyền, VNO sử dụng những công nghệ phần cứng và phần mềm tiên tiến nhất trên thế giới để
          xây dựng hệ thống hạ tầng theo chuẩn quốc tế và đủ sức cung ứng Dịch vụ với yêu cầu cao nhất, thực hiện phân
          quyền tiếp cận thông tin một cách chặt chẽ và chính xác đối với cán bộ nhân viên của VNO.
          <br />
          <br />
          Thời gian lưu trữ thông tin là không giới hạn, khi người dùng đăng ký một tài khoản Yschool thì thông tin này
          sẽ tồn tại mãi mãi cho đến khi Yschool nhận được yêu cầu xóa thông tin cá nhân trên hệ thống Yschool từ người
          dùng.
        </p>

        <p className={classes.txtTitSubMenu}>5. Chia sẻ thông tin cá nhân</p>
        <p className={classes.content}>
          VNO có thể tiết lộ cho hoặc chia sẻ với các đối tác thông tin người dùng cho bất kỳ mục đích nào sau đây cũng
          như cho các mục đích khác được pháp luật cho phép (mặc dù trong mỗi trường hợp như vậy, Công ty sẽ luôn hành
          động một cách hợp lý và không tiết lộ hoặc chia sẻ thông tin người dùng vượt quá phạm vi cần thiết cho từng
          mục đích cụ thể):
          <br />
          <br />• Nhằm mục đích cung cấp Dịch vụ theo yêu cầu của người dùng;
          <br /> <br />• Trong trường hợp được yêu cầu hoặc cho phép theo luật áp dụng (bao gồm nhưng không giới hạn
          trong việc trả lời các câu hỏi, các cuộc điều tra hay chỉ thị theo quy định, hoặc tuân thủ các yêu cầu nộp hồ
          sơ và báo cáo theo pháp luật), cho mục đích được quy định trong luật áp dụng đó;
          <br /> <br />• Liên quan đến, hoặc trong các cuộc đàm phán về, một trường hợp sáp nhập, bán tài sản công ty,
          hợp nhất hoặc tái cơ cấu, việc cấp vốn cho hoặc mua lại toàn bộ hay một phần hoạt động kinh doanh của VNO bởi
          hoặc vào một công ty khác, cho các mục đích của giao dịch đó (ngay cả khi giao dịch đó cuối cùng không được
          tiến hành);
          <br /> <br />• Trong trường hợp Công ty chia sẻ thông tin người dùng với các bên thứ ba (bao gồm các đại lý,
          bên bán hàng, nhà cung cấp, nhà thầu, đối tác và các bên khác cung cấp dịch vụ cho VNO, thực hiện các chức
          năng thay mặt Công ty hoặc các bên hợp tác thương mại), cho hoặc liên quan đến các mục đích mà các bên thứ ba
          đó được thuê hoặc các mục đích hợp tác của VNO với các bên thứ ba đó (tùy từng trường hợp áp dụng). Trong
          trường hợp này, hợp đồng giữa Công ty và các bên thứ ba sẽ quy định rõ ràng và nghiêm ngặt các yêu cầu đối với
          việc các bên thứ ba phải có nghĩa vụ bảo mật các thông tin người dùng dựa trên các điều khoản và điều kiện mà
          VNO đã cam kết với người dùng theo Chính Sách Bảo Mật này.
          <br /> <br />• Ngoài các trường hợp được quy định trong Chính Sách Bảo Mật này, Công ty có thể tiết lộ hoặc
          chia sẻ thông tin người dùng sau khi thông báo trước về việc tiết lộ và chia sẻ thông tin này và đã nhận được
          sự chấp thuận của người dùng về việc tiết lộ hoặc chia sẻ thông tin.
          <br /> <br />• VNO sẽ không bán hoặc cho thuê hoặc tiết lộ thông tin người dùng dưới bất kỳ hình thức nào, cho
          bất kỳ bên thứ ba nào không liên quan và/hoặc không có chức năng như các trường hợp vừa nêu trên.
        </p>
        <p className={classes.txtTitSubMenu}>6. Chỉnh sửa thông tin cá nhân</p>
        <p className={classes.content}>
          Người dùng là người duy nhất có thể truy cập vào tài khoản Yschool của chính mình bằng thông tin truy cập mà
          mình đã đăng ký. Khi muốn cập nhật, xóa bỏ hay thay đổi thông tin, người dùng đăng nhập vào tài khoản và thao
          tác trên máy tính/thiết bị di động cung ứng Dịch vụ Yschool, Yschool sẽ lưu lại những thông tin bị thay đổi để
          chống lại các hành vi xóa dấu vết gian lận và dùng làm chứng cứ xác minh cho việc thay đổi thông tin của người
          dùng.
          <br />
          <br />
          Người dùng phải cam kết những thông tin sau khi chỉnh sửa là đầy đủ, có tính xác thực; và phải chịu trách
          nhiệm trước pháp luật về việc chỉnh sửa thông tin cá nhân của mình.
        </p>
        <p className={classes.txtTitSubMenu}>7. Cam kết bảo mật thông tin người dùng</p>
        <p className={classes.content}>
          VNO cam kết rằng những thông tin mà người dùng đã cung cấp cho Yschool sẽ được bảo mật và được sử dụng để đem
          lại lợi ích tối đa cho người dùng. Công ty sẽ nỗ lực để đảm bảo thông tin của người dùng được giữ bí mật. Tuy
          nhiên, VNO không chịu trách nhiệm trong trường hợp có sự truy cập trái phép thông tin người dùng hoặc các sự
          cố rò rỉ thông tin nếu nguyên nhân gây ra không bao gồm yếu tố lỗi từ phía VNO.
          <br />
          <br />
          Yschool có thể có các liên kết đến các website và hệ thống khác, phụ thuộc vào nhu cầu sử dụng của người dùng.
          Các website và hệ thống liên kết này có thể không thuộc phạm vi quản lý của VNO và Công ty không chịu trách
          nhiệm đối với bất kỳ website và hệ thống liên kết nào không thuộc phạm vi quản lý và kiểm soát của Công ty.
          VNO khuyến cáo người dùng nên suy xét và cẩn trọng trước khi chấp nhận bất kỳ liên hết nào dẫn đến các website
          và hệ thống khác, đặc biệt là các website và hệ thống không thuộc thẩm quyền quản lý của VNO.
          <br /> <br />
          VNO có đặc quyền và toàn quyền chỉnh sửa nội dung trên ứng dụng và hệ thống của Yschool mà không cần phải cảnh
          báo hoặc báo trước cho người dùng. Khi người dùng sử dụng ứng dụng và hệ thống của Yschool sau khi chỉnh sửa
          nghĩa là người dùng đã thừa nhận, đồng ý tuân thủ cũng như tin tưởng vào sự chỉnh sửa này.
          <br /> <br />
          VNO khuyên người dùng không nên đưa thông tin chi tiết về tài khoản Yschool hoặc bất kỳ thông tin nào khác của
          người dùng với bất kỳ ai bằng email hoặc phương tiện trao đổi thông tin khác, Công ty không chịu trách nhiệm
          về những thiệt hại có thể xảy ra trong trường hợp này.
          <br /> <br />
          Người dùng tuyệt đối không sử dụng bất kỳ chương trình, công cụ hay hình thức nào khác để can thiệp vào hệ
          thống hay làm thay đổi cấu trúc dữ liệu. Nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động
          nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của hệ thống, ứng dụng Yschool. Mọi vi phạm sẽ bị tước
          bỏ mọi quyền lợi đối với việc sử dụng Yschool cũng như sẽ bị truy tố trước pháp luật nếu cần thiết.
        </p>
        <p className={classes.txtTitSubMenu}>III. Điều khoản chung</p>
        <p className={classes.content}>
          Các điều kiện, điều khoản và nội dung của Chính sách này được điều chỉnh bởi luật pháp Việt Nam và tòa án Việt
          Nam có thẩm quyền xem xét, giải quyết đối với các tranh chấp nào xảy ra liên quan đến Chính sách này.
          <br />
          <br />
          Nếu có bất kỳ khác biệt nào giữa bản tiếng Việt của Chính Sách Bảo Mật này và các bản ngôn ngữ khác (nếu có),
          bản tiếng Việt sẽ được ưu tiên áp dụng.
        </p>
      </div>
    );
  };

  const returnPolicy = () => {
    return (
      <div>
        <p className={classes.txtTitSubMenu}>I. MỤC ĐÍCH, ĐỐI TƯỢNG VÀ PHẠM VI ÁP DỤNG</p>
        <p className={classes.content}>
          • Chính sách này được ban hành với mục đích tạo sự an tâm và tin phụ huynh có nhu cầu thanh toán học phí cho
          trường học (sau đây gọi là “khách hàng” hoặc “người dùng”) thông qua Ứng dụng thanh toán và tương tác Trường
          Em - Yschool (“YSCHOOL”)
          <br />
          <br />• Chính sách được áp dụng cho khách hàng là cá nhân, tổ chức có nhu cầu sử dụng các dịch vụ do Công ty
          Cổ phần Dịch vụ Thương mại Việt Nam Trực Tuyến (“VNO”) cung cấp thông qua ứng dụng YSCHOOL.
        </p>
        <p className={classes.txtTitSubMenu}>II. NỘI DUNG CHÍNH SÁCH</p>
        <p className={classes.content}>1. Các trường hợp chấp nhận phối hợp với bên thứ ba hoàn tiền</p>
        <p className={classes.content}>
          VNO chấp nhận sẽ phối hợp với bên thứ ba là Ngân hàng và/hoặc tổ chức cung ứng phần mềm thanh toán học phí
          và/hoặc trường học hoàn trả/bồi thường thiệt hại cho người dùng trong các trường hợp sau:
          <br />
          <br />• Thanh toán trùng hóa đơn học phí (1 hóa đơn học phí bị thanh toán từ 2 lần trở lên)
          <br />
          <br />• Thanh toán nhầm hóa đơn học phí
          <br />
          <br />• Thanh toán giao dịch thành công nhưng hoá đơn chưa được gạch nợ
          <br />
          <br />• Thanh toán sai số tiền cần thanh toán dẫn đến phần tiền người dùng đã thanh toán lớn hơn số tiền cần
          thanh toán
          <br />
          <br />• Các trường hợp sai sót hợp lí khác được công nhận
        </p>
        <p className={classes.content}>2. Quy định hoàn tiền</p>
        <p className={classes.content}>2.1. Nguyên tắc hoàn tiền</p>
        <p className={classes.content}>
          • VNO chỉ thực hiện hoàn tiền đối với các trường hợp tiền học phí người dùng đã thanh toán mà VNO đã nhận được
          nhưng vẫn chưa được đối soát và chuyển về tài khoản của trường học. Trường hợp tiền thanh toán đã được chuyển
          về trường học thì người dùng có trách nhiệm liên hệ làm việc với trường học để được giải quyết và hoàn tiền
          theo quy định của trường học.
          <br />
          <br />• Trường hợp Người dùng liên hệ yêu cầu hoàn tiền đến VNO thì VNO sẽ liên hệ với các bên có liên quan để
          đối soát yêu cầu hoàn trả/bồi thường của khách hàng. Trường hợp các bên thống nhất yêu cầu của khách hàng là
          có cơ sở chấp nhận thì VNO sẽ đề nghị ngân hàng thực hiện trích tiền từ tài khoản quản lí dòng tiền thu hộ cho
          Trường học để hoàn tiền cho khách hàng qua kênh mà khách hàng đã thực hiện thanh toán học phí theo đúng số
          tiền đã thanh toán nhầm, thanh toán sai hoặc thanh toán thừa trước đó.
          <br />
          <br />• Trường hợp VNO hoặc Bên thứ ba có liên kết với VNO phát hiện và gửi yêu cầu hoàn tiền cho khách hàng
          thì VNO sau khi đối soát và xác nhận chấp nhận yêu cầu hoàn tiền, VNO sẽ đề nghị ngân hàng thực hiện trích
          tiền từ tài khoản quản lí dòng tiền thu hộ cho Trường học để hoàn tiền cho khách hàng qua kênh mà khách hàng
          đã thực hiện thanh toán học phí theo đúng số tiền đã thanh toán nhầm, thanh toán sai hoặc thanh toán thừa
          trước đó.
          <br />
          <br />• VNO sẽ khôi phục dữ liệu như thông tin học phí, tình trạng thanh toán đã bị sai lệch trên ứng dụng
          YSCHOOL trong trường hợp cần phải khôi phục lại dữ liệu.
        </p>
        <p className={classes.content}>2.2. Phương thức hoàn tiền</p>
        <p className={classes.content}>Phương thức hoàn tiền là hoàn tiền theo kênh mà người dùng đã thanh toán.</p>

        <p className={classes.content}>3. Thời gian xử lý</p>
        <p className={classes.content}>
          • VNO sẽ gửi kết quả phản hồi yêu cầu hoàn trả/bồi thường tới khách hàng thông qua Dịch vụ Chăm sóc khách hàng
          của YSCHOOL trong vòng tối đa 02 ngày làm việc kể từ khi VNO xử lý xong yêu cầu hoàn trả của người dùng hoặc
          của bên thứ ba.
          <br />
          <br />• Nếu yêu cầu hoàn trả/bồi thường của khách hàng được VNO xác định đáp ứng các điều kiện quy định tại
          khoản 1, mục II của chính sách này, VNO sẽ thực hiện thủ tục liên hệ với bên thứ ba có liên kết theo quy định
          tại khoản 2 và khoản 3, mục II của chính sách này.
          <br />
          <br />• Đối với phương thức hoàn tiền thông qua chuyển khoản ngân hàng, thời gian khách hàng nhận được tiền
          tùy theo quy định của ngân hàng.
        </p>
      </div>
    );
  };

  const inspectionsComplaintsRefund = () => {
    return (
      <div>
        <p className={classes.txtTitSubMenu}>I. QUY ĐỊNH CHUNG</p>
        <p className={classes.content}>1. Mục đích</p>
        <p className={classes.content}>
          Quy định thống nhất trình tự thủ tục tiếp nhận và giải quyết tra soát, khiếu nại, tranh chấp dành cho khách
          hàng (hoặc người dùng) sử dụng ứng dụng YSCHOOL thông qua bộ phận chăm sóc khách hàng của VNO (viết tắt:
          BP.CSKH).
        </p>

        <p className={classes.content}>2. Phạm vi, đối tượng áp dụng</p>
        <p className={classes.content}>
          • Cá nhân, tổ chức sử dụng ứng dụng YSCHOOL (sau đây gọi là "người dùng" hoặc "khách hàng")
          <br />• Các đối tác cung cấp sản phẩm/dịch vụ hoặc liên kết để cung ứng dịch vụ liên quan đến ứng dụng YSCHOOL
        </p>
        <p className={classes.content}>3. Giải thích từ ngữ, từ viết tắt</p>
        <p className={classes.content}>
          • VNO: Công ty Cổ phần Dịch vụ Thương mại Việt Nam Trực Tuyến
          <br />• Khiếu nại: các yêu cầu, khiếu nại, tranh chấp, kiện tụng của khách hàng khi sử dụng ứng dụng YSCHOOL
          <br />• Ngày làm việc: là các ngày làm việc từ thứ hai đến thứ sáu hàng tuần của VNO, và không rơi vào các
          ngày nghỉ lễ, Tết theo quy định của nước Cộng hòa xã hội chủ nghĩa Việt Nam.
        </p>
        <p className={classes.content}>4. Phương thức tiếp nhận khiếu nại</p>
        <p className={classes.content}>
          • Thư điện tử-Email: support@yopayment.vn
          <br />• Tổng đài Chăm sóc khách hàng: 19006474
          <br />• Văn phòng giao dịch của Công ty Cổ phần Dịch vụ Thương mại Việt Nam Trực Tuyến tại Tầng 08, Toà nhà
          Galleria, 258 Nam Kỳ Khởi Nghĩa, Phường Võ Thị Sáu, Quận 3, Thành phố Hồ Chí Minh.
          <br />• Các phương thức tiếp nhận thông tin khác được giới thiệu tại Mục hỗ trợ của ứng dụng YSCHOOL và
          website https://yschool.vn/
          <br />• Người dùng là chủ tài khoản trực tiếp gửi yêu cầu tra soát qua email, tin nhắn trên website…, sau đó
          bổ sung mẫu đề nghị khiếu nại theo mẫu PL02 hoặc trực tiếp điền mẫu PL02 tại văn phòng giao dịch của VNO.
          Trong trường hợp chủ tài khoản khiếu nại thông qua các kênh tiếp nhận trực tuyến, BP.CSKH yêu cầu chủ tài
          khoản bổ sung giấy đề nghị tra soát, khiếu nại theo mẫu tại PL 02 trong thời gian không quá 02 ngày làm việc
          kể từ ngày khách hàng yêu cầu tra soát, khiếu nại trực tuyến.
          <br />• Trường hợp khách hàng uỷ quyền cho người khác thực hiện đề nghị tra soát, khiếu nại, chủ tài khoản
          phải thực hiện theo đúng quy định của pháp luật về thủ tục uỷ quyền.
        </p>
        <p className={classes.content}>5. Thời hạn xử lý đề nghị tra soát khiếu nại, tranh chấp</p>
        <p className={classes.content}>
          • Khách hàng được quyền đề nghị tra soát, khiếu nại trong vòng 60 ngày kể từ ngày phát sinh giao dịch đề nghị
          tra soát, khiếu nại.
          <br />• VNO thực hiện ngay các biện pháp để tạm dừng cung ứng dịch vụ ngay khi nhận được đề nghị từ khách hàng
          do nghi ngờ có gian lận hoặc tổn thất, và chịu trách nhiệm với toàn bộ tổn thất tài chính phát sinh đối với
          khách hàng do việc sử dụng dịch vụ sau thời điểm tạm dừng cung ứng dịch vụ.
          <br />• Thời hạn xử lý đề nghị tra soát, khiếu nại không quá 45 ngày làm việc kể từ ngày BP.CSKH tiếp nhận đề
          nghị tra soát, khiếu nại lần đầu của chủ tài khoản theo một trong các hình thức tiếp nhận quy định tại Mục 3.
        </p>

        <p className={classes.content}>7. Kết quả và xử lý kết quả tra soát, khiếu nại, tranh chấp</p>
        <p className={classes.content}>
          • Trong vòng 03 (ba) ngày làm việc kể từ ngày thông báo kết quả tra soát, khiếu nại cho chủ tài khoản, VNO
          thực hiện bồi hoàn cho chủ tài khoản theo thoả thuận và quy định của pháp luật hiện hành đối với tổn thất phát
          sinh không do lỗi của chủ tài khoản và/hoặc không thuộc các trường hợp bất khả kháng, theo thoả thuận tại
          “Thoả thuận người dùng”.
          <br />• Trong trường hợp hết thời hạn xử lý đề nghị tra soát, khiếu nại, tranh chấp mà vẫn chưa xác định được
          nguyên nhân hay lỗi thuộc bên nào thì trong vòng 15 ngày làm việc tiếp theo, VNO thoả thuận với chủ tài khoản
          về phương án xử lý hoặc tạm thời bồi hoàn tổn thất cho chủ tài khoản cho đến khi có kết luận cuối cùng của cơ
          quan có thẩm quyền phân định rõ lỗi và trách nhiệm của các bên.
          <br />• Trong trường hợp vụ việc có dấu hiệu phạm tội, VNO thực hiện thông báo cho cơ quan nhà nước có thẩm
          quyền theo quy định của pháp luật về tố tụng hình sự và báo cáo Ngân hàng Nhà nước (Vụ Thanh Toán, Ngân hàng
          Nhà nước chi nhánh tỉnh, thành phố trên địa bàn). Đồng thời, VNO thông báo bằng văn bản cho chủ tài khoản về
          tình trạng xử lý đề nghị tra soát, khiếu nại, việc giải quyết tra soát, khiếu nại thuộc trách nhiệm giải quyết
          của cơ quan nhà nước có thẩm quyền. Trong trường hợp cơ quan nhà nước có thẩm quyền thông báo kết quả giải
          quyết không có yếu tố tội phạm, trong vòng 15 ngày làm việc kể từ ngày có kết luận của cơ quan nhà nước có
          thẩm quyền, VNO thoả thuận với khách hàng về phương án xử lý tra soát, khiếu nại.
          <br />• Trường hợp VNO, chủ tài khoản và các bên liên quan không thoả thuận được và/hoặc không đồng ý với quá
          trình xử lý đề nghị tra soát, khiếu nại thì việc giải quyết tranh chấp được thực hiện theo quy định của pháp
          luật.
        </p>
      </div>
    );
  };

  const processingOfPersonalData = () => {
    return (
      <div>
        <p className={classes.content}>
          Điều khoản và điều kiện chung về xử lý dữ liệu cá nhân này (“Điều Khoản Và Điều Kiện”) giải thích cách thức
          Công ty Cổ Phần Dịch vụ Thương Mại Viêt Nam Trực Tuyến- VNO (“Chúng tôi”) thu thập, sử dụng và xử lý dữ liệu
          Cá nhân của bạn theo quy định pháp luật.
          <br />
          <br />
          Trong quá trình thiết lập quan hệ với bạn, Chúng tôi cần thu thập và xử lý dữ liệu cá nhân của bạn để có thể
          cung cấp dịch vụ cho bạn. Chúng tôi cam kết luôn tôn trọng quyền riêng tư và nỗ lực bảo vệ dữ liệu cá nhân của
          bạn theo quy định của pháp luật Việt Nam.
          <br />
          <br />
          Điều Khoản Và Điều Kiện này áp dụng cho hoạt động của bạn trên trang web, ứng dụng do Chúng tôi quản lý, việc
          bạn sử dụng (hoặc có khả năng sử dụng) bất kỳ dịch vụ nào do Chúng tôi cung cấp, hoặc bất kỳ mối quan hệ (hoặc
          quan hệ tiềm năng) nào được thiết lập giữa bạn và Chúng tôi.
        </p>
        <p className={classes.txtTitSubMenu}>1. Định nghĩa</p>
        <p className={classes.content}>
          • “Dữ liệu cá nhân” là thông tin cá nhân của bạn dưới dạng ký hiệu, chữ viết, chữ số, hình ảnh, âm thanh hoặc
          dạng tương tự trên môi trường điện tử gắn liền với hoặc giúp xác định danh tính của một con người cụ thể. Dữ
          liệu cá nhân bao gồm Dữ liệu cá nhân cơ bản và Dữ liệu cá nhân nhạy cảm.
          <br />• “Xử lý dữ liệu cá nhân” là một hoặc nhiều hoạt động tác động tới dữ liệu cá nhân, như: thu thập, ghi,
          phân tích, xác nhận, lưu trữ, chỉnh sửa, công khai, kết hợp, truy cập, truy xuất, thu hồi, mã hóa, giải mã,
          sao chép, chia sẻ, truyền đưa, cung cấp, chuyển giao, xóa, hủy dữ liệu cá nhân hoặc các hành động khác có liên
          quan.
          <br />• “Chủ thể dữ liệu” là cá nhân được dữ liệu cá nhân phản ánh.
          <br />• “Bên Kiểm soát dữ liệu cá nhân” là tổ chức, cá nhân quyết định mục đích và phương tiện xử lý dữ liệu
          cá nhân.
          <br />• “Bên Xử lý dữ liệu cá nhân” là tổ chức, cá nhân thực hiện việc xử lý dữ liệu thay mặt cho Bên Kiểm
          soát dữ liệu, thông qua một hợp đồng hoặc thỏa thuận với Bên Kiểm soát dữ liệu.
          <br />• “Bên Kiểm soát và xử lý dữ liệu cá nhân” là tổ chức, cá nhân đồng thời quyết định mục đích, phương
          tiện và trực tiếp xử lý dữ liệu cá nhân
          <br />• “Bên thứ ba” là tổ chức, cá nhân ngoài Chủ thể dữ liệu, Bên Kiểm soát dữ liệu cá nhân, Bên Xử lý dữ
          liệu cá nhân, Bên Kiểm soát và xử lý dữ liệu cá nhân được phép xử lý dữ liệu cá nhân.
          <br />• Trong phạm vi xử lý dữ liệu cá nhân theo Điều Khoản Và Điều Kiện này, bạn là Chủ thể dữ liệu và Chúng
          tôi là Bên kiểm soát và/hoặc là Bên kiểm soát và Bên xử lý dữ liệu cá nhân.
        </p>

        <p className={classes.txtTitSubMenu}>2. Mục đích thu thập dữ liệu cá nhân</p>
        <p className={classes.content}>
          Chúng tôi có thể thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn cho một hoặc những mục đích
          sau đây:
          <br />• Xác minh danh tính, lý lịch, thông tin cá nhân của người sử dụng dịch vụ.
          <br />• Cung cấp các sản phẩm, dịch vụ cho bạn.
          <br />• Giám sát, xử lý, theo dõi việc sử dụng trang web, ứng dụng của bạn để cung cấp cho bạn một trải nghiệm
          hài lòng, tạo điều kiện hoặc quản lý việc sử dụng trang web, ứng dụng của bạn, và hỗ trợ Chúng tôi nâng cao
          trải nghiệm của bạn trong việc sử dụng trang web, ứng dụng.
          <br />• Đăng ký trở thành khách hàng/thành viên của Chúng tôi và giải quyết, xử lý, quản lý tài khoản mà bạn
          đã đăng ký, tạo điều kiện cho các giao dịch hoặc hoạt động của bạn trên trang web, ứng dụng với Chúng tôi.
          <br />• Thực hiện phản hồi các khiếu nại, các yêu cầu dịch vụ; hoặc phản hồi cho hoặc giải quyết liên quan đến
          việc tương tác của bạn với Chúng tôi.
          <br />• Liên lạc hoặc trao đổi với bạn thông qua điện thoại/cuộc gọi thoại, tin nhắn văn bản và/hoặc tin nhắn
          fax, email và/hoặc thư bưu chính nhằm mục đích quản lý, điều hành việc sử dụng trang web, ứng dụng của bạn, tư
          cách thành viên và/hoặc tài khoản của bạn, mối quan hệ của bạn với Chúng tôi hoặc bất kỳ giao dịch nào được
          bạn thực hiện với Chúng tôi.
          <br />• Cung cấp các thông tin tiếp thị, quảng cáo và khuyến mãi, tài liệu, hồ sơ liên quan đến các sản phẩm,
          cuộc thi, dịch vụ, sự kiện của Chúng tôi và/hoặc các tổ chức thứ ba khác mà Chúng tôi hợp tác.
          <br />• Ngăn chặn hoặc điều tra bất kỳ hành vi gian lận, vi phạm pháp luật, hoạt động bất hợp pháp hoặc xử lý,
          điều tra các khiếu nại.
          <br />• Tuân thủ các thủ tục, quy tắc, quy định, hướng dẫn, chỉ thị hoặc yêu cầu được ban hành bởi bất kỳ
          luật, tòa án, cơ quan có thẩm quyền (trong nước hoặc quốc tế) (bao gồm nhưng không giới hạn việc tiết lộ thông
          tin cho cơ quan có thẩm quyền).
          <br />• Thực hiện nghiên cứu, khảo sát, phân tích dữ liệu và khách hàng để nâng cao chất lượng sản phẩm, dịch
          vụ và tiện ích của Chúng tôi, hoặc để cải thiện sự hiểu biết của Chúng tôi đối với sở thích, mối quan tâm và
          sự ưu tiên của bạn, và tăng cường sự tương tác liên tục giữa bạn và Chúng tôi trên trang web, ứng dụng.
        </p>

        <p className={classes.txtTitSubMenu}>3. Các loại dữ liệu cá nhân thu thập</p>
        <p className={classes.content}>
          Chúng tôi có thể thu thập nhiều loại dữ liệu cá nhân khác nhau dưới đây, bao gồm:
          <br />
          <br />
          Dữ liệu cá nhân cơ bản:
          <br />
          <br />• Họ, chữ đệm và tên khai sinh, tên gọi khác (nếu có); Ngày, tháng, năm sinh; ngày, tháng, năm chết hoặc
          mất tích; Giới tính; Nơi sinh, nơi đăng ký khai sinh, nơi thường trú, nơi tạm trú, nơi ở hiện tại, quê quán,
          địa chỉ liên hệ; Quốc tịch; Hình ảnh của cá nhân; Tình trạng hôn nhân; Thông tin về mối quan hệ gia đình (cha
          mẹ, con cái);
          <br />
          <br />• Số điện thoại, số chứng minh nhân dân, số định danh cá nhân, số hộ chiếu, số giấy phép lái xe, số biển
          số xe, số mã số thuế cá nhân, số bảo hiểm xã hội, số thẻ bảo hiểm y tế;
          <br />
          <br />• Thông tin về tài khoản số của cá nhân; Dữ Liệu cá nhân phản ánh hoạt động, lịch sử hoạt động trên
          không gian mạng;
          <br />
          <br />• Các thông tin khác gắn liền với hoặc giúp xác định danh tính của bạn mà không phải là dữ liệu cá nhân
          nhảy cảm do bạn cung cấp hoặc được Chúng tôi thu thập, xác minh được trong từng thời kỳ trên cơ sở không trái
          với quy định của pháp luật.
          <br />
          <br />
          Trong một số trường hợp, Chúng tôi có thể thu thập các dữ liệu cá nhân nhạy cảm của bạn. Dữ liệu cá nhân nhạy
          cảm là những dữ liệu cá nhân gắn liền với sự riêng tư của cá nhân mà khi bị vi phạm sẽ ảnh hưởng trực tiếp với
          các quyền lợi pháp lý của cá nhân đó, có thể bao gồm các loại sau:
          <br />
          <br />• Quan điểm chính trị, quan điểm tôn giáo;
          <br />
          <br />• Tình trạng sức khỏe và đời tư được ghi trong hồ sơ bệnh án, không bao gồm thông tin về nhóm máu;{' '}
          <br />
          <br />• Thông tin liên quan đến nguồn gốc chủng tộc, nguồn gốc dân tộc; Thông tin về đặc điểm di truyền được
          thừa hưởng hoặc có được của cá nhân; Thông tin về thuộc tính vật lý, đặc điểm sinh học riêng của cá nhân;
          <br />
          <br />• Thông tin về đời sống tình dục, xu hướng tình dục của cá nhân;
          <br />
          <br />• Dữ liệu về tội phạm, hành vi phạm tội được thu thập, lưu trữ bởi các cơ quan thực thi pháp luật;
          <br />
          <br />• Thông tin khách hàng của tổ chức tín dụng, chi nhánh ngân hàng nước ngoài, tổ chức cung ứng dịch vụ
          trung gian thanh toán, các tổ chức được phép khác, gồm: thông tin định danh khách hàng theo quy định của pháp
          luật, thông tin về tài khoản, thông tin về tiền gửi, thông tin về tài sản gửi, thông tin về giao dịch, thông
          tin về tổ chức, cá nhân là bên bảo đảm tại tổ chức tín dụng, chi nhánh ngân hàng, tổ chức cung ứng dịch vụ
          trung gian thanh toán;
          <br />
          <br />• Dữ liệu về vị trí của cá nhân được xác định qua dịch vụ định vị;
          <br />
          <br />• Dữ liệu cá nhân khác được pháp luật quy định là đặc thù và cần có biện pháp bảo mật cần thiết.
        </p>

        <p className={classes.txtTitSubMenu}>4. Tiết lộ dữ liệu cá nhân</p>
        <p className={classes.content}>
          Bạn công nhận, đồng ý và chấp thuận rằng Chúng tôi được phép tiết lộ dữ liệu cá nhân của bạn cho các bên thứ
          ba (trong nước hoặc quốc tế) dưới đây cho một hoặc nhiều mục đích nêu tại Mục 2 và các bên thứ ba này được
          phép thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn cho một hoặc nhiều mục đích trên, bao
          gồm:
          <br />
          <br />• Cán bộ nhân viên, công ty mẹ, công ty con, công ty liên kết, chi nhánh của VNO.
          <br />
          <br />• Các tổ chức/cá nhân mà Chúng tôi có quan hệ kinh doanh thương mại bao gồm nhưng không giới hạn các
          công ty cung cấp dịch vụ hành chính, dịch vụ thư tín, công ty viễn thông, ngân hàng, công ty vận chuyển, công
          ty công nghệ thông tin, xử lý dữ liệu hoặc công ty phần mềm và trung tâm dữ liệu, dịch vụ nghiên cứu thị
          trường, dịch vụ tiếp thị, chăm sóc khách hàng và/hoặc các dịch vụ khác có liên quan đến, hoặc để hỗ trợ, cho
          việc vận hành hoạt động kinh doanh của Chúng tôi.
          <br />
          <br />• Các tổ chức/cá nhân tư vấn chuyên môn gồm công ty kiểm toán, tổ chức hành nghề luật sư, tổ chức thẩm
          định giá, kiểm toán viên, cố vấn pháp lý, luật sư, chuyên gia.
          <br />
          <br />• Bất kỳ cá nhân, cơ quan có thẩm quyền hoặc cơ quan quản lý hoặc bên thứ ba mà Chúng tôi được phép hoặc
          bắt buộc phải tiết lộ theo quy định pháp luật của bất kỳ quốc gia, hoặc theo bất kỳ hợp đồng hoặc cam kết nào
          khác giữa bên thứ ba và Chúng tôi.
          <br />
          <br />• Pháp nhân kế thừa trong trường hợp chia tách, hợp nhất, sáp nhập hoặc bán tài sản của công ty và/hoặc
          đơn vị thành viên.
          <br />
          <br />• Bất kỳ bên nào mà Chúng tôi xét thấy là cần thiết vì mục đích xử lý dữ liệu cá nhân nêu tại Mục 2.{' '}
          <br />
          <br />• Tổ chức, cá nhân khác theo quy định của pháp luật.
        </p>

        <p className={classes.txtTitSubMenu}>5. Quyền và nghĩa vụ của chủ thể dữ liệu:</p>
        <p className={classes.content}>
          Quyền của chủ thể dữ liệu:
          <br />
          <br />• Quyền được biết: được biết về hoạt động xử lý dữ liệu cá nhân của mình, trừ trường hợp luật có quy
          định khác.
          <br />
          <br />• Quyền đồng ý: được đồng ý hoặc không đồng ý cho phép xử lý dữ liệu cá nhân của mình, trừ trường hợp
          luật có quy định khác.
          <br />
          <br />• Quyền truy cập: được truy cập để xem, chỉnh sửa hoặc yêu cầu chỉnh sửa dữ liệu cá nhân của mình, trừ
          trường hợp luật có quy định khác.
          <br />
          <br />• Quyền rút lại sự đồng ý: được quyền rút lại sự đồng ý của mình, trừ trường hợp luật có quy định khác.
          Bất kỳ sự rút lại sự đồng ý nào của bạn đối với việc xử lý dữ liệu theo Điều Khoản Và Điều Kiện này sẽ không
          ảnh hưởng đến việc xử lý dữ liệu cá nhân của bạn trước đây theo sự đồng ý mà bạn đã cung cấp cho Chúng tôi.
          <br />
          <br />• Quyền xóa dữ liệu: được xóa hoặc yêu cầu xóa dữ liệu cá nhân của mình, trừ trường hợp luật có quy định
          khác.
          <br />
          <br />• Quyền hạn chế xử lý dữ liệu: được yêu cầu hạn chế xử lý dữ liệu cá nhân của mình, trừ trường hợp luật
          có quy định khác. Việc hạn chế xử lý dữ liệu được thực hiện trong 72 giờ sau khi có yêu cầu của bạn đối với
          toàn bộ dữ liệu cá nhân mà bạn yêu cầu hạn chế, trừ trường hợp luật có quy định khác.
          <br />
          <br />• Quyền cung cấp dữ liệu: được yêu cầu Chúng tôi cung cấp cho bản thân dữ liệu cá nhân của mình, trừ
          trường hợp luật có quy định khác.
          <br />
          <br />• Quyền phản đối xử lý dữ liệu: được phản đối Chúng tôi xử lý dữ liệu cá nhân của mình nhằm ngăn chặn
          hoặc hạn chế tiết lộ dữ liệu cá nhân hoặc sử dụng cho mục đích quảng cáo, tiếp thị, trừ trường hợp luật có quy
          định khác. Chúng tôi sẽ thực hiện yêu cầu của bạn trong 72 giờ sau khi nhận được yêu cầu, trừ trường hợp luật
          có quy định khác.
          <br />
          <br />• Quyền khiếu nại, tố cáo, khởi kiện: có quyền khiếu nại, tố cáo hoặc khởi kiện theo quy định của pháp
          luật.
          <br />
          <br />• Quyền yêu cầu bồi thường thiệt hại: có quyền yêu cầu bồi thường thiệt hại theo quy định của pháp luật
          khi xảy ra vi phạm quy định về bảo vệ dữ liệu cá nhân của mình, trừ trường hợp các bên có thỏa thuận khác hoặc
          luật có quy định khác.
          <br />
          <br />• Quyền tự bảo vệ: có quyền tự bảo vệ theo quy định của Bộ luật Dân sự, luật khác có liên quan và Nghị
          định 13/2023/NĐ-CP, hoặc yêu cầu cơ quan, tổ chức có thẩm quyền thực hiện các phương thức bảo vệ quyền dân sự
          theo quy định tại Điều 11 Bộ luật Dân sự.
          <br />
          <br />
          Nghĩa vụ của chủ thể dữ liệu:
          <br />
          <br />• Tự bảo vệ dữ liệu cá nhân của mình; yêu cầu các tổ chức, cá nhân khác có liên quan bảo vệ dữ liệu cá
          nhân của mình.
          <br />
          <br />• Tôn trọng, bảo vệ dữ liệu cá nhân của người khác.
          <br />
          <br />• Cung cấp đầy đủ, chính xác dữ liệu cá nhân khi đồng ý cho phép xử lý dữ liệu cá nhân; kịp thời thông
          báo cho Chúng tôi về bất kỳ thay đổi nào về dữ liệu cá nhân đã cung cấp.
          <br />
          <br />• Tham gia tuyên truyền, phổ biến kỹ năng bảo vệ dữ liệu cá nhân.
          <br />
          <br />• Thực hiện quy định của pháp luật về bảo vệ dữ liệu cá nhân và tham gia phòng, chống các hành vi vi
          phạm quy định về bảo vệ dữ liệu cá nhân.
        </p>

        <p className={classes.txtTitSubMenu}>6. Cách thức thu thập và xử lý dữ liệu cá nhân</p>
        <p className={classes.content}>
          Dữ liệu cá nhân có thể được Chúng tôi thu thập theo các cách thức sau: <br />
          <br />
          (i) Từ các trao đổi, liên lạc giữa bạn với Chúng tôi: Chúng tôi có thể thu thập dữ liệu cá nhân trong quá
          trình trao đổi, liên lạc giữa Chúng tôi và bạn (gặp trực tiếp, qua thư, điện thoại, trực tuyến, liên lạc điện
          tử hoặc bất kỳ phương tiện nào khác).
          <br />
          <br />
          (ii) Thông qua quan hệ được thiết lập giữa Chúng tôi và bạn khi bạn đăng ký, sử dụng bất kỳ sản phẩm, dịch vụ
          nào của Chúng tôi.
          <br />
          <br />
          (iii) Từ việc bạn truy cập vào trang web, ứng dụng của Chúng tôi. <br />
          <br />
          (iv) Từ các tương tác hoặc các công nghệ thu thập dữ liệu tự động: Chúng tôi có thể thu thập thông tin bao gồm
          địa chỉ IP, URL giới thiệu, hệ điều hành, trình duyệt tin điện tử và bất kỳ thông tin nào khác được ghi tự
          động từ kết nối:
          <br />
          <br />
          a. Cookie, plug-in hoặc trình kết nối mạng xã hội của bên thứ ba.
          <br />
          <br />
          b. Bất kỳ công nghệ nào có khả năng theo dõi hoạt động cá nhân trên các thiết bị hoặc trang web, ứng dụng.
          <br />
          <br />
          c. Thông tin vị trí hoặc siêu dữ liệu khác được cung cấp bởi một thiết bị.
          <br />
          <br />
          d. Các phương tiện khác: Chúng tôi có thể thu thập dữ liệu cá nhân khi bạn tương tác với Chúng tôi thông qua
          bất kỳ phương tiện nào khác.
          <br />
          <br />
          (v) Từ dữ liệu ghi âm, ghi hình (có hoặc không có âm thanh), ảnh và/hoặc hình ảnh cụ thể của bạn có thể được
          ghi lại từ camera an ninh được lắp đặt tại trụ sở, văn phòng, địa điểm kinh doanh, thông qua các thiết bị đầu
          cuối tự phục vụ của Chúng tôi, thiết bị điện thoại hoặc tại các sự kiện của Chúng tôi. <br />
          <br />
          Dữ liệu cá nhân có thể được Chúng tôi xử lý theo các cách thức sau: <br />
          <br />
          (i) Việc xử lý dữ liệu cá nhân của bạn có thể được thực hiện dưới bất kỳ hình thức nào, bao gồm nhưng không
          giới hạn việc xử lý thông qua bản giấy, bản ghi âm, ghi hình hoặc dưới hình thức thông điệp dữ liệu điện tử.{' '}
          <br />
          <br />
          (ii) Chúng tôi tự xử lý hoặc xử lý thông qua bên thứ ba theo ủy quyền hoặc có xác lập quan hệ hợp đồng với
          Chúng tôi, bao gồm cả trường hợp Chúng tôi chủ động xác minh, xử lý dữ liệu cá nhân thông qua các cơ quan, tổ
          chức, cá nhân, bên thứ ba có liên quan trong quá trình thiết lập mối quan hệ với bạn. <br />
          <br />
          (iii) Việc chuyển dữ liệu cá nhân của bạn cho các cơ quan, tổ chức, cá nhân trong nước hoặc ra nước ngoài sẽ
          được thực hiện cho các mục đích phù hợp với quy định tại Điều Khoản Và Điều Kiện này hoặc văn bản khác có liên
          quan đến trong từng thời kỳ. <br />
          <br />
          (iv) Bạn xác nhận và đồng ý rằng trong quá trình xử lý dữ liệu cá nhân của bạn, Chúng tôi có thể sẽ chuyển dữ
          liệu cá nhân của bạn ra bên ngoài lãnh thổ Việt Nam nhằm mục đích phục vụ cho việc cung cấp sản phẩm, dịch vụ
          cho bạn. Chúng tôi sẽ thực hiện tất cả các biện pháp bảo vệ dữ liệu cần thiết để đảm bảo an toàn đối với dữ
          liệu cá nhân của bạn. <br />
          <br />
          (v) Trong trường hợp cần chuyển dữ liệu cá nhân nhạy cảm của bạn cho Bên Xử Lý Dữ liệu cá nhân, bên thứ ba
          khác (nếu có), Chúng tôi sẽ nỗ lực áp dụng các biện pháp bảo vệ dữ liệu cao nhất trong quá trình chuyển dữ
          liệu cá nhân nhạy cảm. <br />
          <br />
          (vi) Áp dụng các công nghệ, ứng dụng, phần mềm do Chúng tôi tự phát triển hoặc thuê bên thứ ba (từ đối tác
          cung cấp trong nước hoặc nước ngoài) phát triển trong quá trình xử lý dữ liệu cá nhân. <br />
          <br />
          (vii) Các cách thức xử lý khác tùy theo quyết định của Chúng tôi mà không trái với quy định của pháp luật.{' '}
        </p>

        <p className={classes.txtTitSubMenu}>7. Hậu quả, thiệt hại không mong muốn có thể xảy ra </p>
        <p className={classes.content}>
          Bảo vệ dữ liệu cá nhân trong quá trình xử lý dữ liệu cá nhân của bạn luôn được Chúng tôi ưu tiên hàng đầu. Tuy
          nhiên, vì lý do kỹ thuật hoặc các lý do khác nằm ngoài sự kiểm soát của Chúng tôi, việc thất thoát dữ liệu, rò
          rỉ thông tin vẫn có thể xảy ra và gây nên các hậu quả, thiệt hại không muốn đối với bạn và/hoặc Chúng tôi.
          Trong trường hợp này, Chúng tôi sẽ nỗ lực tối đa để sửa chữa và củng cố hàng rào bảo mật, giảm thiểu thiệt hại
          phát sinh cho bạn.
        </p>

        <p className={classes.txtTitSubMenu}>8. Thời gian bắt đầu và thời gian kết thúc xử lý dữ liệu </p>
        <p className={classes.content}>
          Thời gian xử lý dữ liệu bắt đầu (i) kể từ ngày bạn thực hiện cung cấp/cho phép Chúng tôi xử lý dữ liệu cá nhân
          hoặc (ii) ngày bạn xác lập quan hệ nhằm sử dụng các sản phẩm, dịch vụ của Chúng tôi, tùy thuộc ngày nào đến
          sớm hơn, và kết thúc khi dữ liệu cá nhân được tiêu hủy/chấm dứt xử lý theo thỏa thuận và/hoặc theo quy định
          của pháp luật.{' '}
        </p>

        <p className={classes.txtTitSubMenu}>9. Sửa đổi Điều Khoản Và Điều Kiện</p>
        <p className={classes.content}>
          Điều Khoản Và Điều Kiện này có thể được Chúng tôi xem xét và sửa đổi theo từng thời điểm và sẽ được cập nhật
          theo từng thời kỳ trên trang web, ứng dụng của Chúng tôi và/hoặc các phương tiện khác thích hợp do Chúng tôi
          quy định vào từng thời kỳ.{' '}
        </p>

        <p className={classes.txtTitSubMenu}>10. Liên hệ</p>
        <p className={classes.content}>
          Nếu bạn có bất kỳ câu hỏi nào liên quan đến Điều Khoản Và Điều Kiện của Chúng tôi, hoặc nếu bạn muốn yêu cầu
          truy cập, hoặc chỉnh sửa hoặc xóa bỏ dữ liệu cá nhân của bạn hoặc rút lại sự đồng ý, vui lòng liên hệ hoặc gửi
          yêu cầu của bạn cho Chúng tôi đến email: info@yopayment.vn{' '}
        </p>

        <p className={classes.txtTitSubMenu}>11. Quy định chung</p>
        <p className={classes.content}>
          Sự chấp thuận của bạn đối với Điều Khoản Và Điều Kiện này là bổ sung vào và là một phần không thể tách rời với
          các chính sách, điều khoản, điều kiện, hợp đồng được áp dụng khi bạn sử dụng trang web, ứng dụng, dịch vụ của
          Chúng tôi.
          <br />
          <br />
          Để tránh nhầm lẫn, trong trường hợp pháp luật bảo vệ dữ liệu hiện hành cho phép Chúng tôi, thu thập, sử dụng,
          xử lý hoặc tiết lộ dữ liệu cá nhân của bạn mà không cần có sự chấp thuận của bạn, thì sự cho phép theo quy
          định pháp luật này sẽ tiếp tục được áp dụng.
        </p>
      </div>
    );
  };
  return (
    <Box>
      <Container className={classes.container}>
        <div style={{ flexDirection: 'column', width: '100%' }}>
          <div className={classes.coverTitle}>
            <h2 className={classes.title}>
              THỎA THUẬN NGƯỜI DÙNG ỨNG DỤNG THANH TOÁN VÀ TƯƠNG TÁC TRƯỜNG EM - YSCHOOL
            </h2>
          </div>
          <div style={{ padding: 12 }}>{userAgreement()}</div>

          <div className={classes.coverTitle}>
            <h2 className={classes.title}>
              CHÍNH SÁCH BẢO MẬT THÔNG TIN NGƯỜI DÙNG ỨNG DỤNG THANH TOÁN VÀ TƯƠNG TÁC TRƯỜNG EM – YSCHOOL
            </h2>
          </div>
          <div style={{ padding: 12 }}>{privacyPolicy()}</div>

          <div className={classes.coverTitle}>
            <h2 className={classes.title}>
              CHÍNH SÁCH HOÀN TRẢ VÀ BỒI THƯỜNG THIỆT HẠI CHO KHÁCH HÀNG SỬ DỤNG ỨNG DỤNG THANH TOÁN VÀ TƯƠNG TÁC TRƯỜNG
              EM - YSCHOOL
            </h2>
          </div>
          <div style={{ padding: 12 }}>{returnPolicy()}</div>

          <div className={classes.coverTitle}>
            <h2 className={classes.title}>
              QUY TRÌNH GIẢI QUYẾT TRA SOÁT, KHIẾU NẠI, TRANH CHẤP DÀNH CHO NGƯỜI DÙNG ỨNG DỤNG THANH TOÁN VÀ TƯƠNG TÁC
              TRƯỜNG EM - YSCHOOL
            </h2>
          </div>
          <div style={{ padding: 12 }}>{inspectionsComplaintsRefund()}</div>

          <div className={classes.coverTitle}>
            <h2 className={classes.title}>ĐIỀU KHOẢN VÀ ĐIỀU KIỆN CHUNG VỀ XỬ LÝ DỮ LIỆU CÁ NHÂN</h2>
          </div>
          <div style={{ padding: 12 }}>{processingOfPersonalData()}</div>
        </div>
      </Container>
    </Box>
  );
};

export default Policy;
