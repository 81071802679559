import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';

export const AES256Encrypt = (strKey, message) => {
  const key = CryptoJS.enc.Base64.parse(strKey);
  const iv = getIV(strKey);
  const encryptedMessage = CryptoJS.AES.encrypt(message, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encryptedMessage;
};
export const convertStringToUTF8ByteArray = (str) => {
  let binaryArray = new Uint8Array(str.length);
  Array.prototype.forEach.call(binaryArray, function (el, idx, arr) {
    arr[idx] = str.charCodeAt(idx);
  });
  return binaryArray;
};

export const getIV = (strKey) => {
  let iv = new Buffer(strKey, 'base64').slice(0, 16).toString('base64');
  iv = CryptoJS.enc.Base64.parse(iv);
  return iv;
};
export const AESDecrypt = (message) => {
  let strKey = 'pEhhUJqIDY+5p8Ec0eyGec8MEpr5MxxOFAa6Cfvq9Ls=';
  const key = CryptoJS.enc.Base64.parse(strKey);
  const iv = getIV(strKey);
  const decryptedMsg = CryptoJS.AES.decrypt(message, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return decryptedMsg;
};
export const AESEncrypt = (message) => {
  const key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_APP_KEY);
  const iv = getIV(process.env.REACT_APP_APP_KEY);
  const encryptedMessage = CryptoJS.AES.encrypt(message, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encryptedMessage;
};
