import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import StorageKeys from 'constants/storage-key';
import PaymentApi from 'apis/paymentApi';

export const findStudentByCode = createAsyncThunk('/api/student/find-student-by-id', async (data) => {
  const res = await PaymentApi.findStudentById(data);
  if (res.ErrorCode === 0) {
    return res;
  }
  return res;
});

export const findStudentByInfo = createAsyncThunk('/api/student/find-student', async (data) => {
  const res = await PaymentApi.findStudentByInfo(data);
  if (res.ErrorCode === 0) {
    return res;
  }
  return res;
});
//
export const findBillStudent = createAsyncThunk('/api/yopayment/payment/find-bill-ys', async (data) => {
  const res = await PaymentApi.findBillStudent(data);
  if (res.ErrorCode === 0) {
    return res;
  }
  return res;
});

export const getListSchool = createAsyncThunk('/api/user/list-school', async (data) => {
  const res = await PaymentApi.getListSchool(data);
  if (res.ErrorCode === 0) {
    return res;
  }
  return res;
});

export const getListPaymentFee = createAsyncThunk('/api/merchant/ext-check-type-school', async (data) => {
  const res = await PaymentApi.getListPaymentFee(data);
  if (res.ErrorCode === 0) {
    return res;
  }
  return res;
});

export const getAppFee = createAsyncThunk('/api/service/V2/get/application-fee', async (data) => {
  const res = await PaymentApi.getAppFee(data);
  if (res.ErrorCode === 0) {
    return res;
  }
  return res;
});

export const createOrderTuitonPayment = createAsyncThunk('/api/yopayment/payment/create-order-ys-v2', async (data) => {
  const res = await PaymentApi.createOrderTuitonPayment(data);
  if (res.ErrorCode === 0) {
    return res;
  }
  return res;
});

const payment = createSlice({
  name: StorageKeys.STUDENT,
  initialState: {},
  reducers: {},
  extraReducers: {
    [findStudentByCode.fulfilled]: () => {},
    [findBillStudent.fulfilled]: () => {},
    [getListSchool.fulfilled]: () => {},
    [getListPaymentFee.fulfilled]: () => {},
    [getAppFee.fulfilled]: () => {},
    [createOrderTuitonPayment.fulfilled]: () => {},
  },
});
const { actions, reducer } = payment;
export default reducer;
