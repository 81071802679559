import axiosClient from './axiosClient';

const userApi = {
  login(data) {
    const url = '/api/user/login';
    return axiosClient.post(url, data);
  },
  getMenuPermission() {
    const url = '/api/user/memu';
    return axiosClient.post(url, '');
  },
};
export default userApi;
