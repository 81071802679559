import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonCom from 'components/Buttom';
import { useHistory, useParams } from 'react-router-dom';
import Images from 'assets/images';
import Utils from 'constants/utils';

const useStyles = makeStyles((theme) => ({
  main: {
    background: 'white',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    color: '#18b0a3',
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  card: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    background: 'white',
    width: '80%',
    marginTop: 30,
    borderRadius: 20,
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  coverContentTab: {
    flexDirection: 'column',
    display: 'flex',
    minHeight: 280,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  styCoverLineText: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: '1px solid #eee',
  },
  styTxtTitRow: {
    margin: '16px 0',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0',
      fontSize: 14,
    },
  },
  styTxtConRow: {
    color: '#f0727e',
    margin: '16px 0',
    [theme.breakpoints.down('sm')]: {
      margin: 8,
      fontSize: 14,
    },
  },
  styCoverCardLstBill: {
    width: '60%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  styTxtDisBill: {
    textAlign: 'left',
    width: '100%',
    color: '#18b0a3',
  },
  coverBtnResult: {
    marginTop: 30,
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  styCoverImg: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  styImgSad: {
    height: 300,
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
  styBtn: {
    color: 'white',
    minWidth: 200,
    fontSize: 20,
  },
}));

const PaymentResults = (props) => {
  const params = useParams();
  const classes = useStyles();
  const [messageData, setMessageData] = useState('');
  const history = useHistory();
  useEffect(() => {
    let data = decodeURIComponent(escape(atob(params.data)));
    setMessageData(JSON.parse(data));
    return () => {};
  }, [params.data]);

  return (
    <Box className={classes.main}>
      <Container className={classes.container}>
        <div className={classes.card}>
          {messageData !== '' && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              {messageData.ErrorCode == 2 && (
                <h2 style={{ color: '#1d1851' }} className={classes.title}>{`Bạn đã huỷ giao dịch thành công`}</h2>
              )}
              {messageData.ErrorCode == 1 && (
                <h2
                  style={{ color: '#1d1851', maxWidth: '50%' }}
                  className={classes.title}
                >{`Thanh toán không thành công, vui lòng liên hệ với CSKH để biết thêm chi tiết`}</h2>
              )}
              {messageData.ErrorCode == 0 && <h2 className={classes.title}>{`Thanh toán thành công`}</h2>}
            </div>
          )}
          <div className={classes.coverContentTab}>
            <div className={classes.styCoverCardLstBill}>
              {messageData !== '' && (
                <div>
                  {(messageData.ErrorCode == 2 || messageData.ErrorCode == 1) && (
                    <div className={classes.styCoverImg}>
                      <img src={Images.background_sad} alt="img" className={classes.styImgSad} />
                    </div>
                  )}
                  {messageData.ErrorCode == 0 && (
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <div className={classes.styCoverLineText}>
                        <h4 className={classes.styTxtTitRow}>{`Mã giao dịch`}</h4>
                        <h3 className={classes.styTxtConRow}>{`${
                          messageData?.Data?.TransactionId || 'Đang cập nhật'
                        }`}</h3>
                      </div>
                      <div className={classes.styCoverLineText}>
                        <h4 className={classes.styTxtTitRow}>{`Ngày giao dịch`}</h4>
                        <h3 className={classes.styTxtConRow}>{`${
                          messageData?.Data?.TransactionDate || 'Đang cập nhật'
                        }`}</h3>
                      </div>
                      <div className={classes.styCoverLineText}>
                        <h4 className={classes.styTxtTitRow}>{`Tên Trường`}</h4>
                        <h3 className={classes.styTxtConRow}>{`${
                          messageData?.Data?.SchoolName || 'Đang cập nhật'
                        }`}</h3>
                      </div>
                      <div className={classes.styCoverLineText}>
                        <h4 className={classes.styTxtTitRow}>{`Lớp`}</h4>
                        <h3 className={classes.styTxtConRow}>{`${messageData?.Data?.ClassName || 'Đang cập nhật'}`}</h3>
                      </div>
                      <div className={classes.styCoverLineText}>
                        <h4 className={classes.styTxtTitRow}>{`Mã học sinh`}</h4>
                        <h3 className={classes.styTxtConRow}>{`${messageData?.Data?.SSCId || 'Đang cập nhật'}`}</h3>
                      </div>
                      <div className={classes.styCoverLineText}>
                        <h4 className={classes.styTxtTitRow}>{`Tên học sinh`}</h4>
                        <h3 className={classes.styTxtConRow}>{`${
                          messageData?.Data?.StudentName || 'Đang cập nhật'
                        }`}</h3>
                      </div>

                      <div className={classes.styCoverLineText}>
                        <h4 className={classes.styTxtTitRow}>{`Tiền học phí`}</h4>
                        <h3 className={classes.styTxtConRow}>{`${
                          Utils.formatMoney(messageData?.Data?.Amount) || 'Đang cập nhật'
                        } đ`}</h3>
                      </div>
                      <div className={classes.styCoverLineText}>
                        <h4 className={classes.styTxtTitRow}>{`Phí ứng dụng`}</h4>
                        <h3 className={classes.styTxtConRow}>{`${
                          Utils.formatMoney(messageData?.Data?.ApplicationFee) || 'Đang cập nhật'
                        } đ`}</h3>
                      </div>
                      <div className={classes.styCoverLineText}>
                        <h4 className={classes.styTxtTitRow}>{`Tổng tiền`}</h4>
                        <h3 className={classes.styTxtConRow}>{`${
                          Utils.formatMoney(messageData?.Data?.TotalAmount) || 'Đang cập nhật'
                        } đ`}</h3>
                      </div>
                      <h4 className={(classes.styTxtTitRow, classes.styTxtDisBill)}>{`Danh sách khoản phí`}</h4>
                      {messageData?.Data?.Bills.map((bill) => (
                        <div className={classes.styCoverLineText}>
                          <h4 className={classes.styTxtTitRow}>{`Tháng ${bill.Month}`}</h4>
                          <h3 className={classes.styTxtConRow}>{`${
                            Utils.formatMoney(bill.PayAmount) || 'Đang cập nhật'
                          } đ`}</h3>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={classes.coverBtnResult}>
              <ButtonCom
                text="Về trang chủ"
                typeBTN="BRAND"
                callbackClick={() => {
                  history.push('/');
                }}
                style={{ color: 'white', minWidth: 200 }}
              />
              {messageData.ErrorCode !== 0 && (
                <ButtonCom
                  text="Tiếp Tục Thanh Toán"
                  typeBTN="BRAND"
                  callbackClick={() => {
                    history.push('/payment');
                  }}
                  style={{ color: 'white', minWidth: 200 }}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default PaymentResults;
