import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonCom from 'components/Buttom';
import { useSnackbar } from 'notistack';
import { getListPaymentFee, getAppFee, createOrderTuitonPayment } from '../../paymentSlice';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router-dom';
import InputCom from 'components/InputCom';
import { loadding } from 'features/auth/userSlice';
import leftArrow from 'assets/images/left-arrow.svg';
import Images from 'assets/images';
import Utils from 'constants/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  title: {
    textAlign: 'center',
    color: '#18b0a3',
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
  },
  card: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    width: '80%',
    borderRadius: 20,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '96%',
      paddingBottom: 10,
    },
  },
  coverHeaderInfoStudent: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  coverIconHeaderCard: {
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
    },
  },
  cardOptPayMed: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: 12,
    height: 80,
    padding: '0 12px',
    margin: 20,
  },
  imageSelect: {
    width: 20,
    height: 20,
    margin: 10,
    [theme.breakpoints.down('sm')]: {
      width: 16,
      height: 16,
      margin: 4,
    },
  },
  imgTypeOpt: {
    width: 44,
    height: 44,
    margin: 12,
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
      margin: 10,
    },
  },
  styInput: {
    width: '100%',
    borderRadius: 30,
    backgroundColor: '#efefef',
    textAlign: 'center',
    fontSize: 20,
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  coverViewTxtAmount: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },

  styImgIconHeaderCard: {
    width: 28,
    height: 28,
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
  },
  coverViewListBill: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  txtPaymentMethod: {
    fontSize: 20,
    margin: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
      margin: 7,
    },
  },
  txtTitDis: {
    margin: 0,
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  txtTitTotalAmount: {
    margin: 0,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
}));

const PaymentMethod = (props) => {
  const classes = useStyles();
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [paymentMethodSel, setPaymentMethodSel] = useState(-1);
  const [lstTokenAndFee, setLstTokenAndFee] = useState([]);
  const [lstAppFee, setLstAppFee] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(state?.isBMC ? state?.username : '');
  const showLoadding = loadding(true);
  const { enqueueSnackbar } = useSnackbar();

  const handelReloadData = useCallback(async () => {
    try {
      dispatch(showLoadding);
      let infoStudent = state.infoStudent;
      const resultPaymentFee = await dispatch(getListPaymentFee(infoStudent.SchoolCode));
      const resPaymentFee = unwrapResult(resultPaymentFee);
      if (resPaymentFee.ErrorCode === 0) {
        setLstTokenAndFee(resPaymentFee.Data);
      } else {
        enqueueSnackbar(resPaymentFee.ErrorMsg, { variant: 'warning' });
      }
      const resultAppFee = await dispatch(getAppFee(infoStudent.SchoolCode));
      const resAppFee = unwrapResult(resultAppFee);
      dispatch(showLoadding);
      if (resAppFee.ErrorCode === 0) {
        setLstAppFee(resAppFee.Data);
      } else {
        enqueueSnackbar(resAppFee.ErrorMsg, { variant: 'warning' });
      }
    } catch (e) {
      dispatch(showLoadding);
      enqueueSnackbar(JSON.stringify(e), { variant: 'error' });
    }
  }, [state, enqueueSnackbar, dispatch]);

  useEffect(() => {
    handelReloadData();
    return () => {};
  }, []);

  const calculateFeeApp = useCallback(() => {
    switch (paymentMethodSel) {
      case -1:
        return 0;
      case 2:
        if (!lstAppFee.IsPaid) {
          let amount = 0;
          lstAppFee.PaymentTypeApplies.forEach((method) => {
            if (method.PaymentType === 2) {
              amount = method.Amount;
            }
          });
          return amount;
        } else {
          return 0;
        }
      case 3:
        let internationalCard = lstTokenAndFee.InternationalCard;
        return internationalCard.FeeFix + (internationalCard.FeePercent * state?.totalAmountSel) / 100;
      case 15:
        return 0;
      default:
        return 0;
    }
  }, [paymentMethodSel, lstAppFee, lstTokenAndFee, state]);

  const handelPayment = useCallback(async () => {
    if (!state?.isBMC && phoneNumber.length !== 10) {
      enqueueSnackbar('Vui lòng nhập đầy đủ Số Điện Thoại của Phụ Huynh', { variant: 'error' });
      return;
    }
    dispatch(showLoadding);
    try {
      let body = {
        MerchantCode: '',
        CustomerId: `WEB${phoneNumber}`,
        PaymentType: paymentMethodSel,
        Token: '',
        IsSaveToken: false,
        Transaction: {
          StudentId: state.infoStudent.StudentCode,
          Bills: state.lstBill,
          StudentType: '2',
        },
      };
      const result = await dispatch(createOrderTuitonPayment(body));
      const res = unwrapResult(result);

      if (res.ErrorCode === 45) {
        window.location.href = res.Data;
      } else {
        dispatch(showLoadding);
        enqueueSnackbar(res.ErrorMsg, { variant: 'warning' });
      }
    } catch (e) {
      dispatch(showLoadding);
      enqueueSnackbar(JSON.stringify(e), { variant: 'warning' });
    }
  }, [phoneNumber, state, paymentMethodSel, state, enqueueSnackbar, dispatch]);

  const renderViewHeaderCard = () => {
    return (
      <div className={classes.coverHeaderInfoStudent}>
        <div className={classes.coverIconHeaderCard}>
          <img
            onClick={() => {
              history.goBack();
            }}
            src={leftArrow}
            alt="img"
            className={classes.styImgIconHeaderCard}
          />
        </div>
        <h3 className={classes.title}>{`Chọn phương thức thanh toán`}</h3>
        <div className={classes.coverIconHeaderCard} />
      </div>
    );
  };

  return (
    <Box>
      <Container className={classes.container}>
        <div className={classes.card}>
          {renderViewHeaderCard()}
          {lstTokenAndFee && lstTokenAndFee?.PaymentMethods && (
            <div className={classes.coverViewListBill}>
              {lstTokenAndFee?.PaymentMethods.includes('ATM') && (
                <div
                  className={classes.cardOptPayMed}
                  onClick={() => {
                    setPaymentMethodSel(2);
                  }}
                  style={{ border: paymentMethodSel === 2 ? '2px solid #f0727e' : 'none' }}
                >
                  <img
                    src={paymentMethodSel === 2 ? Images.ic_sle : Images.ic_unsle}
                    alt="img"
                    className={classes.imageSelect}
                  />
                  <img src={Images.iconATM} alt="img" className={classes.imgTypeOpt} />
                  <p className={classes.txtPaymentMethod}>{`Thẻ ATM nội địa`}</p>
                </div>
              )}
              {lstTokenAndFee?.PaymentMethods.includes('VISA') && (
                <div
                  style={{ border: paymentMethodSel === 3 ? '2px solid #f0727e' : 'none' }}
                  className={classes.cardOptPayMed}
                  onClick={() => {
                    setPaymentMethodSel(3);
                  }}
                >
                  <img
                    src={paymentMethodSel === 3 ? Images.ic_sle : Images.ic_unsle}
                    alt="img"
                    className={classes.imageSelect}
                  />
                  <img src={Images.iconVisa} alt="img" className={classes.imgTypeOpt} />
                  <p className={classes.txtPaymentMethod}>{`Thẻ Quốc tế (Visa/MasterCard)`}</p>
                </div>
              )}
              {lstTokenAndFee?.PaymentMethods.includes('VIETTEL') && (
                <div
                  style={{ border: paymentMethodSel === 15 ? '2px solid #f0727e' : 'none' }}
                  className={classes.cardOptPayMed}
                  onClick={() => {
                    setPaymentMethodSel(15);
                  }}
                >
                  <img
                    src={paymentMethodSel === 15 ? Images.ic_sle : Images.ic_unsle}
                    alt="img"
                    className={classes.imageSelect}
                  />
                  <img src={Images.logoViettelMoney} alt="img" className={classes.imgTypeOpt} />
                  <p className={classes.txtPaymentMethod}>{`Viettel Money`}</p>
                </div>
              )}

              <div>
                <h3 className={classes.title} style={{ marginBottom: 0, marginTop: 30 }}>{`Thông tin phụ huynh`}</h3>
                <div style={{ margin: 20 }}>
                  <InputCom
                    isShowLable={false}
                    isTextCenter={true}
                    value={phoneNumber}
                    classes={classes.styInput}
                    fullWidth={true}
                    placeholder={'Nhập số điện thoại của Phụ Huynh'}
                    isNumber={true}
                    disabled={state?.isBMC || false}
                    onChangeValue={(value) => {
                      if (value == '') {
                        setPhoneNumber(value);
                      }
                      if (value.length === 1 && value != 0) {
                        return;
                      }
                      if (value.length > 10) return;
                      setPhoneNumber(value);
                    }}
                  />
                </div>
              </div>

              {paymentMethodSel !== -1 && (
                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', margin: 20 }}>
                  <div className={classes.coverViewTxtAmount}>
                    <h4 className={classes.txtTitDis}>{`Tiền học phí`}</h4>
                    <h3 style={{ color: '#F1727E' }} className={classes.txtTitDis}>{`${Utils.formatMoney(
                      state?.totalAmountSel.toString() || ''
                    )} đ`}</h3>
                  </div>
                  <div className={classes.coverViewTxtAmount}>
                    <h4 className={classes.txtTitDis}>{`Phí ứng dụng`}</h4>
                    <h3 style={{ color: '#F1727E' }} className={classes.txtTitDis}>
                      {calculateFeeApp() == 0 ? 'Miễn phí' : `${Utils.formatMoney(calculateFeeApp())} đ`}
                    </h3>
                  </div>
                  <div className={classes.coverViewTxtAmount}>
                    <h4 className={classes.txtTitTotalAmount}>{`Tổng tiền`}</h4>
                    <h3 style={{ color: '#F1727E' }} className={classes.txtTitTotalAmount}>{`${Utils.formatMoney(
                      state?.totalAmountSel + calculateFeeApp()
                    )} đ`}</h3>
                  </div>
                  <div>
                    <ButtonCom
                      disabled={paymentMethodSel === -1}
                      text="Thanh Toán"
                      typeBTN="BRAND"
                      callbackClick={handelPayment}
                      style={{ color: 'white', width: 300, marginTop: 30 }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </Box>
  );
};
export default PaymentMethod;
