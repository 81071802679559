import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  create: {
    backgroundColor: '#2FA84F',
    color: 'white',
    borderRadius: 30,
    minWidth: theme.spacing(17),
    fontWeight: 600,
    margin: theme.spacing(0, 1),
    textTransform: 'capitalize',
    height: 40,
  },
  update: {
    backgroundColor: '#367BF5',
    color: 'white',
    borderRadius: 30,
    minWidth: theme.spacing(17),
    fontWeight: 600,
    margin: theme.spacing(0, 1),
    textTransform: 'capitalize',
    height: 40,
  },
  delete: {
    backgroundColor: '#FA541C',
    color: 'white',
    borderRadius: 30,
    minWidth: theme.spacing(17),
    fontWeight: 600,
    margin: theme.spacing(0, 1),
    textTransform: 'capitalize',
    height: 40,
  },
  default: {
    backgroundColor: '#f3f3f3',
    color: '#1c1c1c',
    borderRadius: 30,
    minWidth: theme.spacing(17),
    fontWeight: 600,
    margin: theme.spacing(0, 1),
    textTransform: 'capitalize',
    height: 40,
  },
  brand: {
    backgroundColor: '#F1727E',
    color: '#1c1c1c',
    borderRadius: 30,
    minWidth: theme.spacing(17),
    fontWeight: 600,
    margin: theme.spacing(0, 1),
    textTransform: 'capitalize',
    height: 40,
  },
}));
const ButtonCom = ({ text, disabled, type, callbackClick = () => {}, typeBTN = 'CREATE', style, fullWidth }) => {
  const classes = useStyles();
  return (
    <Button
      aria-label="upload picture"
      className={[
        typeBTN === 'CREATE'
          ? classes.create
          : typeBTN === 'UPDATE'
          ? classes.update
          : typeBTN === 'DEFAULT'
          ? classes.default
          : typeBTN === 'BRAND'
          ? classes.brand
          : classes.delete,
      ]}
      variant="contained"
      disabled={disabled}
      onClick={callbackClick}
      type={type}
      style={{ marginTop: 10, ...style }}
      fullWidth={fullWidth}
    >
      {text}
    </Button>
  );
};
ButtonCom.propTypes = {
  text: PropTypes.string,
  callback: PropTypes.func,
};
export default ButtonCom;
