import { Container, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { lstPartner } from 'constants/dataCommon';
import Slider from 'react-slick';

const useStyles = makeStyles((theme) => ({
  styTxtTitle: {
    color: '#F1727E',
    textAlign: 'center',
    marginTop: 100,
    [theme.breakpoints.down('xl')]: {
      marginTop: 100,
      fontSize: 30,
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 50,
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
      fontSize: 16,
    },
  },
  coverContent: {
    marginTop: 50,
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  imgLogo: {
    height: 80,
    maxWidth: 180,
    width: 'auto',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: 50,
      maxWidth: 100,
    },
  },
  coverCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    width: 220,
    height: 220,
    background: 'white',
    borderRadius: 30,
    margin: 30,
    padding: 20,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: 150,
      borderRadius: 12,
      margin: 10,
      padding: 10,
    },
  },
}));

Partner.propTypes = {};

function Partner(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <h1 className={classes.styTxtTitle}>{`CÁC ĐỐI TÁC TIÊU BIỂU`}</h1>
      <Container className={classes.coverContent}>
        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          <Slider {...settings}>
            {lstPartner.map((item, index) => (
              <div key={index}>
                <div className={classes.coverCard}>
                  <img src={item.image} alt={'Logo BTNOVA'} className={classes.imgLogo} />
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <h5 style={{ fontWeight: 400, fontSize: 14, margin: 0, textAlign: 'center' }}>{item.disPartner}</h5>
                    <h3 style={{ margin: 12, textAlign: 'center' }}>{item.namePartner}</h3>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </>
  );
}

export default Partner;
