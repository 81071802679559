import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import leftArrow from 'assets/images/left-arrow.svg';
import ButtonCom from 'components/Buttom';
import InputCom from 'components/InputCom';
import { loadding } from 'features/auth/userSlice';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { findBillStudent } from '../../paymentSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  title: {
    textAlign: 'center',
    color: '#18b0a3',
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
  },
  headerCard: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  card: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    background: 'white',
    width: '80%',
    borderRadius: 20,
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      width: '96%',
      paddingBottom: 10,
    },
  },
  coverHeaderTab: {
    flexDirection: 'row',
    display: 'flex',
    borderRadius: 20,
  },
  txtTitleTab: {
    textAlign: 'center',
  },
  coverTxtTitleTab: {
    width: '50%',
    cursor: 'pointer',
  },
  coverContentTab: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  styInput: {
    width: '100%',
    borderRadius: 30,
    backgroundColor: '#efefef',
    textAlign: 'center',
    fontSize: 20,
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  styCoverLstStudent: {
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  styCardStudent: {
    color: 'white',
    borderRadius: 20,
    paddingLeft: 30,
    paddingBottom: 12,
    marginTop: 12,
    width: '60%',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      paddingLeft: 18,
    },
  },
  styTxtInfoStudent: {
    marginBottom: 0,
    marginTop: 10,
  },
  coverIconHeaderCard: {
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
    },
  },
  styImgIconHeaderCard: {
    width: 28,
    height: 28,
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
  },
  coverTab: {
    width: '70%',
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}));

const InfoStudent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const showLoadding = loadding(true);
  const [studentSel, setStudentSel] = useState('');
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const handelFindBillStudent = useCallback(async () => {
    try {
      dispatch(showLoadding);
      let body = {
        StudentId: !state.isLis ? state?.infoStudent[0]?.StudentCode : studentSel.StudentCode,
        StudentType: 2,
        MerchantCode: process.env.REACT_APP_MERCHANTCODE,
      };
      const result = await dispatch(findBillStudent(body));
      const res = unwrapResult(result);
      dispatch(showLoadding);
      if (res.ErrorCode === 0 && res.Data !== null) {
        history.push('/payment/listBillStudent', {
          infoStudent: !state.isLis ? state.infoStudent[0] : studentSel,
          lstBill: res.Data,
        });
      } else {
        enqueueSnackbar(res.ErrorMsg, { variant: 'warning' });
      }
      //
    } catch (e) {
      dispatch(showLoadding);
      enqueueSnackbar(JSON.stringify(e), { variant: 'warning' });
    }
  }, [state, history, studentSel, enqueueSnackbar, dispatch]);

  const renderViewHeaderCard = () => {
    return (
      <div className={classes.headerCard}>
        <div className={classes.coverIconHeaderCard}>
          <img
            onClick={() => {
              history.goBack();
            }}
            src={leftArrow}
            alt="img"
            className={classes.styImgIconHeaderCard}
          />
        </div>
        <h2 className={classes.title}>{`${state.isLis ? 'Danh sách học sinh' : 'Thông tin học sinh'}`}</h2>
        <div className={classes.coverIconHeaderCard} />
      </div>
    );
  };

  return (
    <Box>
      <Container className={classes.container}>
        <div className={classes.card}>
          {renderViewHeaderCard()}
          {state.isLis ? (
            <div className={classes.styCoverLstStudent}>
              {state?.infoStudent.map((student, index) => {
                return (
                  <div
                    onClick={() => {
                      setStudentSel(student);
                    }}
                    className={classes.styCardStudent}
                    style={{
                      backgroundColor:
                        student.StudentCode === studentSel.StudentCode ? '#F1727E' : index % 2 ? '#2fb1ea' : '#8ac47c',
                    }}
                  >
                    <h4 className={classes.styTxtInfoStudent}>{`Họ và tên: ${student.StudentName}`}</h4>
                    <h4 className={classes.styTxtInfoStudent}>{`Ngày sinh: ${moment(student.Birthday).format(
                      'DD/MM/YYYY'
                    )}`}</h4>
                    <h4 className={classes.styTxtInfoStudent}>{`Lớp: ${student.ClassName}`}</h4>
                    <h4 className={classes.styTxtInfoStudent}>{`Mã học sinh: ${student.StudentCode}`}</h4>
                  </div>
                );
              })}
              <ButtonCom
                disabled={studentSel === ''}
                text="Kiểm Tra Học Phí"
                typeBTN="BRAND"
                callbackClick={handelFindBillStudent}
                style={{ color: 'white', width: 300, marginTop: 30 }}
              />
            </div>
          ) : (
            <div className={classes.coverContentTab}>
              <div className={classes.coverTab}>
                <InputCom
                  isShowLable={false}
                  isTextCenter={true}
                  value={state.infoStudent[0].SchoolName}
                  disabled={true}
                  classes={classes.styInput}
                  fullWidth={true}
                />
                <InputCom
                  isShowLable={false}
                  isTextCenter={true}
                  value={state.infoStudent[0].StudentCode}
                  disabled={true}
                  classes={classes.styInput}
                />
                <InputCom
                  isShowLable={false}
                  isTextCenter={true}
                  value={state.infoStudent[0].StudentName}
                  disabled={true}
                  classes={classes.styInput}
                />
                <InputCom
                  isShowLable={false}
                  isTextCenter={true}
                  value={state.infoStudent[0].ClassName}
                  isNumber={true}
                  classes={classes.styInput}
                  disabled={true}
                />
                <InputCom
                  isShowLable={false}
                  isTextCenter={true}
                  value={moment(state.infoStudent[0].Birthday).format('DD/MM/YYYY')}
                  isNumber={true}
                  classes={classes.styInput}
                  disabled={true}
                />
              </div>
              <ButtonCom
                text="Kiểm Tra Học Phí"
                typeBTN="BRAND"
                callbackClick={handelFindBillStudent}
                style={{ color: 'white', width: 300, marginTop: 30 }}
              />
            </div>
          )}
        </div>
      </Container>
    </Box>
  );
};

export default InfoStudent;
