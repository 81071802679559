import React from 'react';
import leftArrow from 'assets/images/left-arrow.svg';
import rightArrow from 'assets/images/right-arrow.svg';
import './BtnBanner.css';
BtnBanner.propTypes = {};

function BtnBanner({ direction, moveBanner }) {
  return (
    <button onClick={moveBanner} className={direction === 'next' ? 'btn-slide next' : 'btn-slide prev'}>
      <img src={direction === 'next' ? rightArrow : leftArrow} />
    </button>
  );
}
export default BtnBanner;
