import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

FullpageLoader.propTypes = {};

function FullpageLoader(props) {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        zIndex: 10000,
      }}
    >
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <ReactLoading color="#fff" type={'spin'} />
        <p style={{ color: 'white' }}>{`Đang xử lý dữ liệu...`}</p>
      </div>
    </div>
  );
}

export default FullpageLoader;
