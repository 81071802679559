import { AppBar, Box, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo_header from 'assets/images/logo_header.png';
import { lisDataHeader } from 'constants/dataCommon';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: '#FFF',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    position: 'fixed',
  },
  contain: {
    maxWidth: 1300,
    width: '100%',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    alignItems: 'center',
  },

  menuListUl: {
    textAlign: 'center',
    margin: theme.spacing(0, 0),
    flex: 1,
    zIndex: 3,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  coverListMenu: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  menuListLi: {
    display: 'inline-block',
  },
  buttonMenu: {
    padding: theme.spacing(1, 3),
    fontWeight: 800,
    fontSize: 15,
    color: '#F1727E',
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1),
    },
  },
  lineMenuSel: {
    height: 2,
    width: '80%',
    background: '#F1727E',
    borderRadius: 6,
    margin: '0px 10px',
  },
  lineMenuUnsel: {
    height: 2,
    width: '80%',
    background: '#fff',
    borderRadius: 6,
    margin: '0px 10px',
  },
  imgLogo: {
    [theme.breakpoints.down('xl')]: {
      width: 170,
      height: 170 * 0.269,
      margin: 20,
    },
    [theme.breakpoints.down('lg')]: {
      width: 170,
      height: 170 * 0.269,
      margin: 20,
    },
    [theme.breakpoints.down('sm')]: {
      width: 80,
      height: 80 * 0.269,
      margin: 10,
    },
  },
  iconMenuMobile: {
    color: '#F1727E',
    display: 'none',
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}));
const Header = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const itemRef = useRef(null);
  const history = useHistory();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelClickMenuParent = (item, e) => {
    if (item.path === '') {
      enqueueSnackbar('Chức năng đang được cập nhật vui lòng thử lại sau', { variant: 'warning' });
    } else {
      history.push(item.path);
    }
  };
  const handleMenuMobile = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenuMobile = (item) => {
    setAnchorEl(null);
    if (item.path === '') {
      enqueueSnackbar('Chức năng đang được cập nhật vui lòng thử lại sau', { variant: 'warning' });
    } else {
      history.push(item.path);
    }
  };
  return (
    <Box>
      <AppBar position="static" className={classes.root} elevation={0}>
        <Toolbar className={classes.contain}>
          <img src={logo_header} alt={'Logo Yschool'} onClick={(e) => history.push('/')} className={classes.imgLogo} />
          <div className={classes.coverListMenu}>
            <ul className={classes.menuListUl}>
              {lisDataHeader.map((item, index) => {
                return (
                  <li className={classes.menuListLi} key={index}>
                    <button
                      className={classes.buttonMenu}
                      ref={itemRef}
                      onClick={(e) => handelClickMenuParent(item, e)}
                      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                    >
                      <h4
                        style={{
                          fontWeight: '500',
                          fontSize: location.pathname === item.path ? 16 : 15,
                          margin: 10,
                        }}
                      >
                        {item.title}
                      </h4>
                      {item.path === '/' ? (
                        location.pathname === '/' ? (
                          <div className={classes.lineMenuSel} />
                        ) : (
                          <div className={classes.lineMenuUnsel} />
                        )
                      ) : location.pathname.includes(item.path) ? (
                        <div className={classes.lineMenuSel} />
                      ) : (
                        <div className={classes.lineMenuUnsel} />
                      )}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          <IconButton edge="end" className={classes.iconMenuMobile} aria-label="menu" onClick={handleMenuMobile}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {lisDataHeader.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleClickMenuMobile(item)}
            style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
          >
            <div>
              <h4
                style={{
                  fontWeight: '500',
                  fontSize: location.pathname === item.path ? 16 : 15,
                  textAlign: 'center',
                  margin: 8,
                  color: '#F1727E',
                }}
              >
                {item.title}
              </h4>
              {item.path === '/' ? (
                location.pathname === '/' ? (
                  <div className={classes.lineMenuSel} />
                ) : (
                  <div className={classes.lineMenuUnsel} />
                )
              ) : location.pathname.includes(item.path) ? (
                <div className={classes.lineMenuSel} />
              ) : (
                <div className={classes.lineMenuUnsel} />
              )}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
export default Header;
