function nlog(...val) {
  // console.log(...val);
}
function formatMoney(value) {
  if (value == '') return 0;
  if (value > 0) {
    var stemp = '';
    var svalue = value.toString();
    let icount = 0;
    for (var i = svalue.length - 1; i >= 0; i--) {
      stemp = svalue[i] + stemp;
      icount++;
      if (icount == 3 && i > 0) {
        icount = 0;
        stemp = ',' + stemp;
      }
    }
    return stemp;
  } else {
    var stemp = '';
    var svalue = value.toString();
    let icount = 0;
    for (var i = svalue.length - 1; i >= 0; i--) {
      if (!isNaN(svalue[i]) === false) {
        stemp = svalue[i] + stemp;
        icount++;
        if (icount == 3 && i > 0) {
          icount = 0;
          stemp = stemp;
        }
      } else {
        stemp = svalue[i] + stemp;
        icount++;
        if (icount == 3 && i > 0) {
          icount = 0;
          if (!isNaN(svalue[i - 1]) === false) {
            stemp = stemp;
          } else {
            stemp = ',' + stemp;
          }
        }
      }
    }
    return stemp;
  }
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function countTotalPageTable(totalRow, rowsPerPage) {
  return Math.floor(totalRow / rowsPerPage) + (totalRow % rowsPerPage > 0 ? 1 : 0);
}

export default {
  nlog,
  formatMoney,
  capitalizeFirstLetter,
  countTotalPageTable,
};
