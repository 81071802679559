import Images from 'assets/images';

export const lisDataHeader = [
  {
    title: 'Trang Chủ',
    path: '/',
  },
  {
    title: 'Thanh Toán',
    path: '/payment',
  },
  {
    title: 'Tin Tức',
    path: '/news',
    // path: '',
  },
  {
    title: 'Hướng Dẫn',
    path: '/tutorial',
  },
  {
    title: 'Chính Sách',
    path: '/policy',
    // path: '',
  },
  {
    title: 'Tuyển Dụng',
    path: '/recruit',
    // path: '',
  },
];

export const lstVideoTutorial = [
  {
    videoId: 'FwyNc59gQ2g',
    title: 'Giới thiệu ứng dụng Trường em - Yschool',
    image: Images.FwyNc59gQ2g,
  },
  {
    videoId: 'Tnb_4N4Ngro',
    title: 'Thanh toán học phí đơn giản cùng Yschool',
    image: Images.Tnb_4N4Ngro,
  },
  {
    videoId: 'QVrOka5snog',
    title: 'Hướng dẫn tải ứng dụng Yschool trên Google Play',
    image: Images.QVrOka5snog,
  },
  {
    videoId: 'MhkuJ8sE2K8',
    title: 'Hướng dẫn tải ứng dụng Yschool trên App Store',
    image: Images.MhkuJ8sE2K8,
  },
  {
    videoId: 'q-r-aXICljI',
    title: 'Hướng dẫn thanh toán học phí và bảo hiểm y tế trên Yschool',
    image: Images.qraXICljI,
  },
  {
    videoId: 'LGiRsvIz7Bk',
    title: 'Hướng dẫn liên kết học sinh và thanh toán học phí bằng thẻ ATM, Chuyển khoản',
    image: Images.LGiRsvIz7Bk,
  },
  {
    videoId: 'FDLs3hkKW2Y',
    title: 'Hướng dẫn liên kết học sinh và thanh toán bảo hiểm y tế',
    image: Images.FDLs3hkKW2Y,
  },
  {
    videoId: 'BtQ91nojGkI',
    title: 'Hướng dẫn liên kết học sinh và thanh toán học phí trên Yschool',
    image: Images.BtQ91nojGkI,
  },
  {
    videoId: 'xb8IMiuCI9s',
    title: 'Vòng quay may mắn mùa Trung thu 2021 cùng Yschool',
    image: Images.xb8IMiuCI9s,
  },
];

export const lstPartner = [
  {
    namePartner: 'SAIGONBANK',
    disPartner: 'Ngân hàng TMCP Sài Gòn Công Thương',
    image: Images.SaiGon_Bank,
  },
  {
    namePartner: 'Ví Điện Tử MOMO',
    disPartner: 'Công ty Cổ phần dịch vụ Di Động Trực Tuyến',
    image: Images.img_momo,
  },
  {
    namePartner: 'VPBANK',
    disPartner: 'Ngân hàng TMCP Việt Nam Thịnh Vượng',
    image: Images.VP_Bank,
  },
  {
    namePartner: 'Viettel Money',
    disPartner: 'Tổng Công ty Dịch vụ số Viettel',
    image: Images.logoViettelMoney,
  },
  {
    namePartner: 'ZaloPay',
    disPartner: 'Công ty Cổ phần ZION',
    image: Images.ZaloPay,
  },
];
