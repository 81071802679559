import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonCom from 'components/Buttom';
import InputCom from 'components/InputCom';
import { useSnackbar } from 'notistack';
import { findBillStudent, findStudentByCode } from '../../paymentSlice';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { loadding } from 'features/auth/userSlice';
import leftArrow from 'assets/images/left-arrow.svg';

const useStyles = makeStyles((theme) => ({
  main: {
    background: 'white',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    color: '#18b0a3',
  },
  card: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    background: 'white',
    width: '80%',
    marginTop: 30,
    borderRadius: 20,
    paddingBottom: 30,
  },
  coverHeaderTab: {
    flexDirection: 'row',
    display: 'flex',
    borderRadius: 20,
  },
  txtTitleTab: {
    textAlign: 'center',
  },
  coverTxtTitleTab: {
    width: '50%',
    cursor: 'pointer',
  },
  coverContentTab: {
    flexDirection: 'column',
    display: 'flex',
    minHeight: 280,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  styInput: {
    width: '100%',
    borderRadius: 30,
    backgroundColor: '#efefef',
    textAlign: 'center',
    fontSize: 20,
    color: '#000',
  },
  styCoverLstStudent: {
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  styCardStudent: {
    color: 'white',
    borderRadius: 20,
    paddingLeft: 30,
    paddingBottom: 12,
    marginTop: 12,
    width: '60%',
    cursor: 'pointer',
  },
  styTxtInfoStudent: {
    marginBottom: 0,
    marginTop: 10,
  },
}));

const InfoStudentBMC = (props) => {
  const params = useParams();
  const match = useRouteMatch();

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const showLoadding = loadding(true);
  const [infoStudent, setInfoStudent] = useState('');
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    handelFindStudent();
    return () => {};
  }, [params.studentId]);

  const handelFindStudent = useCallback(async () => {
    try {
      let body = {
        StudentCode: params.studentId,
        StudentType: 2,
      };
      dispatch(showLoadding);
      const result = await dispatch(findStudentByCode(body));
      const res = unwrapResult(result);
      dispatch(showLoadding);
      if (res.ErrorCode === 0 && res.Data !== null) {
        setInfoStudent(res.Data.Students);
      } else {
        enqueueSnackbar(res.ErrorMsg, { variant: 'warning' });
      }
    } catch (e) {
      dispatch(showLoadding);
      enqueueSnackbar(JSON.stringify(e), { variant: 'warning' });
    }
  }, [enqueueSnackbar, dispatch, history, match, params]);

  const handelFindBillStudent = useCallback(async () => {
    try {
      dispatch(showLoadding);
      let body = {
        StudentId: infoStudent[0].StudentCode,
        StudentType: 2,
        MerchantCode: process.env.REACT_APP_MERCHANTCODE,
      };
      const result = await dispatch(findBillStudent(body));
      const res = unwrapResult(result);
      dispatch(showLoadding);
      if (res.ErrorCode === 0 && res.Data !== null) {
        history.push('/payment/listBillStudent', {
          infoStudent: infoStudent[0],
          lstBill: res.Data,
          isBMC: true,
          username: params?.username || moment(new Date()).format('YYYYMMDDHHMMSS'),
        });
      } else {
        enqueueSnackbar(res.ErrorMsg, { variant: 'warning' });
      }
    } catch (e) {
      dispatch(showLoadding);
      enqueueSnackbar(JSON.stringify(e), { variant: 'warning' });
    }
  }, [state, history, infoStudent, enqueueSnackbar, dispatch]);
  return (
    <Box className={classes.main}>
      <Container className={classes.container}>
        <div className={classes.card}>
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
            <h2 className={classes.title}>{'Thông tin học sinh'}</h2>
          </div>
          {infoStudent !== '' && (
            <div className={classes.coverContentTab}>
              <div style={{ width: '70%', flexDirection: 'column', display: 'flex' }}>
                <InputCom
                  isShowLable={false}
                  isTextCenter={true}
                  value={infoStudent[0].SchoolName}
                  disabled={true}
                  classes={classes.styInput}
                  fullWidth={true}
                />
                <InputCom
                  isShowLable={false}
                  isTextCenter={true}
                  value={infoStudent[0].StudentCode}
                  disabled={true}
                  classes={classes.styInput}
                />
                <InputCom
                  isShowLable={false}
                  placeholder={'Họ và Tên học sinh'}
                  isTextCenter={true}
                  value={infoStudent[0].StudentName}
                  disabled={true}
                  classes={classes.styInput}
                />
                <InputCom
                  isShowLable={false}
                  placeholder={'Năm sinh học sinh'}
                  isTextCenter={true}
                  value={infoStudent[0].ClassName}
                  isNumber={true}
                  classes={classes.styInput}
                  disabled={true}
                />
                <InputCom
                  isShowLable={false}
                  isTextCenter={true}
                  value={moment(infoStudent[0].Birthday).format('DD/MM/YYYY')}
                  isNumber={true}
                  classes={classes.styInput}
                  disabled={true}
                />
              </div>
              <ButtonCom
                text="Kiểm Tra Học Phí"
                typeBTN="BRAND"
                callbackClick={handelFindBillStudent}
                style={{ color: 'white', width: 300, marginTop: 30 }}
              />
            </div>
          )}
        </div>
      </Container>
    </Box>
  );
};

export default InfoStudentBMC;
