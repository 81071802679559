import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Toolbar, Container } from '@material-ui/core';
import Images from 'assets/images';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 50,
  },
  contain: {
    width: '100%',
    background: '#F1727E',
    padding: 30,
    borderRadius: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: 'white',
    [theme.breakpoints.down('xl')]: {
      padding: 30,
      borderRadius: 30,
    },
    [theme.breakpoints.down('lg')]: {
      padding: 30,
      borderRadius: 30,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 10,
      borderRadius: 10,
      flexDirection: 'column',
    },
  },
  styTxtTutoZalo: {
    margin: 0,
    fontSize: 15,
    marginLeft: 6,
  },
  styTxtTich: {
    color: 'white',
    width: 18,
    height: 18,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  styCoverTxtTutorialZalo: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: 4,
  },
  styIconChannel: {
    width: 40,
    height: 40,
    [theme.breakpoints.down('xl')]: {
      width: 40,
      height: 40,
    },
    [theme.breakpoints.down('lg')]: {
      width: 40,
      height: 40,
    },
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
  },
  styTxtChannel: {
    margin: 0,
    fontSize: 15,
    marginLeft: 6,
  },
  styCoverViewChannel: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginTop: 12,
  },
  styCoverColChannel: {
    flexDirection: 'column',
    display: 'flex',
    width: '50%',
  },
  styCoverViewAllChannel: {
    width: '60%',
    flexDirection: 'column',
    display: 'flex',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'center',
      marginTop: 30,
    },
  },
  styImgLogoBig: {
    width: 180,
    height: 180 * 0.268,
    [theme.breakpoints.down('xl')]: {
      width: 180,
      height: 180 * 0.268,
    },

    [theme.breakpoints.down('sm')]: {
      width: 130,
      height: 130 * 0.268,
      margin: 10,
    },
  },
  styTxtInfo: {
    margin: 12,
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('xl')]: {
      fontSize: 14,
      margin: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: 10,
      textAlign: 'center',
    },
  },
  styCoverViewBig: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  coverChannelSupport: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
    },
  },
  coverViewSupport: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
      flexDirection: 'column',
    },
  },
  coverZaloSupport: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      marginLeft: 20,
    },
  },
  coverViewInfoCompany: {
    width: '25%',
    paddingRight: 50,
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 10,
    },
  },
  styImgCer: {
    height: 180 * 0.268,
    [theme.breakpoints.down('sm')]: {
      height: 140 * 0.268,
    },
  },
  coverViewCer: {
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'center',
      marginTop: 30,
    },
  },
  styTxtTitle: {
    margin: 6,
  },
}));
Footer.propTypes = {};
function Footer(props) {
  const classes = useStyles();
  return (
    <Box pt={4}>
      <Container>
        <div className={classes.root}>
          <Toolbar className={classes.contain}>
            <div className={classes.coverViewInfoCompany}>
              <div className={classes.styCoverViewBig}>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <img src={Images.img_footer} alt={'Logo BTNOVA'} className={classes.styImgLogoBig} />
                </div>
                <p className={classes.styTxtInfo}>
                  {`Công ty CP Dịch Vụ Việt Nam Trực Tuyến Tầng 8 tòa nhà The Galleria Office Building, 258 Nam Kỳ Khởi Nghĩa, Phường 8, Quận 3, TPHCM`}
                </p>
                <h5 className={classes.styTxtInfo}>{`Copyright © 2022 Công ty CP Dịch Vụ Việt Nam Trực Tuyến`}</h5>
              </div>
            </div>
            <div className={classes.styCoverViewAllChannel}>
              <h4 className={classes.styTxtTitle}>{`LIÊN HỆ`}</h4>
              <div className={classes.coverViewSupport}>
                <div className={classes.coverChannelSupport}>
                  <div className={classes.styCoverColChannel}>
                    <a
                      href="Skype:live:.cid.4d65289e70fc1529?chat"
                      target="blank"
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      <div className={classes.styCoverViewChannel}>
                        <img src={Images.LogoSkype} alt={'Logo Skype'} className={classes.styIconChannel} />
                        <p className={classes.styTxtChannel}>{`Yschool.hotro`}</p>
                      </div>
                    </a>
                    <div className={classes.styCoverViewChannel}>
                      <img src={Images.LogoCall} alt={'Logo BTNOVA'} className={classes.styIconChannel} />
                      <p className={classes.styTxtChannel}>{`19006474`}</p>
                    </div>
                  </div>
                  <div className={classes.styCoverColChannel}>
                    <a href="http://m.me/WmnYschool" target="blank" style={{ textDecoration: 'none', color: 'white' }}>
                      <div className={classes.styCoverViewChannel}>
                        <img src={Images.LogoFacebook} alt={'Logo Facebook'} className={classes.styIconChannel} />
                        <p className={classes.styTxtChannel}>{`Yschool`}</p>
                      </div>
                    </a>
                    <div className={classes.styCoverViewChannel}>
                      <img src={Images.LogoSkyper} alt={'Logo Skyper'} className={classes.styIconChannel} />
                      <p className={classes.styTxtChannel}>{`0986238393`}</p>
                    </div>
                  </div>
                </div>
                <a
                  href="https://zalo.me/2696293591339322049"
                  target="blank"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  <div className={classes.coverZaloSupport}>
                    <img src={Images.LogoZalo} alt={'Logo Zalo'} className={classes.styIconChannel} />
                    <div style={{ marginLeft: 4 }}>
                      <div className={classes.styCoverTxtTutorialZalo}>
                        <DoneIcon className={classes.styTxtTich} />
                        <p className={classes.styTxtTutoZalo}>{`Khách hàng vào ứng dụng Zalo`}</p>
                      </div>
                      <div className={classes.styCoverTxtTutorialZalo}>
                        <DoneIcon className={classes.styTxtTich} />
                        <p className={classes.styTxtTutoZalo}>{`Chọn tìm bạn bè ở thanh tìm kiếm`}</p>
                      </div>
                      <div className={classes.styCoverTxtTutorialZalo}>
                        <DoneIcon className={classes.styTxtTich} />
                        <p className={classes.styTxtTutoZalo}>
                          {`Nhập "Yschool - trường em" và nhấn "Quan Tâm" để được hỗ trợ`}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className={classes.coverViewCer}>
              <h4 style={{ margin: 6, textAlign: 'center' }}>{`CHỨNG NHẬN BỞI`}</h4>
              <a
                href="http://online.gov.vn/(X(1)S(jvuuiuf2wi2evcicbig1umsm))/Home/AppDetails/1603?AspxAutoDetectCookieSupport=1"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Images.logoSaleNoti} alt={'Logo BTNOVA'} className={classes.styImgCer} />
              </a>
            </div>
          </Toolbar>
        </div>
      </Container>
    </Box>
  );
}

export default Footer;
