import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Banner from 'components/Banner';
import Partner from './components/Partner';
import TutorialUser from './components/TutorialUser';
import WhyChoose from './components/WhyChoose';
const useStyles = makeStyles((theme) => ({}));

Home.propTypes = {};
function Home(props) {
  const classes = useStyles();
  return (
    <Box style={{ background: 'white' }}>
      <Banner />
      <TutorialUser />
      <WhyChoose />
      <Partner />
    </Box>
  );
}

export default Home;
