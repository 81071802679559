import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import FindStudent from './pages/FindStudent';
import InfoStudent from './pages/InfoStudent';
import ListBillStudent from './pages/ListBillStudent';
import PaymentMethod from './pages/PaymentMethod';
import PaymentResults from './pages/PaymentResults';
import InfoStudentBMC from './pages/InfoStudentBMC';
import { Box } from '@material-ui/core';

function PaymentFeature(props) {
  const match = useRouteMatch();
  return (
    <Box pt={4}>
      <Switch>
        <Route path={match.url} exact component={FindStudent} />
        <Route path={match.url + '/infoStudent'} exact component={InfoStudent} />
        <Route path={match.url + '/infoStudentBMC/:studentId/:username?'} exact component={InfoStudentBMC} />
        <Route path={match.url + '/listBillStudent'} exact component={ListBillStudent} />
        <Route path={match.url + '/paymentMethod'} exact component={PaymentMethod} />
        <Route path={match.url + '/paymentResults/:data'} exact component={PaymentResults} />
      </Switch>
    </Box>
  );
}
PaymentFeature.propTypes = {};

export default PaymentFeature;
