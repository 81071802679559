import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import ButtonCom from 'components/Buttom';
import DropdownAutocomplete from 'components/DropdownAutocomplete';
import InputCom from 'components/InputCom';
import { loadding } from 'features/auth/userSlice';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { findStudentByCode, findStudentByInfo, getListSchool } from '../../paymentSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  title: {
    textAlign: 'center',
    color: '#18b0a3',
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
  },
  card: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    width: '80%',
    borderRadius: 20,
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      width: '96%',
      paddingBottom: 10,
    },
  },
  coverHeaderTab: {
    flexDirection: 'row',
    display: 'flex',
    borderRadius: 20,
  },
  txtTitleTab: {
    textAlign: 'center',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  coverTxtTitleTab: {
    width: '50%',
    cursor: 'pointer',
    padding: '0 10px',
  },
  coverContentTab: {
    flexDirection: 'column',
    display: 'flex',
    minHeight: 280,
    justifyContent: 'space-around',
    paddingTop: 30,
    alignItems: 'center',
  },
  styInput: {
    width: '100%',
    borderRadius: 30,
    backgroundColor: '#efefef',
    color: '#000000',
    textAlign: 'center',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  coverTab: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}));

const FindStudent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const showLoadding = loadding(true);
  const [tabSel, setTabSel] = useState(1);
  const [studentCode, setStudentCode] = useState('');
  const [studentName, setStudentName] = useState('');
  const [studentYearOfBirth, setStudentYearOfBirth] = useState('');
  const [lstSchool, setLstSchool] = useState('');
  const [schoolSel, setSchoolSel] = useState(null);
  const [searchSchool, setSearchSchool] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handelGetListSchool = useCallback(
    async (search = '') => {
      try {
        let body = {
          PageIndex: 1,
          PageSize: 1000,
          SearchQuery: search,
        };
        const result = await dispatch(getListSchool(body));
        const res = unwrapResult(result);
        if (res.ErrorCode === 0 && res.Data !== null) {
          setLstSchool(res.Data);
        } else {
          // enqueueSnackbar(res.ErrorMsg, { variant: 'warning' });
        }
      } catch (e) {}
    },
    [dispatch]
  );

  useEffect(() => {
    handelGetListSchool(searchSchool);
    return () => {};
  }, [handelGetListSchool, searchSchool]);

  const handelFindStudent = useCallback(async () => {
    if (tabSel === 1) {
      if (studentCode.toString().trim().length === 0) {
        enqueueSnackbar('Vui lòng nhập mã số học sinh để tìm kiếm', { variant: 'warning' });
        return;
      }
      try {
        let body = {
          StudentCode: studentCode.toString().trim(),
          StudentType: 2,
        };
        dispatch(showLoadding);
        const result = await dispatch(findStudentByCode(body));
        const res = unwrapResult(result);
        dispatch(showLoadding);
        if (res.ErrorCode === 0 && res.Data !== null) {
          history.push(match.url + '/infoStudent', {
            infoStudent: res.Data.Students,
            isLis: false,
          });
        } else {
          enqueueSnackbar(res.ErrorMsg, { variant: 'warning' });
        }
      } catch (e) {
        dispatch(showLoadding);
        enqueueSnackbar(JSON.stringify(e), { variant: 'warning' });
      }
    } else {
      if (studentName.toString().trim().length === 0) {
        enqueueSnackbar('Vui lòng nhập Họ và Tên học sinh để tìm kiếm!', { variant: 'warning' });
        return;
      }
      if (studentYearOfBirth.toString().trim().length !== 4) {
        enqueueSnackbar('Vui lòng nhập đủ Năm sinh của học sinh tìm kiếm!', { variant: 'warning' });
        return;
      }
      if (schoolSel === null) {
        enqueueSnackbar('Vui lòng chọn Trường để tìm kiếm học sinh', { variant: 'warning' });
        return;
      }
      try {
        dispatch(showLoadding);
        let body = {
          StudentName: studentName
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          YearOfBirth: studentYearOfBirth,
          SchoolCode: schoolSel.SchoolCode,
        };
        const result = await dispatch(findStudentByInfo(body));
        const res = unwrapResult(result);
        dispatch(showLoadding);
        if (res.ErrorCode === 0 && res.Data !== null) {
          history.push(match.url + '/infoStudent', {
            infoStudent: res.Data.Students,
            isLis: true,
          });
        } else {
          enqueueSnackbar(res.ErrorMsg, { variant: 'warning' });
        }
      } catch (e) {
        dispatch(showLoadding);
        enqueueSnackbar(JSON.stringify(e), { variant: 'warning' });
      }
    }
  }, [tabSel, studentCode, studentName, studentYearOfBirth, schoolSel, enqueueSnackbar, dispatch, history, match]);

  const renderViewTabar = () => {
    return (
      <div className={classes.coverHeaderTab}>
        <div
          onClick={() => {
            setTabSel(1);
          }}
          className={classes.coverTxtTitleTab}
          style={{
            backgroundColor: tabSel === 1 ? 'white' : '#F8F8F8',
          }}
        >
          <h4
            className={classes.txtTitleTab}
            style={{ color: tabSel === 1 ? '#F1727E' : '#8e8e93' }}
          >{`Tìm theo Mã Số Học Sinh`}</h4>
        </div>
        <div
          onClick={() => {
            setTabSel(2);
          }}
          className={classes.coverTxtTitleTab}
          style={{
            backgroundColor: tabSel === 2 ? 'white' : '#F8F8F8',
          }}
        >
          <h4
            className={classes.txtTitleTab}
            style={{ color: tabSel === 2 ? '#F1727E' : '#8e8e93' }}
          >{`Tìm theo Họ Tên Học Sinh`}</h4>
        </div>
      </div>
    );
  };

  const renderTabOne = () => {
    return (
      <div className={classes.coverTab}>
        <InputCom
          isShowLable={false}
          placeholder={'Nhập mã số học sinh'}
          isTextCenter={true}
          value={studentCode}
          onChangeValue={(value) => {
            setStudentCode(value);
          }}
          isNumber={true}
          classes={classes.styInput}
        />
      </div>
    );
  };
  const renderTabTwo = () => {
    return (
      <div className={classes.coverTab}>
        <InputCom
          isShowLable={false}
          placeholder={'Họ và Tên học sinh'}
          isTextCenter={true}
          value={studentName}
          onChangeValue={(value) => {
            setStudentName(value);
          }}
          classes={classes.styInput}
        />
        <InputCom
          isShowLable={false}
          placeholder={'Năm sinh học sinh'}
          isTextCenter={true}
          value={studentYearOfBirth}
          onChangeValue={(value) => {
            setStudentYearOfBirth(value);
          }}
          isNumber={true}
          classes={classes.styInput}
        />
        <div style={{ marginTop: 16 }}>
          <DropdownAutocomplete
            label="Chọn trường của học sinh"
            nameItemNone="Tất cả"
            fullWidth={true}
            data={lstSchool || []}
            onChanged={(value, type) => {
              setSchoolSel(value);
            }}
            onChangeSearchSchool={(value) => {
              setSearchSchool(value);
            }}
            type={'SCHOOL'}
            placeholder={'Chọn trường của học sinh'}
            keyName={'SchoolName'}
            keyValue={'SchoolId'}
            classProps={classes.styInput}
          />
        </div>
      </div>
    );
  };

  return (
    <Box>
      <Container className={classes.container}>
        <h2 className={classes.title}>{`Tìm kiếm học sinh`}</h2>
        <div className={classes.card}>
          {renderViewTabar()}
          <div className={classes.coverContentTab}>
            {tabSel === 1 ? renderTabOne() : renderTabTwo()}
            <ButtonCom
              text="Tìm kiếm"
              typeBTN="BRAND"
              callbackClick={handelFindStudent}
              style={{ color: 'white', width: 300 }}
            />
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default FindStudent;
