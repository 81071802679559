import axiosClient from './axiosClient';
import { AESEncrypt, RSAEncrypt } from 'constants/security';

const paymentApi = {
  findStudentById(data) {
    const url = '/api/student/find-student-by-id';
    const dataEncrypt = AESEncrypt(JSON.stringify(data));
    let body = {
      Data: dataEncrypt.toString(),
      MerchantCode: process.env.REACT_APP_MERCHANTCODE,
    };
    const axios = axiosClient(process.env.REACT_APP_MAIN_HOST);
    return axios.post(url, body);
  },

  findStudentByInfo(data) {
    const url = '/api/student/find-student';
    const dataEncrypt = AESEncrypt(JSON.stringify(data));
    let body = {
      Data: dataEncrypt.toString(),
      MerchantCode: process.env.REACT_APP_MERCHANTCODE,
    };
    const axios = axiosClient(process.env.REACT_APP_MAIN_HOST);
    return axios.post(url, body);
  },
  findBillStudent(data) {
    const url = '/api/yopayment/payment/find-bill-ys';
    const dataEncrypt = AESEncrypt(JSON.stringify(data));
    let body = {
      Data: dataEncrypt.toString(),
      MerchantCode: process.env.REACT_APP_MERCHANTCODE,
    };
    const axios = axiosClient(process.env.REACT_APP_MAIN_HOST_YOPAY);
    return axios.post(url, body);
  },
  getListSchool(data) {
    const url = '/api/user/list-school';
    const dataEncrypt = AESEncrypt(JSON.stringify(data));
    let body = {
      Data: dataEncrypt.toString(),
      MerchantCode: process.env.REACT_APP_MERCHANTCODE,
    };
    const axios = axiosClient(process.env.REACT_APP_MAIN_HOST);
    return axios.post(url, body);
  },

  getListPaymentFee(schoolCode = '') {
    const url = '/api/yopayment/token/get';
    let data = {
      MerchantCode: process.env.REACT_APP_MERCHANTCODE,
      CustomerId: '1',
      ServiceId: '0300000000',
      SchoolCode: schoolCode,
    };
    const dataEncrypt = AESEncrypt(JSON.stringify(data));
    let body = {
      Data: dataEncrypt.toString(),
      MerchantCode: process.env.REACT_APP_MERCHANTCODE,
    };
    const axios = axiosClient(process.env.REACT_APP_MAIN_HOST_YOPAY);
    return axios.post(url, body);
  },
  getAppFee(schoolCode = '') {
    const url = '/api/service/V2/get/application-fee';
    let data = {
      MerchantCode: process.env.REACT_APP_MERCHANTCODE,
      CustomerId: '1',
      ServiceId: '0300000000',
      SchoolCode: schoolCode,
    };
    const dataEncrypt = AESEncrypt(JSON.stringify(data));
    let body = {
      Data: dataEncrypt.toString(),
      MerchantCode: process.env.REACT_APP_MERCHANTCODE,
    };
    const axios = axiosClient(process.env.REACT_APP_MAIN_HOST_YOPAY);
    return axios.post(url, body);
  },

  createOrderTuitonPayment(data) {
    data.MerchantCode = process.env.REACT_APP_MERCHANTCODE;
    const url = '/api/yopayment/payment/create-order-ys-v2';
    const dataEncrypt = AESEncrypt(JSON.stringify(data));
    let body = {
      Data: dataEncrypt.toString(),
      MerchantCode: process.env.REACT_APP_MERCHANTCODE,
    };
    const axios = axiosClient(process.env.REACT_APP_MAIN_HOST_YOPAY);
    return axios.post(url, body);
  },
};
export default paymentApi;
