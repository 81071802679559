import React, { useCallback } from 'react';
import YouTube from 'react-youtube';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ssss: {
    borderRadius: 20,
  },
}));
const YoutubePlayer = ({ videoId, style }) => {
  const classes = useStyles();

  const onReady = useCallback((event) => {
    event.target.pauseVideo();
  }, []);
  return (
    <YouTube
      videoId={videoId}
      opts={{
        height: 220,
        iframeClassName: classes.ssss,
        className: classes.ssss,
        width: 400,
        playerVars: {
          autoplay: 0,
        },
        borderRadius: 20,
        ...style,
      }}
      onReady={onReady}
    />
  );
};

export default YoutubePlayer;
