import userReducer from 'features/auth/userSlice';
// import commonReducer from 'features/dashboard/commonSlice';

const { configureStore } = require('@reduxjs/toolkit');
const rootReducer = {
  user: userReducer,
  // common: commonReducer,
};
const store = configureStore({
  reducer: rootReducer,
});

export default store;
