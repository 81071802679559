import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Images from 'assets/images';

const useStyles = makeStyles((theme) => ({
  styTxtTitle: {
    color: '#F1727E',
    textAlign: 'center',
    marginTop: 100,
    [theme.breakpoints.down('xl')]: {
      marginTop: 100,
      fontSize: 30,
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 50,
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
      fontSize: 16,
    },
  },
  coverContent: {
    marginTop: 50,
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  coverRowWhyChoose: {
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  coverItemWhyChoose: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 40,
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      padding: 10,
    },
  },
  imgItemWhyChoose: {
    width: 70,
    height: 70,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 50,
    },
  },
  txtItemWhyChoose: {
    fontSize: 20,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      margin: 10,
    },
  },
}));

WhyChoose.propTypes = {};

function WhyChoose(props) {
  const classes = useStyles();
  return (
    <>
      <h1 className={classes.styTxtTitle}>{`SỰ LỰA CHỌN CỦA NHÀ TRƯỜNG - PHỤ HUYNH`}</h1>
      <Container className={classes.coverContent}>
        <div className={classes.coverRowWhyChoose}>
          <div className={classes.coverItemWhyChoose}>
            <img src={Images.item_img_01} alt={'Logo BTNOVA'} className={classes.imgItemWhyChoose} />
            <p
              className={classes.txtItemWhyChoose}
            >{`Thanh toán hóa đơn, học phí với tốc độ nhanh chóng, thuận tiện, các giao dịch được bảo mật.`}</p>
          </div>
          <div className={classes.coverItemWhyChoose}>
            <img src={Images.item_img_02} alt={'Logo BTNOVA'} className={classes.imgItemWhyChoose} />
            <p className={classes.txtItemWhyChoose}>{`Đơn giản hóa quá trình thanh toán học phí`}</p>
          </div>
          <div className={classes.coverItemWhyChoose}>
            <img src={Images.item_img_03} alt={'Logo BTNOVA'} className={classes.imgItemWhyChoose} />
            <p className={classes.txtItemWhyChoose}>{`Trao đổi nhanh chóng kịp thời`}</p>
          </div>
        </div>
        <div className={classes.coverRowWhyChoose}>
          <div className={classes.coverItemWhyChoose}>
            <img src={Images.item_img_04} alt={'Logo BTNOVA'} className={classes.imgItemWhyChoose} />
            <p className={classes.txtItemWhyChoose}>{`Tối đa hóa sự tương tác Nhà trường - Gia đình.`}</p>
          </div>
          <div className={classes.coverItemWhyChoose}>
            <img src={Images.item_img_05} alt={'Logo BTNOVA'} className={classes.imgItemWhyChoose} />
            <p className={classes.txtItemWhyChoose}>{`Ứng dụng công nghệ vào trường học.`}</p>
          </div>
          <div className={classes.coverItemWhyChoose}>
            <img src={Images.item_img_06} alt={'Logo BTNOVA'} className={classes.imgItemWhyChoose} />
            <p className={classes.txtItemWhyChoose}>{`Tiết kiệm thời gian, nhân lực.`}</p>
          </div>
        </div>
      </Container>
    </>
  );
}

export default WhyChoose;
