import { TextField } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle } from 'react';

const InputCom = forwardRef((props, ref) => {
  const {
    name = '',
    label,
    disabled = false,
    placeholder,
    onChangeValue = () => {},
    style,
    value,
    isShowLable = false,
    isRow = false,
    data = '',
    isNumber = false,
    isTextCenter = false,
    noneSpace = false,
    classes = '',
    fullWidth = true,
  } = props;
  useImperativeHandle(ref, () => ({
    resetValue() {
      onChangeValueInput('');
    },
  }));
  const onChangeValueInput = (event) => {
    if (isNumber) {
      onChangeValue(event.target.value.replace(/\D/, '') || '', data);
    } else {
      onChangeValue(event.target.value || '', data);
    }
  };
  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        underline: {
          '&&&:before': {
            borderBottom: 'none',
            textAlign: 'center',
          },
          '&&:after': {
            borderBottom: 'none',
            textAlign: 'center',
          },
        },
      }}
      className={classes}
      inputProps={{ style: { textAlign: isTextCenter && 'center', height: 38, ...style } }}
      name={name}
      disabled={disabled}
      InputLabelProps={{
        shrink: isShowLable,
        style: { zIndex: 0 },
      }}
      style={{ width: fullWidth ? '100%' : isRow ? 100 : 220, ...style, textAlign: isTextCenter && 'center' }}
      disableUnderline={true}
      variant="standard"
      margin="normal"
      fullWidth={fullWidth}
      label={isShowLable ? label : ''}
      value={value}
      type={'text'}
      placeholder={placeholder}
      onChange={(event) => onChangeValueInput(event)}
    />
  );
});

InputCom.propTypes = {};

export default InputCom;
