import { createTheme, ThemeProvider } from '@material-ui/core';
import { GlobalStyle } from 'constants/GlobalStyle';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './features/dashboard';
import './assets/fonts/UTMEremitage.otf';
import './assets/fonts/UVNDaLat.ttf';
const theme = createTheme({
  palette: {
    action: {
      selected: 'rgba(53, 123, 245, 0.08)',
      hover: 'rgba(53, 123, 245, 0.08)',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&.Mui-selected': {
          backgroundColor: 'rgb(53, 123, 245, 0.1)',
        },
      },
    },
  },
});
const App = () => (
  <>
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/" component={Home} />
      </Switch>
      <GlobalStyle />
    </ThemeProvider>
  </>
);

export default App;
