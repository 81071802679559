import React from 'react';
import { Box, Container } from '@material-ui/core';
import { lstVideoTutorial } from 'constants/dataCommon';
import YoutubePlayer from 'components/YoutubePlayer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 120,
    [theme.breakpoints.down('sm')]: {
      marginTop: 60,
    },
  },
  coverCardVideo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    width: 320,
    height: 280,
    background: 'white',
    borderRadius: 20,
    margin: 20,
    padding: 0,
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      height: 230,
    },
  },
  txtTitle: {
    margin: 12,
    fontWeight: '500',
    color: '#F1727E',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  coverLstCard: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));
const Tutorial = (props) => {
  const classes = useStyles();

  return (
    <Box>
      <Container className={classes.container}>
        <div className={classes.coverLstCard}>
          {lstVideoTutorial.map((item, index) => (
            <div key={index} className={classes.coverCardVideo}>
              <YoutubePlayer style={{ width: 320, height: 180 }} videoId={item.videoId} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <h4 className={classes.txtTitle}>{item.title}</h4>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </Box>
  );
};

export default Tutorial;
