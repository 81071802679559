import axios from 'axios';
import StorageKeys from 'constants/storage-key';

const customAxios = (dynamicBaseURL) => {
  const axiosClient = axios.create({
    baseURL: dynamicBaseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  axiosClient.interceptors.request.use(
    function (config) {
      if (config.url !== '/api/user/login') {
        config.headers.Authorization = localStorage.getItem(StorageKeys.TOKEN) || '';
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axiosClient.interceptors.response.use(
    function (response) {
      if (response.data.ErrorCode === 401) {
      }
      return response.data;
    },
    function (error) {
      if (JSON.stringify(error).includes('Network Error')) {
      }
      return Promise.reject(error);
    }
  );
  return axiosClient;
};

export default customAxios;
