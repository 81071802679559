import ConfirmDialog from 'components/ConfirmDialog';
import Header from 'components/Header';
import HomeFeature from 'features/dashboard/pages/Home';
import FullpageLoader from 'components/FullpageLoader';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Footer from 'components/Footer';
import TutorialFeature from 'features/dashboard/pages/Tutorial';
import PaymentFeature from 'features/dashboard/pages/Payment';
import PolicyFeature from 'features/dashboard/pages/Policy';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  coverContent: {
    marginTop: 100,
    [theme.breakpoints.down('xl')]: {
      marginTop: 100,
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 40,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  txtCompany: {
    textAlign: 'center',
    [theme.breakpoints.down('xl')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));
const Home = (props) => {
  const classes = useStyles();

  const loaddingState = useSelector((state) => {
    return state.user.loadding;
  });
  return (
    <div
      style={{
        background: 'white',
        minHeight: '100vh',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Header />
        <div className={classes.coverContent}>
          <Switch>
            <Route path="/" exact component={HomeFeature} />
            <Route path="/payment" component={PaymentFeature} />
            <Route path="/tutorial" component={TutorialFeature} />
            <Route path="/policy" component={PolicyFeature} />
          </Switch>
        </div>
      </div>
      <div>
        <Footer />
        <div>
          <p className={classes.txtCompany}>
            {`Phát triển bởi Công ty CP Dịch Vụ Việt Nam Trực Tuyến Copyright © 2021. All Rights Reserved.`}
          </p>
        </div>
      </div>
      <ConfirmDialog />
      {loaddingState && <FullpageLoader />}
    </div>
  );
};

export default Home;
