import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { lstVideoTutorial } from 'constants/dataCommon';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';

const useStyles = makeStyles((theme) => ({
  styTxtTitle: {
    color: '#F1727E',
    textAlign: 'center',
    marginTop: 100,
    [theme.breakpoints.down('xl')]: {
      marginTop: 100,
      fontSize: 30,
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 50,
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
      fontSize: 16,
    },
  },
  coverSlickVideo: {
    padding: '50px 0px',
    [theme.breakpoints.down('xl')]: {
      padding: '50px 0px',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '20px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5px 0px',
    },
  },
  coverArrow: {
    background: '#f0727e',
    width: 50,
    height: 50,
    borderRadius: '50%',
    cursor: 'pointer',
    alignContent: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.5,
    [theme.breakpoints.down('xl')]: {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down('lg')]: {
      width: 40,
      height: 40,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  coverTxtVideoToturial: {
    position: 'absolute',
    borderRadius: 20,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '30%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingLeft: 10,
    padingRight: 10,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 3,
      padingRight: 3,
    },
  },
  imgTutorial: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
    borderRadius: 20,
    maxHeight: 220,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 20,
    },
  },
  txtTutorial: {
    color: '#fff',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  coverCardTutorial: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    margin: '20px 50px',
    borderRadius: 20,
    background: 'white',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 20,
      margin: '10px',
      boxShadow: 'none',
    },
  },
}));

TutorialUser.propTypes = {};
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const classes = useStyles();
  return (
    <div style={{ display: 'block', ...style, marginTop: -25 }} className={className}>
      <div className={classes.coverArrow} onClick={onClick}>
        <ArrowRightIcon style={{ color: 'white', width: 30, height: 30 }} />
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick, style, className } = props;
  const classes = useStyles();

  return (
    <div style={{ display: 'block', ...style, marginTop: -25 }} className={className}>
      <div className={classes.coverArrow} onClick={onClick}>
        <ArrowLeftIcon style={{ color: 'white', width: 30, height: 30 }} />
      </div>
    </div>
  );
}

function TutorialUser(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      <h1 className={classes.styTxtTitle}>{`HƯỚNG DẪN NGƯỜI DÙNG`}</h1>
      <div className={classes.coverSlickVideo}>
        <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          <Slider {...settings}>
            {lstVideoTutorial.map((item, index) => (
              <div key={index + 1}>
                <div
                  className={classes.coverCardTutorial}
                  onClick={() => {
                    history.push('/tutorial');
                  }}
                >
                  <div style={{ height: 220, position: 'relative', width: '100%' }}>
                    <img alt="img" src={item.image} className={classes.imgTutorial} />
                    <div className={classes.coverTxtVideoToturial}>
                      <h4 className={classes.txtTutorial}>{item.title}</h4>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default TutorialUser;
