const Images = {
  LogoFacebook: require('./icon_facebook.png'),
  LogoSkype: require('./icon_skype.png'),
  LogoSkyper: require('./icon_skyper.png'),
  LogoCall: require('./logo-call.png'),
  LogoZalo: require('./zalo-logo.png'),
  parent: require('./parent.png'),
  banner_left: require('./banner_left.png'),
  img_IOS_Store: require('./img_IOS_Store.png'),
  img_CHPlay: require('./img_CHPlay.png'),
  banner_right: require('./banner_right.png'),
  Banner_1: require('./Banner_1.jpg'),
  item_img_01: require('./item_img_01.png'),
  item_img_02: require('./item_img_02.png'),
  item_img_03: require('./item_icon_03.png'),
  item_img_04: require('./icon_item_06.png'),
  item_img_05: require('./icon_item_05.png'),
  item_img_06: require('./item_icon_06.png'),
  img_footer: require('./img_footer.png'),
  SaiGon_Bank: require('./SaiGon_Bank.png'),
  img_momo: require('./img_momo.png'),
  VP_Bank: require('./VP_Bank.png'),
  logoViettelMoney: require('./logoViettelMoney.png'),
  ZaloPay: require('./ZaloPay.png'),
  FwyNc59gQ2g: require('./FwyNc59gQ2g.png'),
  Tnb_4N4Ngro: require('./Tnb_4N4Ngro.png'),
  QVrOka5snog: require('./QVrOka5snog.png'),
  MhkuJ8sE2K8: require('./MhkuJ8sE2K8.png'),
  qraXICljI: require('./qraXICljI.png'),
  LGiRsvIz7Bk: require('./LGiRsvIz7Bk.png'),
  FDLs3hkKW2Y: require('./FDLs3hkKW2Y.png'),
  BtQ91nojGkI: require('./BtQ91nojGkI.png'),
  xb8IMiuCI9s: require('./xb8IMiuCI9s.png'),
  iconCheck: require('./Check.png'),
  iconUnCheck: require('./UnCheck.png'),

  iconATM: require('./iconATM.png'),
  iconVisa: require('./iconVisa.png'),

  ic_sle: require('./ic_sle.png'),
  ic_unsle: require('./ic_unsle.png'),
  background_sad: require('./background_sad.png'),
  logoSaleNoti: require('./logoSaleNoti.png'),
};
export default Images;
