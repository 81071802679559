import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userApi from 'apis/userApi';
import StorageKeys from 'constants/storage-key';

export const login = createAsyncThunk('/api/user/login', async (payload) => {
  const res = await userApi.login(payload);
  if (res.ErrorCode === 0) {
    localStorage.setItem(StorageKeys.TOKEN, res.Data.LoginSession);
    localStorage.setItem(StorageKeys.USER, JSON.stringify(res.Data));
    return res;
  }
  return res;
});

export const getMenuPermission = createAsyncThunk('/api/user/menu', async () => {
  const res = await userApi.getMenuPermission();
  if (res.ErrorCode === 0) {
    localStorage.setItem(StorageKeys.MENU_PERMISSION, JSON.stringify(res.Data));
    return res;
  }
  return res;
});

const userSlice = createSlice({
  name: StorageKeys.USER,
  initialState: {
    current: JSON.parse(localStorage.getItem(StorageKeys.USER)) || {},
    menuPermission: JSON.parse(localStorage.getItem(StorageKeys.MENU_PERMISSION)) || {},
    token: localStorage.getItem(StorageKeys.TOKEN) || '',

    loadding: false,
  },
  reducers: {
    logout(state) {
      localStorage.removeItem(StorageKeys.TOKEN);
      localStorage.removeItem(StorageKeys.USER);
      localStorage.removeItem(StorageKeys.MENU_PERMISSION);
      state.current = {};
    },
    genListMonthYear(state) {
      let currentDate = new Date();
      let arrDay = [];
      for (let i = 0; i <= 12; i++) {
        let dateOfIndex = new Date(currentDate.getFullYear(), currentDate.getMonth() + i);
        let obj = {
          date: `${('0' + (dateOfIndex.getMonth() + 1).toString()).slice(-2)}/${dateOfIndex.getFullYear()}`,
          id: i,
          month: `${dateOfIndex.getMonth() + 1}`,
          year: `${dateOfIndex.getFullYear()}`,
        };
        arrDay.push(obj);
      }
      state.listMonthYear = arrDay;
      localStorage.setItem(StorageKeys.LIST_MONTH_YEAR, JSON.stringify(arrDay));
    },
    getLstMonthYearAgo(state) {
      let currentDate = new Date();
      let arrDay = [];
      for (let i = 0; i <= 12; i++) {
        let dateOfIndex = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
        let obj = {
          date: `${('0' + (dateOfIndex.getMonth() + 1).toString()).slice(-2)}/${dateOfIndex.getFullYear()}`,
          id: i,
          month: `${dateOfIndex.getMonth() + 1}`,
          year: `${dateOfIndex.getFullYear()}`,
        };
        arrDay.push(obj);
      }
      state.listMonthYearAgo = arrDay;
      localStorage.setItem(StorageKeys.LIST_MONTH_YEAR_AGO, JSON.stringify(arrDay));
    },
    loadding(state) {
      state.loadding = !state.loadding;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.current = action.payload.Data;
    },
    [getMenuPermission.fulfilled]: (state, action) => {
      state.menuPermission = action.payload.Data;
    },
  },
});
const { actions, reducer } = userSlice;
export const { logout, genListMonthYear, getLstMonthYearAgo, loadding } = actions;
export default reducer;
