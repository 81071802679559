import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Images from 'assets/images';
import leftArrow from 'assets/images/left-arrow.svg';
import ButtonCom from 'components/Buttom';
import Utils from 'constants/utils';
import { loadding } from 'features/auth/userSlice';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  title: {
    textAlign: 'center',
    color: '#fff',
    marginTop: 8,
    fontWeight: '500',
    marginBottom: 0,
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  card: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    background: 'white',
    width: '80%',
    marginTop: 30,
    borderRadius: 20,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '96%',
      paddingBottom: 10,
    },
  },
  coverCardInfoStudent: {
    marginTop: 12,
    backgroundColor: '#31cde5',
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    borderRadius: 20,
    padding: 10,
  },
  coverHeaderInfoStudent: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  coverIconHeaderCard: {
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
    },
  },
  coverListBill: {
    flexDirection: 'row',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  coverCardDetailBill: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    background: 'white',
    marginTop: 18,
    padding: '8px 10px',
    flexDirection: 'column',
    display: 'flex',
    borderRadius: 20,
  },
  lineBreak: {
    width: '100%',
    height: 2,
    backgroundColor: '#eee',
  },
  coverDetailBill: {
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    padding: 12,
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  styTxtAmount: {
    fontSize: 20,
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  styCoverViewTotalAmount: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 12,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  styCoverBtn: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  styImgIconHeaderCard: {
    width: 28,
    height: 28,
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
  },
  coverViewListBill: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  txtMonth: {
    margin: 12,
    textAlign: 'center',
    fontSize: 17,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      margin: 8,
    },
  },
  styIconSelect: {
    width: 28,
    height: 28,
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
  },
  styTxtNameDetailbill: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 4,
    },
  },
  styTxtTotalMount: {
    color: '#F1727E',
    fontSize: 26,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      marginBottom: 8,
    },
  },
  styTxtTotalAmount: {
    fontSize: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      margin: 10,
    },
  },
}));

const ListBillStudent = (props) => {
  const { state } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [lstBill, setLstBill] = useState([]);
  const [totalAmountSel, setTotalAmountSel] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const showLoadding = loadding(true);

  useEffect(() => {
    const lstBillRaw = state?.lstBill?.BillInfo || [];
    lstBillRaw.forEach((billMonth) => {
      billMonth.BillDetails.forEach((detail) => {
        detail.IsSelect = false;
      });
    });
    setLstBill(lstBillRaw);
    return () => {};
  }, [state]);

  const handelSelectItem = useCallback(
    (indexParent, detail) => {
      let totalAmount = 0;
      let lstBillNew = lstBill;
      for (let i = lstBillNew.length - 1; i >= 0; i--) {
        if (i > indexParent) {
          lstBillNew[i].BillDetails.forEach((bill) => {
            bill.IsSelect = false;
          });
        } else {
          // eslint-disable-next-line no-loop-func
          lstBillNew[i].BillDetails.forEach((bill) => {
            if (bill.FeeType === detail.FeeType) {
              bill.IsSelect = true;
              totalAmount += bill.Amount;
            } else {
              bill.IsSelect = false;
            }
          });
        }
      }
      setTotalAmountSel(totalAmount);
      setLstBill(lstBillNew);
    },
    [lstBill]
  );

  const handelUnSelectItem = useCallback(
    (indexParent) => {
      let totalAmount = 0;
      let lstBillNew = lstBill;
      for (let i = lstBillNew.length - 1; i >= 0; i--) {
        if (i > indexParent) {
          lstBillNew[i].BillDetails.forEach((bill) => {
            bill.IsSelect = false;
          });
        } else if (i === indexParent) {
          lstBillNew[i].BillDetails.forEach((bill) => {
            bill.IsSelect = false;
          });
        } else {
          // eslint-disable-next-line no-loop-func
          lstBillNew[i].BillDetails.forEach((deBill) => {
            if (deBill.IsSelect === true) {
              totalAmount += deBill.Amount;
            }
          });
        }
      }
      setTotalAmountSel(totalAmount);
      setLstBill(lstBillNew);
    },
    [lstBill]
  );

  const handelNext = useCallback(() => {
    if (totalAmountSel === 0) {
      enqueueSnackbar('Vui lòng chọn khoản thu để thanh toán', { variant: 'warning' });
      return;
    }
    let billInfo = [];
    for (let i = 0; i < lstBill.length; i++) {
      let objTemp = JSON.parse(JSON.stringify(lstBill[i]));
      objTemp.BillDetails = [];
      let billdetails = [];
      let payAmount = 0;
      lstBill[i].BillDetails.forEach((detailBill) => {
        if (detailBill.IsSelect === true) {
          payAmount += detailBill.Amount;
          billdetails.push(detailBill);
        }
      });
      if (billdetails.length > 0) {
        objTemp.PayAmount = payAmount;
        objTemp.BillDetails = billdetails;
        billInfo.push(objTemp);
      }
    }
    history.push('/payment/paymentMethod', {
      infoStudent: state?.infoStudent || '',
      lstBill: billInfo,
      totalAmountSel: totalAmountSel,
      isBMC: state?.isBMC || false,
      username: state?.username || '',
    });
  }, [totalAmountSel, lstBill, state, history, enqueueSnackbar]);
  const renderViewHeaderCard = () => {
    return (
      <div className={classes.coverHeaderInfoStudent}>
        <div className={classes.coverIconHeaderCard}>
          <img
            onClick={() => {
              history.goBack();
            }}
            src={leftArrow}
            alt="img"
            className={classes.styImgIconHeaderCard}
          />
        </div>
        <div className={classes.coverCardInfoStudent}>
          <h4 className={classes.title}>{`Chi tiết khoản thu của`}</h4>
          <h3 className={classes.title}>{`${state?.infoStudent?.StudentName || ''}`}</h3>
          <h3 className={classes.title}>{`${state?.infoStudent?.StudentCode || ''}`}</h3>
        </div>
        <div className={classes.coverIconHeaderCard} />
      </div>
    );
  };
  return (
    <Box>
      <Container className={classes.container}>
        <div className={classes.card}>
          {renderViewHeaderCard()}
          <div className={classes.coverListBill}>
            <div className={classes.coverViewListBill}>
              {lstBill.map((item, index) => {
                let indexParent = index;
                return (
                  <div key={index} className={classes.coverCardDetailBill}>
                    <h4 className={classes.txtMonth}>{`Tháng ${item.Month}`}</h4>
                    <div className={classes.lineBreak} />
                    {item.BillDetails.map((itemDetail, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            itemDetail.IsSelect
                              ? handelUnSelectItem(indexParent)
                              : handelSelectItem(indexParent, itemDetail);
                          }}
                          className={classes.coverDetailBill}
                        >
                          <div style={{ width: '100%' }}>
                            <p
                              className={classes.styTxtNameDetailbill}
                              style={{ color: itemDetail.FeeType === 1 ? 'red' : '#18b0a3' }}
                            >{`${itemDetail.Note} ${(itemDetail.FeeType === 1 && '*') || ''}`}</p>
                            <p className={classes.styTxtAmount}>{`${Utils.formatMoney(itemDetail.Amount)} vnđ`}</p>
                          </div>
                          <img
                            src={itemDetail.IsSelect ? Images.iconCheck : Images.iconUnCheck}
                            alt="img"
                            className={classes.styIconSelect}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div className={classes.styCoverViewTotalAmount}>
                <h3 className={classes.styTxtTotalMount}>{`Tổng tiền thanh toán`}</h3>
                <h3 className={classes.styTxtTotalAmount}>{`${Utils.formatMoney(totalAmountSel)} vnđ`}</h3>
              </div>
            </div>
          </div>
          <div className={classes.styCoverBtn}>
            <ButtonCom
              text="Tiếp Tục"
              typeBTN="BRAND"
              callbackClick={() => handelNext()}
              style={{ color: 'white', width: 300, marginTop: 30 }}
            />
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default ListBillStudent;
