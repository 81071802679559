import React, { useState, useEffect, useCallback } from 'react';
import BtnBanner from './components/BtnBanner';
import Images from 'assets/images';
import { makeStyles } from '@material-ui/core/styles';

import './Banner.css';
const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'white',
    marginTop: 90,
    [theme.breakpoints.down('xl')]: {
      height: 650,
    },
    [theme.breakpoints.down('lg')]: {
      height: 650,
    },
    [theme.breakpoints.down('sm')]: {
      height: 320,
      marginTop: 50,
    },
  },
  coverArrow: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  styBannerOne: {
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('xl')]: {
      width: '100%',
      height: 650,
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: 650,
    },
    [theme.breakpoints.down('sm')]: {
      height: 250,
      width: '100%',
    },
  },
  styImgBannerRightTwo: {
    width: '40vw',
    height: 'calc(40vw / 1.2)',
    [theme.breakpoints.down('xl')]: {
      width: '40vw',
      height: 'calc(40vw / 1.2)',
    },
    [theme.breakpoints.down('lg')]: {
      width: '40vw',
      height: 'calc(40vw / 1.2)',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  styCoverBannerTwo: {
    width: '100%',
    height: 650,
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    [theme.breakpoints.down('xl')]: {
      height: 650,
    },
    [theme.breakpoints.down('lg')]: {
      height: 650,
    },
    [theme.breakpoints.down('sm')]: {
      height: 250,
    },
  },
  styCoverViewDownload: {
    marginTop: 40,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xl')]: {
      marginTop: 40,
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 40,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  styImgDownload: {
    width: 130,
    height: 32.5,
    marginLeft: 30,
    [theme.breakpoints.down('xl')]: {
      marginLeft: 30,
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: 30,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 6,
    },
  },
  styCoverTxtBannerTwo: {
    paddingLeft: 20,
    paddingTop: 100,
    [theme.breakpoints.down('xl')]: {
      paddingLeft: 20,
      paddingTop: 100,
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 20,
      paddingTop: 100,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingTop: 30,
      position: 'absolute',
      zIndex: 1,
    },
  },
  styCoverImgBannerLeftTwo: {
    alignItems: 'flex-end',
    height: 650,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      height: 320,
    },
  },
  styImgBannerLeftTwo: {
    width: '16vw',
    height: 'calc(16vw * 1.5)',
    [theme.breakpoints.down('sm')]: {
      width: '30vw',
      height: 'calc(30vw * 1.5)',
    },
  },
  txtDownloadNow: {
    fontWeight: '500',
    color: '#606060',
    margin: 0,
  },
  txtTitBannerThree: {
    color: '#F1727E',
    fontWeight: '600',
    fontSize: 20,
    [theme.breakpoints.down('xl')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  txtAppname: {
    color: '#F1727E',
    fontWeight: '600',
    fontSize: 50,
    margin: 20,
    marginLeft: 0,
    [theme.breakpoints.down('xl')]: {
      fontSize: 50,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 50,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  txtDis: {
    fontWeight: '600',
    [theme.breakpoints.down('xl')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      fontWeight: '500',
    },
  },
}));
const Banner = () => {
  const dataBanner = [{ index: 1 }, { index: 2 }, { index: 3 }];
  const [bannerIndex, setBannerIndex] = useState(1);
  const classes = useStyles();
  const nextBanner = useCallback(() => {
    if (bannerIndex !== dataBanner.length) {
      setBannerIndex(bannerIndex + 1);
    } else if (bannerIndex === dataBanner.length) {
      setBannerIndex(1);
    }
  }, [bannerIndex, dataBanner]);

  useEffect(() => {
    let timer1 = setTimeout(() => nextBanner(), 3200);
    return () => {
      clearTimeout(timer1);
    };
  }, [bannerIndex, nextBanner]);
  const prevBanner = () => {
    if (bannerIndex !== 1) {
      setBannerIndex(bannerIndex - 1);
    } else if (bannerIndex === 1) {
      setBannerIndex(dataBanner.length);
    }
  };

  const renderViewBannerIndexOne = () => {
    return <img className={classes.styBannerOne} alt={'Banner'} src={Images.Banner_1} />;
  };
  const renderViewBannerIndexTwo = () => {
    return (
      <div className={classes.styCoverBannerTwo}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.styCoverImgBannerLeftTwo}>
            <img className={classes.styImgBannerLeftTwo} alt={'Banner'} src={Images.banner_left} />
          </div>
          <div className={classes.styCoverTxtBannerTwo}>
            <h2 style={{ color: '#F1727E', fontWeight: '500' }}>{`TUYỂN SINH NĂM HỌC MỚI`}</h2>
            <h3 style={{ fontWeight: '500' }}>
              <h3 style={{ color: '#F1727E', display: 'contents' }}>{`Bước 1: `}</h3>
              {`Vào tính năng "Tuyển sinh năm học mới" trên ứng dụng Yschool.`}
            </h3>
            <h3 style={{ fontWeight: '500' }}>
              <h3 style={{ color: '#F1727E', display: 'contents' }}>{`Bước 2: `}</h3>
              {`Khai báo thông tin.`}
            </h3>
            <h3 style={{ fontWeight: '500' }}>
              <h3 style={{ color: '#F1727E', display: 'contents' }}>{`Bước 3: `}</h3>
              {`Kiểm tra lại các thông tin và "Đăng ký".`}
            </h3>
            <div className={classes.styCoverViewDownload}>
              <h3 className={classes.txtDownloadNow}>{`TẢI NGAY`}</h3>
              <a href="https://apps.apple.com/us/app/yschool/id1486322665?ls=1%22">
                <img className={classes.styImgDownload} alt={'Banner'} src={Images.img_IOS_Store} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.yschool.app">
                <img className={classes.styImgDownload} alt={'Banner'} src={Images.img_CHPlay} />
              </a>
            </div>
          </div>
        </div>
        <img className={classes.styImgBannerRightTwo} alt={'Banner'} src={Images.parent} />
      </div>
    );
  };

  const renderViewBannerIndexThree = () => {
    return (
      <div className={classes.styCoverBannerTwo}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.styCoverImgBannerLeftTwo}>
            <img className={classes.styImgBannerLeftTwo} alt={'Banner'} src={Images.banner_left} />
          </div>
          <div className={classes.styCoverTxtBannerTwo}>
            <h2 className={classes.txtTitBannerThree}>{`PHẦN MỀM TƯƠNG TÁC PHỤ HUYNH - HỌC SINH`}</h2>
            <h1 className={classes.txtAppname}>{`TRƯỜNG EM -YSCHOOL`}</h1>
            <h3
              className={classes.txtDis}
            >{`Mong muốn đem lại cho khách hàng những giải pháp cũng như tiện ích tối ưu nhất nhằm đơn giản hoá quá trình thanh toán học phí cũng như tăng sự tương tác giữa nhà trường, giáo viên, phụ huynh`}</h3>
            <div className={classes.styCoverViewDownload}>
              <h3 className={classes.txtDownloadNow}>{`TẢI NGAY`}</h3>
              <a href="https://apps.apple.com/us/app/yschool/id1486322665?ls=1%22">
                <img className={classes.styImgDownload} alt={'Banner'} src={Images.img_IOS_Store} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.yschool.app">
                <img className={classes.styImgDownload} alt={'Banner'} src={Images.img_CHPlay} />
              </a>
            </div>
          </div>
        </div>
        <div>
          <img style={{ width: '40vw', height: 'calc(40vw / 1.12)' }} alt={'Banner'} src={Images.banner_right} />
        </div>
      </div>
    );
  };
  const renderViewBanner = (index) => {
    switch (index) {
      case 1:
        return renderViewBannerIndexOne();
      case 2:
        return renderViewBannerIndexTwo();
      case 3:
        return renderViewBannerIndexThree();
      default:
        return <div></div>;
    }
  };

  return (
    <div className={classes.main}>
      {dataBanner.map((item, index) => {
        return (
          <div key={index} className={bannerIndex === index + 1 ? 'slide active-anim' : 'slide'}>
            {renderViewBanner(item.index)}
          </div>
        );
      })}
      <div className={classes.coverArrow}>
        <div>
          <BtnBanner moveBanner={nextBanner} direction={'next'} />
          <BtnBanner moveBanner={prevBanner} direction={'prev'} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
