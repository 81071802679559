import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { FormControl, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 220,
    margin: 0,
    width: '100%',
  },
  menuItem: {
    backgroundColor: 'white',
    color: 'black',
  },
}));

const DropdownAutocomplete = forwardRef((props, ref) => {
  var {
    data = [],
    keyName = '',
    keyValue = '',
    style,
    fullWidth = false,
    label = '',
    placeholder = 'Chọn ' + label,
    onChanged = () => {},
    valueSetParent = '',
    type,
    onChangeSearchSchool = () => {},
    classProps = '',
  } = props;
  const classes = useStyles();
  const [valueSelected, setValueSelected] = useState('');

  useImperativeHandle(ref, () => ({
    setDataParent() {
      setValueSelected(valueSetParent);
    },
  }));
  const handleChange = (event, value) => {
    let valSel = data.find((va) => {
      return va[keyName] === value;
    });
    onChanged(valSel, type);
  };
  const handleRemoveInput = () => {
    onChanged(null, type);
  };

  return (
    <FormControl fullWidth={fullWidth} variant="outlined" className={classes.formControl}>
      <Autocomplete
        label={label}
        onChange={handleChange}
        options={data.map((option) => option[keyName])}
        onInputChange={(event, newInputValue) => {
          onChangeSearchSchool(newInputValue);
          if (newInputValue === '') {
            handleRemoveInput(0);
          }
        }}
        style={{ padding: 0, ...style, height: 50 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={''}
            InputLabelProps={{
              shrink: true,
              style: { zIndex: 0 },
            }}
            placeholder={placeholder}
            variant="standard"
            margin="normal"
            className={classProps}
            style={{ textAlign: 'center', margin: 0, height: 50 }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              underline: {
                '&&&:before': {
                  borderBottom: 'none',
                  textAlign: 'center',
                },
                '&&:after': {
                  borderBottom: 'none',
                  textAlign: 'center',
                },
              },
              style: { width: '100%', height: 50, paddingLeft: 20, paddingRight: 20 },
            }}
          />
        )}
      />
    </FormControl>
  );
});
DropdownAutocomplete.propTypes = {};

export default DropdownAutocomplete;
